import Pages from 'layouts/Pages.jsx';
import Dashboard from 'layouts/Dashboard.jsx';
import AdminDashboard from 'layouts/AdminDashboard.jsx';

const indexRoutes = [
  { path: '/agent', name: 'Home', component: Dashboard },
  { path: '/admin', name: 'Admin', component: AdminDashboard },
  { path: '/', name: 'Pages', component: Pages },
];

export default indexRoutes;
