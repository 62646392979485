import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import PagesHeader from "components/Header/PagesHeader.jsx";
import Footer from "components/Footer/Footer.jsx";

import pagesRoutes from "routes/pages.jsx";
import InitialPage from "views/Pages/InitialPage.jsx";
import RegisterPage from "views/Pages/RegisterPage.jsx";

import LandingPage from "views/Pages/LandingPage.jsx";
import LoginPage from "views/Pages/LoginPage.jsx";
import PricingPage from "views/Pages/PricingPage.jsx";


import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx";

import bgImage from "assets/img/Lobb-Bg.jpg";

// var ps;

class Pages extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return <div>
        <PagesHeader {...rest} />
        <div className={classes.wrapper} ref="wrapper">
          <div className={classes.fullPage}>
            <div style={{ minHeight: "650px" }}>
              <Switch>
                <Route path={"/pages/landing-page"} component={LandingPage} />
                <Route path={"/pages/initial-page"} component={InitialPage} />
                <Route path={"/pages/login-page"} component={LoginPage} />
                <Route path={"/pages/pricing-page"} component={PricingPage} />
                 <Route path={"/pages/register-page"} component={RegisterPage} />

                {pagesRoutes.map((prop, key) => {
                  if (prop.collapse) {
                    return null;
                  }
                  if (prop.redirect) {
                    return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
                  }
                  return <Route path={prop.path} component={prop.component} key={key} />;
                })}
              </Switch>
            </div>
            <Footer white />
            {/* <div className={classes.fullPageBackground} /> */}
            <div className={classes.fullPageBackground} style={{ backgroundImage: "url(" + bgImage + ")" }} />
          </div>
        </div>
      </div>;
  }
}

Pages.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(pagesStyle)(Pages);
