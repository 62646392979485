import { cardTitle } from "assets/jss/material-dashboard-pro-react.jsx";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import modalStyle from "assets/jss/material-dashboard-pro-react/modalStyle.jsx";
import tooltipsStyle from "assets/jss/material-dashboard-pro-react/tooltipsStyle.jsx";
import popoverStyles from "assets/jss/material-dashboard-pro-react/popoverStyles.jsx";

const AddTruckersStyle = {
  ...customSelectStyle,
  cardTitle:{
    fontFamily:'Rajdhani',
    ...cardTitle
  },
  cardIconTitle: {
    ...cardTitle,
    fontFamily: 'Rajdhani',
    marginTop: "15px",
    marginBottom: "0px",
    "& small": {
      fontSize: "80%",
      fontWeight: "400"
    }
  },
  cardCategory: {
    marginTop: "10px",
    color: "#999999 !important",
    textAlign: "center"
  },
  description: {
    fontFamily: 'Rajdhani',
    color: "#999999"
  },
  updateProfileButton: {
    float: "right"
  },
  rajdhaniFont:{
    fontFamily: 'Rajdhani'
  },
  ...sweetAlertStyle,
  ...modalStyle,
  ...tooltipsStyle,
  ...popoverStyles

};
export default AddTruckersStyle;
