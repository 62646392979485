/* eslint-disable react/sort-comp */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/order */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/Icons
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Table from 'components/Table/Table.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import AddAlert from '@material-ui/icons/AddAlert';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import LoadingOverlay from 'react-loading-overlay';
import SweetAlert from 'react-bootstrap-sweetalert';
import Clear from '@material-ui/icons/Clear';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import firebase from '../../../config/config';

import '../../../assets/react-datepicker.css';

import {
  switchActivePage,
  setCurrentTransaction,
  setPayableTransactions,
  setAthTransactions,
} from '../../../reducers/transactions';

const db = firebase.firestore();

const moment = require('moment');

class AthHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      payableTransactions: [
        {
          color: 'info',
          data: ['28-09-2019', '10,000', 'message is'],
        },
      ],
      amount: '',
      amountState: '',
      message: '',
      messageState: '',
      date: moment().format('MMM Do YY'),
      isAthForm: false,
      tc: false,
      isLoading: false,
      startDate: moment(),
      totalPayablePendingAmount: 0,
      totalCommission: 0,
      totalReceivablePendingAmount: 0,
      isLoadingEntry: false,
      alert: null,
    };
    this.hideDetailedForm = this.hideDetailedForm.bind(this);
    this.showAthForm = this.showAthForm.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateDashboardData = this.updateDashboardData.bind(this);
    this.warningWithConfirmAndCancelMessage = this.warningWithConfirmAndCancelMessage.bind(
      this
    );
  }

  componentWillMount() {
    this.getUserCompanyName();
    const { transactionData, classes } = this.props;
    const { companyId, transactionId } = transactionData;
    const athRef = db
      .collection('Companies')
      .doc(companyId)
      .collection('Transactions')
      .doc(transactionId)
      .collection('Receivable')
      .where('type', '==', 'ATH');
    athRef.onSnapshot(athRes => {
      const athTransactions = [];
      athRes.forEach(element => {
        if (element.data().void) {
          athTransactions.push({
            color: 'success',
            data: [
              this.changeFont(element.data().date),
              this.changeFont(
                Intl.NumberFormat('en-IN').format(element.data().amount)
              ),
              this.changeFont(element.data().message),
              '',
            ],
          });
        } else {
          athTransactions.push({
            color: 'success',
            data: [
              element.data().date,
              Intl.NumberFormat('en-IN').format(element.data().amount),
              element.data().message,
              <Button
                color="warning"
                className={classes.actionButton}
                key={element.id}
                onClick={() => {
                  this.warningWithConfirmAndCancelMessage(
                    companyId,
                    transactionId,
                    element.id
                  );
                }}
                justIcon
                simple
                size="lg"
              >
                <Clear className={classes.icon} />
              </Button>,
            ],
          });
        }
      });
      this.props.setAthTransactions(athTransactions);
    });
  }

  changeFont(value) {
    return <strike>{value}</strike>;
  }

  void(companyId, transactionId, receivableId) {
    const body = { companyId, transactionId, receivableId };
    this.setState({
      isLoadingEntry: true,
      alert: null,
    });
    const voidEntry = firebase.functions().httpsCallable('deleteAthEntry');
    voidEntry(body)
      .then(response => {
        this.setState({
          isLoadingEntry: false,
        });
      })
      .catch(err => {
        this.setState({
          isLoadingEntry: false,
        });
      });
  }

  warningWithConfirmAndCancelMessage(companyId, transactionId, receivableId) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: 'block', marginTop: '-100px' }}
          title="Are you sure?"
          onConfirm={() => this.void(companyId, transactionId, receivableId)}
          onCancel={() => this.setState({ alert: null })}
          confirmBtnCssClass={`${this.props.classes.button} ${this.props.classes.success}`}
          cancelBtnCssClass={`${this.props.classes.button} ${this.props.classes.danger}`}
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
        />
      ),
    });
  }

  /**
   * Will Get Balances Total
   */
  getCompanyBalances(companyId) {
    db.collection('Companies')
      .doc(companyId)
      .onSnapshot(resultData => {
        if (resultData.data().companyId) {
          this.setState({
            totalPayablePendingAmount: resultData.data()
              .totalPayablePendingAmount,
            totalReceivablePendingAmount: resultData.data()
              .totalReceivablePendingAmount,
            totalCommission: resultData.data().totalCommission,
          });
        }
      });
  }

  /**
   * Submits the transporter details to the Database
   * @function
   */
  getUserCompanyName() {
    const _me = this;
    firebase.auth().onAuthStateChanged(user => {
      const userRef = db.collection(`Users`).doc(`${user.uid}`);
      userRef.get().then(
        resultData => {
          _me.setState({
            companyName: resultData.data().companyName,
            companyId: resultData.data().companyId,
          });
          this.getCompanyBalances(resultData.data().companyId);
          // Get All the Transporters And Truckers
        },
        err => {
          console.log('err', err);
        }
      );
    });
  }

  hideDetailedForm() {
    this.props.switchActivePage({
      isListPage: true,
      isDetailedPage: false,
      isEditPage: false,
    });
  }

  /**
   * will return the notification snackbar
   * @param {string} place
   */
  showNotification(place) {
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 5000);
    }
  }

  handleChange(date) {
    this.setState({
      startDate: date,
      date: moment(date).format('MMM Do YY'),
    });
  }

  // function that verifies if value contains only numbers
  verifyNumber(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        this.setState({
          [`${stateName}State`]: 'success',
          [stateName]: event.target.value,
        });
        break;
    }
  }

  /**
   * Will Activate Form for Adding New Payable Transaction
   * @function
   */
  showAthForm() {
    this.setState({
      isAthForm: !this.state.isAthForm,
    });
  }

  getForm() {
    const { classes } = this.props;
    return (
      <Card style={{ marginTop: '1px', paddingBottom: '1px' }}>
        <CardBody style={{ marginBottom: '1px', paddingBottom: '1px' }}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Amount"
                id="receivable-amount"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  value: this.state.amount,
                  onChange: event => this.change(event, 'amount', 'number'),
                }}
                success={this.state.amountState === 'success'}
                error={this.state.amountState === 'error'}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Message"
                id="receivable-message"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  value: this.state.message,
                  onChange: event => this.change(event, 'message', 'length', 2),
                  type: 'length',
                }}
                success={this.state.messageState === 'success'}
                error={this.state.messageState === 'error'}
              />
            </GridItem>
            <DatePicker
              id="receivable-date"
              className={classes.datePicker}
              selected={this.state.startDate}
              onChange={this.handleChange}
            />
          </GridContainer>
        </CardBody>
        <CardFooter style={{ paddingTop: '0px' }}>
          {this.state.isLoading ? (
            <CircularProgress
              className={classes.progress}
              style={{ color: purple[500] }}
              thickness={7}
            />
          ) : (
            <React.Fragment>
              <Button
                color="warning"
                className={classes.updateProfileButton}
                onClick={() => this.showAthForm()}
              >
                Cancel
              </Button>
              <Button
                id="submit-receivable"
                color="info"
                className={classes.updateProfileButton}
                onClick={() => this.submitAth()}
              >
                Submit
              </Button>
            </React.Fragment>
          )}
        </CardFooter>
      </Card>
    );
  }

  /**
   * Will return the Table Component
   * @function
   */
  getTable() {
    const { classes } = this.props;
    const { isLoadingEntry } = this.state;
    return (
      <div>
        {this.props.athTransactions.length > 0 ? (
          <div>
            <LoadingOverlay active={isLoadingEntry} spinner text="Loading...">
              <Table
                hover
                tableHead={['Date', 'Amount', 'Message']}
                tableData={this.props.athTransactions}
              />
            </LoadingOverlay>
          </div>
        ) : (
          <p
            className={classes.textCenter}
            style={{ marginTop: '10px', marginBottom: '10px' }}
          >
            No Transactions Available
          </p>
        )}
      </div>
    );
  }

  // validates all required fields and returns a promise
  validateAllFields() {
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify  Amount
      if (this.verifyNumber(this.state.amount)) {
        this.setState({
          amountState: 'success',
        });
      } else {
        this.setState({ amountState: 'error' });
        error = true;
      }

      // Verify whether the amount is less the currentReceivableAmount
      if (
        parseInt(this.state.amount, 10) >
        parseInt(this.props.currentReceivableAmount, 10)
      ) {
        this.setState({
          amountState: 'error',
        });
        error = true;
      }

      // Verify  message
      if (this.verifyLength(this.state.message, 2)) {
        this.setState({
          messageState: 'success',
        });
      } else {
        this.setState({ messageState: 'error' });
        error = true;
      }

      if (!error) {
        resolve(true);
      } else {
        reject('validation Failed');
      }
    });
  }

  /**
   * Will udate the Dashboard Data To The DB
   * @function
   */
  updateDashboardData() {
    const totalReceivablePendingAmount =
      Number(this.state.totalReceivablePendingAmount) -
      Number(this.state.amount);
    db.collection('Companies')
      .doc(this.state.companyId)
      .update({ totalReceivablePendingAmount, modifiedAt: new Date() })
      .then(() => {
        this.clearForm();
        this.setState({ isLoading: false });
      });
  }

  /**
   * Will Submit the Payable history to Database
   * @function
   */
  submitAth = () => {
    this.setState({ isLoading: true });
    this.validateAllFields()
      .then(
        success => {
          if (success) {
            db.collection('Companies')
              .doc(this.props.transactionData.companyId)
              .collection('Transactions')
              .doc(this.props.transactionData.transactionId)
              .collection('Receivable')
              .add({
                amount: this.state.amount,
                message: this.state.message,
                createdAt: new Date(),
                type: 'ATH',
                transactionId: this.props.transactionData.transactionId,
                date: this.state.date,
                companyId: this.props.transactionData.companyId,
              })
              .then(receivableResult => {
                this.setState({
                  isLoading: false,
                });
                this.showNotification('tc');
                this.showAthForm();
                this.clearForm();
              });
          } else {
            this.setState({
              isLoading: false,
            });
          }
        },
        err => {
          this.setState({
            isLoading: false,
          });
        }
      )
      .catch(error => {
        this.setState({
          isLoading: false,
        });
      });
  };

  /**
   * will clear the form
   * @function
   */
  clearForm() {
    this.setState({
      amount: '',
      message: '',
      amountState: '',
      messageState: '',
      isLoading: '',
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {this.getTable()}
          {this.state.alert}
          <GridContainer>
            {this.state.isAthForm ? (
              <GridItem xs={12} sm={12} md={12}>
                {this.getForm()}
              </GridItem>
            ) : (
              <GridItem xs={12} sm={12} md={12}>
                <Button
                  id="add-receivable-entry"
                  color="info"
                  className={classes.updateProfileButton}
                  onClick={() => this.showAthForm()}
                >
                  Add Ath
                </Button>
              </GridItem>
            )}
          </GridContainer>
        </GridItem>
        <Snackbar
          place="tc"
          color="info"
          icon={AddAlert}
          message="ATH Updated Successfully"
          open={this.state.tc}
          closeNotification={() => this.setState({ tc: false })}
          close
        />
        <GridItem xs={12} sm={12} md={12} />
      </GridContainer>
    );
  }
}

AthHistory.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isDetailedPage: state.transactions.isDetailedPage,
  currentTransactionData: state.transactions.currentTransactionData,
  transactions: state.transactions.transactions,
  payableTransactions: state.transactions.payableTransactions,
  athTransactions: state.transactions.athTransactions,
  currentReceivableAmount: state.transactions.currentReceivableAmount,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchActivePage,
      setCurrentTransaction,
      setPayableTransactions,
      setAthTransactions,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(TransactionStyle)(AthHistory));
