import AdminDashboard from 'views/AdminDashboard/AdminDashboard.jsx';

// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';

const adminDashRoutes = [
  {
    path: '/admin/dashboard',
    name: 'Dashboard',
    icon: DashboardIcon,
    component: AdminDashboard,
  },
  {
    redirect: true,
    path: '/admin',
    pathTo: '/admin/dashboard',
    name: 'Dashboard',
  },
];
export default adminDashRoutes;
