/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import CardIcon from 'components/Card/CardIcon.jsx';
import Assignment from '@material-ui/icons/Assignment';
import Edit from '@material-ui/icons/Edit';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Table from 'components/Table/Table.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import Fuse from 'fuse.js';
import CustomInput from 'components/CustomInput/CustomInput.jsx';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import { switchTransporterActivePage } from '../../../reducers/transporters';
import history from '../../../history.js';
import firebase from '../../../config/config';

const db = firebase.firestore();

const keys = {
  phoneNumber: 'transporterData.phoneNumber',
  transporterName: 'transporterData.name',
};

const { phoneNumber, transporterName } = keys;

const fuseOptions = {
  shouldSort: true,
  threshold: 0.4,
  location: 0,
  distance: 50,
  maxPatternLength: 12,
  keys: [phoneNumber, transporterName],
};

class ViewTransporters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transporters: [],
      companyID: '',
      uid: '',
      transportersData: [],
      isLoading: false,
      transporterInitialData: [],
    };
    this.getTable = this.getTable.bind(this);
    this.getTransportersData = this.getTransportersData.bind(this);
    this.AddTransporters = this.AddTransporters.bind(this);
  }

  componentDidMount() {
    const userRef = db.collection('Users');
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({
          isLoading: true,
        });
        userRef
          .doc(`${user.uid}`)
          .get()
          .then(resultData => {
            if (resultData.exists) {
              this.setState({
                companyId: resultData.data().companyId,
              });
              this.getTransportersData(user, resultData.data().companyId);
            }
          });
      } else {
        this.setState({
          isLoading: false,
        });
      }
    });
  }

  openEditForm(data) {
    this.props.switchTransporterActivePage(true, data);
  }

  /**
   * Will Get ALl the Transporters For The User
   * @function
   */
  getTransportersData(user, companyId) {
    const colors = ['info', 'success'];
    const transporterRef = db.collection('Companies');
    const transporters = this.state.transportersData;
    transporterRef
      .doc(`${companyId}`)
      .collection('Transporters')
      .orderBy('name', 'asc')
      .get()
      .then(transporterData => {
        this.setState({
          isLoading: false,
        });
        let flag = 0;
        transporterData.forEach(transporter => {
          const transporterResult = transporter.data();
          const { classes } = this.props;
          const fillButtons = [{ color: 'info', icon: Edit }].map(
            (prop, key) => (
              <Button
                color={prop.color}
                className={classes.actionButton}
                key={key}
                onClick={() => this.openEditForm(transporterResult)}
                justIcon
              >
                <prop.icon className={classes.icon} />
              </Button>
            )
          );
          transporters.push({
            color: colors[flag],
            data: [
              transporterResult.name,
              transporterResult.phoneNumber,
              transporterResult.email,
              fillButtons,
            ],
            transporterData: transporterResult,
          });

          if (flag === 0) {
            flag = 1;
          } else {
            flag = 0;
          }
        });
        this.setState({
          transportersData: transporters,
          transporterInitialData: transporters,
        });
      });
  }

  onSearch(e) {
    const { transporterInitialData } = this.state;
    console.log('init', transporterInitialData);
    const { target = {} } = e;
    const { value = '' } = target;
    if (value === '') {
      this.setState({
        transportersData: transporterInitialData,
        searchText: '',
      });
    } else {
      const fuse = new Fuse(transporterInitialData, fuseOptions);
      const data = value ? fuse.search(value) : transporterInitialData;
      const newData = [];
      data.forEach(val => {
        if (val.hasOwnProperty('item')) {
          newData.push(val.item);
        }
      });
      console.log('data', data);
      this.setState({ searchText: value, transportersData: newData });
    }
  }

  /**
   * Will return the Table Component
   * @function
   */
  getTable() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader color="warning" icon>
          <CardIcon color="warning">
            <Assignment />
          </CardIcon>
        </CardHeader>
        <CardBody className={classes.customCardContentClass}>
          <Table
            hover
            tableHead={['Name', 'Phone', 'Email']}
            tableData={this.state.transportersData}
          />
        </CardBody>
      </Card>
    );
  }

  /**
   * will redirect to Add Transporters Page
   * @function
   */
  AddTransporters() {
    history.push('/agent/transporters/add');
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  render() {
    const { classes } = this.props;
    const { searchText } = this.state;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader className={`${classes.cardHeader}`} color="warning">
                <GridContainer>
                  <GridItem xs={12} md={8} lg={10}>
                    <p className={`${classes.cardHeader}`}>Transporters</p>

                    <h3 className={classes.cardTitle}>
                      {this.state.transportersData.length} <small />
                    </h3>
                  </GridItem>
                  <GridItem xs={12} md={4} lg={2}>
                    <div style={{ float: 'right' }}>
                      <form>
                        <CustomInput
                          formControlProps={{
                            className: `${classes.top} ${classes.search}`,
                          }}
                          inputProps={{
                            placeholder: 'Search',
                            inputProps: {
                              'aria-label': 'Search',
                              value: searchText,
                              onChange: event => this.onSearch(event),
                              className: classes.searchInput,
                            },
                          }}
                        />
                      </form>
                    </div>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody
                className={`${classes.cardHeader} ${classes.textCenter}`}
              >
                {this.state.isLoading ? (
                  <CircularProgress
                    className={classes.progress}
                    style={{ color: purple[500] }}
                    thickness={7}
                  />
                ) : (
                  <React.Fragment>
                    {this.state.transportersData.length <= 0 ? (
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <p className={classes.textCenter}>
                          No Transporters Available
                        </p>
                        <div className={classes.textCenter}>
                          <Button
                            round
                            color="danger"
                            onClick={this.AddTransporters}
                          >
                            Add Transporters
                          </Button>
                        </div>
                      </GridItem>
                    ) : (
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        {this.getTable()}
                      </GridItem>
                    )}
                  </React.Fragment>
                )}
              </CardBody>
              <CardFooter stats>
                <div className={classes.stats} />
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

ViewTransporters.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isTransporterEditForm: state.transporters.isTransporterEditForm,
  userDetails: state.main.userDetails,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchTransporterActivePage,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(TransactionStyle)(ViewTransporters));
