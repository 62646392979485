/* eslint-disable class-methods-use-this */
import React from 'react';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// @material-ui/icons
import TransferWithinAStation from '@material-ui/icons/TransferWithinAStation';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';

import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';

import SweetAlert from 'react-bootstrap-sweetalert';
import userProfileStyles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx';
import history from '../../history.js';

// Fireabse
import firebase from '../../config/config';

const db = firebase.firestore();

class AddTransporters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      nameState: '',

      email: '',
      emailState: '',

      contactPerson: '',
      contactPersonState: '',

      phoneNumber: '',
      phoneNumberState: '',

      creditLimit: '',
      creditLimitState: '',

      gstNumber: '',
      gstNumberState: '',

      panNumber: '',
      panNumberState: '',

      city: '',
      cityState: '',

      state: '',
      stateState: '',

      postalCode: '',
      postalCodeState: '',

      companyName: '',
      isLoading: false,

      alert: null,
      companyId: '',
    };
    this.change = this.change.bind(this);
    this.submitTransporter = this.submitTransporter.bind(this);
    this.validateAllFields = this.validateAllFields.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.goToAddTrucker = this.goToAddTrucker.bind(this);
  }

  componentDidMount() {
    this.getUserCompanyName();
    const tour = {
      id: 'hello-hopscotch',
      showPrevButton: true,
      onEnd: this.endTour,
      onClose: this.endTour,
      showCloseButton: true,
      i18n: { closeTooltip: 'Skip Tour' },
      steps: [
        {
          title: "Enter Transporter's Name",
          content: '',
          target: 'transporter-name',
          placement: 'bottom',
        },
        {
          title: "Enter Contact Person's Name",
          content: '',
          target: 'contact-person',
          placement: 'bottom',
        },
        {
          title: 'Enter Phone Number',
          content: '',
          target: 'phone-number',
          placement: 'bottom',
        },
        {
          title: 'Enter Credit Limit',
          content: '',
          target: 'credit-limit',
          placement: 'bottom',
        },
        {
          title: 'Enter GST Number',
          content: '',
          target: 'gst-number',
          placement: 'bottom',
        },
        {
          title: 'Enter PAN',
          content: '',
          target: 'pan-number',
          placement: 'bottom',
        },
        {
          title: 'Enter Email-Address',
          content: '',
          target: 'email-address',
          placement: 'bottom',
        },
        {
          title: 'Submit and Add ',
          content: '',
          target: 'submit-transporter',
          placement: 'left',
        },
        {
          title: "Let's Continue",
          content: '',
          target: 'logoText',
          placement: 'right',
          showNextButton: false,
          showCTAButton: true,
          ctaLabel: 'Add Trucker',
          onCTA: this.goToAddTrucker,
        },
      ],
    };

    if (this.props.userDetails.onBoard.transporter) {
      window.hopscotch.startTour(tour);
    }
  }

  endTour() {
    //  console.log("Tour ended")
    window.hopscotch.endTour([]);
    const user = firebase.auth().currentUser;
    if (user) {
      db.collection('Users')
        .doc(user.uid)
        .update({ 'onBoard.transporter': false });
    }
  }

  componentWillUnmount() {
    window.hopscotch.endTour([]);
  }

  goToAddTrucker() {
    window.hopscotch.endTour([]);
    history.push('/agent/truckers/add');
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  // function that returns true if value is there, false otherwise
  verifyRequired(value) {
    if (value) {
      return true;
    }
    return false;
  }

  // function that returns true if value is Valid GST, false otherwise
  verifyGst(value) {
    const gstRex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (gstRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains only numbers
  verifyNumber(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains PanCard
  verifyPan(value) {
    const regpan = /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/;
    if (regpan.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains PanCard
  verifyPhone(value) {
    const regPhone = /^[56789]\d{9}$/;
    if (regPhone.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'required':
        if (this.verifyRequired(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'pan':
        if (this.verifyPan(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'phone':
        if (this.verifyPhone(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'gst':
        if (this.verifyGst(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        break;
    }
  }

  // validates all required fields and returns a promise
  validateAllFields() {
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify Name
      if (this.verifyLength(this.state.name, 1)) {
        this.setState({
          nameState: 'success',
        });
      } else {
        this.setState({
          nameState: 'error',
        });
        error = true;
      }

      // Verify Phone Number
      if (this.verifyPhone(this.state.phoneNumber)) {
        this.setState({
          phoneNumberState: 'success',
        });
      } else {
        this.setState({
          phoneNumberState: 'error',
        });
        error = true;
      }

      if (!error) {
        resolve(true);
      } else {
        reject('validation Failed');
      }
    });
  }

  /**
   * Get the Users Company Name from the Database
   * @function
   */
  getUserCompanyName() {
    const _me = this;
    firebase.auth().onAuthStateChanged(user => {
      const userRef = db.collection(`Users`).doc(`${user.uid}`);
      userRef
        .get()
        .then(
          resultData => {
            if (resultData.exists) {
              _me.setState({
                companyName: resultData.data().companyName,
                companyId: resultData.data().companyId,
              });
              // console.log("resultData", resultData.data().companyName);
            }
          },
          err => {
            console.log('err', err);
          }
        )
        .catch(err => {
          console.log('err', err);
        });
    });
  }

  /**
   * Submits the transporter details to the Database
   * @function
   */
  submitTransporter() {
    const _me = this;
    this.setState({
      isLoading: true,
    });
    this.validateAllFields()
      .then(
        success => {
          //  window.hopscotch.showStep(8)
          //   let _me = this;
          if (success) {
            const user = firebase.auth().currentUser;
            const transporterRef = db.collection(`Companies`);
            transporterRef
              .doc(this.state.companyId)
              .collection(`Transporters`)
              .add({
                name: this.state.name,
                email: this.state.email,
                contactPerson: this.state.contactPerson,
                phoneNumber: this.state.phoneNumber,
                creditLimit: this.state.creditLimit,
                gstNumber: this.state.gstNumber,
                panNumber: this.state.panNumber,
                updatedBy: user.uid,
                companyName: this.state.companyName,
                createdAt: new Date(),
                modifiedAt: new Date(),
              })
              .then(userDoc => {
                // Updated Succeessfully
                db.collection(`Companies`)
                  .doc(this.state.companyId)
                  .collection(`Transporters`)
                  .doc(userDoc.id)
                  .update({
                    transporterId: userDoc.id,
                  });
                this.clearForm();
                _me.setState({ isLoading: false });
              });
          }
        },
        err => {
          console.log(err);
          _me.setState({
            isLoading: false,
          });
        }
      )
      .catch(err => {
        console.log(err);
        _me.setState({
          isLoading: false,
        });
      });
  }

  /**
   * Will Clears ALl the State values after form submision
   * @function
   */
  clearForm() {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Good job!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={`${this.props.classes.button} ${this.props.classes.success}`}
        >
          Transporter Added Succeessfully
        </SweetAlert>
      ),
    });
    const _me = this;
    _me.setState({
      name: '',
      nameState: '',
      email: '',
      emailState: '',
      contactPerson: '',
      contactPersonState: '',
      phoneNumber: '',
      phoneNumberState: '',
      creditLimit: '',
      creditLimitState: '',
      gstNumber: '',
      gstNumberState: '',
      panNumber: '',
      panNumberState: '',
      city: '',
      cityState: '',
      state: '',
      stateState: '',
      postalCode: '',
      postalCodeState: '',
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {this.state.alert}
            <Card>
              <CardHeader className={`${classes.cardHeader}`} color="warning">
                <CardIcon color="primary" className={`${classes.cardHeader}`}>
                  <TransferWithinAStation />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Add Transporter <small />
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Transporter Name *"
                      id="transporter-name"
                      formControlProps={{ fullWidth: true }}
                      success={this.state.nameState === 'success'}
                      error={this.state.nameState === 'error'}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'name', 'length', 1),
                        type: 'length',
                        value: this.state.name,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Contact Person "
                      id="contact-person"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'contactPerson', 'length', 0),
                        type: 'length',
                        value: this.state.contactPerson,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Phone Number *"
                      id="phone-number"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'phoneNumber', 'phone'),
                        type: 'phone',
                        value: this.state.phoneNumber,
                      }}
                      success={this.state.phoneNumberState === 'success'}
                      error={this.state.phoneNumberState === 'error'}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Credit Limit "
                      id="credit-limit"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'creditLimit', 'number'),
                        type: 'number',
                        value: this.state.creditLimit,
                      }}
                      success={this.state.creditLimitState === 'success'}
                      error={this.state.creditLimitState === 'error'}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="GST Number "
                      id="gst-number"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'gstNumber', 'gst'),
                        type: 'gst',
                        value: this.state.gstNumber,
                      }}
                      success={this.state.gstNumberState === 'success'}
                      error={this.state.gstNumberState === 'error'}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="PAN Number"
                      id="pan-number"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'panNumber', 'pan'),
                        type: 'pan',
                        value: this.state.panNumber,
                      }}
                      success={this.state.panNumberState === 'success'}
                      error={this.state.panNumberState === 'error'}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      success={this.state.emailState === 'success'}
                      error={this.state.emailState === 'error'}
                      labelText="Email address "
                      id="email-address"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event => this.change(event, 'email', 'email'),
                        type: 'email',
                        value: this.state.email,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                {this.state.isLoading ? (
                  <CircularProgress
                    className={classes.progress}
                    style={{ color: purple[500] }}
                    thickness={7}
                  />
                ) : (
                  <Button
                    id="submit-transporter"
                    color="warning"
                    onClick={this.submitTransporter}
                    className={classes.updateProfileButton}
                  >
                    Submit
                  </Button>
                )}
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
AddTransporters.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userDetails: state.main.userDetails,
});

export default connect(mapStateToProps)(
  withStyles(userProfileStyles)(AddTransporters)
);
