/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import EditTransporters from './Sections/EditTransporters';
import ViewTransporters from './Sections/ViewTransporters';
import { switchTransporterActivePage } from '../../reducers/transporters';

class Transporters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        {this.props.isTransporterEditForm ? (
          <EditTransporters />
        ) : (
          <ViewTransporters />
        )}
      </div>
    );
  }
}

Transporters.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isTransporterEditForm: state.transporters.isTransporterEditForm,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchTransporterActivePage,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(TransactionStyle)(Transporters));
