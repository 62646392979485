import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
// import { createBrowserHistory } from "history";
import history from "../../../src/history.js";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
// import Notifications from "@material-ui/icons/Notifications";

// core components
import Button from "components/CustomButtons/Button.jsx";
import * as firebase from "firebase";

import headerLinksStyle from "assets/jss/material-dashboard-pro-react/components/headerLinksStyle";

class HeaderLinks extends React.Component {
  constructor(props){
    super(props);
    this.logOut = this.logOut.bind(this)
  }
  state = {
    open: false
  };
  handleClick = () => {
    this.setState({ open: !this.state.open });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  /**
  * phone auth logout
  * @function 
  */
  logOut() {
    // var _me = this
    firebase.auth().signOut().then(function () {
      // Sign-out successful.
      // console.log("jsjj")
       history.push('/pages/login-page')
    }).catch(function (error) {
      // An error happened.
    });
  }
  render() {
    const { classes, rtlActive } = this.props;
    const { open } = this.state;
    // const searchButton =
    //   classes.top +
    //   " " +
    //   classes.searchButton +
    //   " " +
    //   classNames({
    //     [classes.searchRTL]: rtlActive
    //   });
    const dropdownItem =
      classes.dropdownItem +
      " " +
      classNames({
        [classes.dropdownItemRTL]: rtlActive
      });
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive
    });
    const managerClasses = classNames({
      [classes.managerClasses]: true
    });
    return <div className={wrapper}>
        <Manager className={managerClasses}>
          <Target>
            <Button color="transparent" justIcon aria-label="Logout" aria-owns={open ? "menu-list" : null} aria-haspopup="true" onClick={this.handleClick} className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink} muiClasses={{ label: rtlActive ? classes.labelRTL : "" }}>
              <Person className={classes.headerLinksSvg + " " + (rtlActive ? classes.links + " " + classes.linksRTL : classes.links)} />
               <Hidden mdUp>
                <span onClick={this.handleClick} className={classes.linkText}>
                  {rtlActive ? "إعلام" : "Logout"}
                </span>
               </Hidden>
            </Button>
          </Target>
          <Popper placement="bottom-start" eventsEnabled={open} className={classNames(
                { [classes.popperClose]: !open }
              ) + " " + classes.pooperResponsive}>
            <ClickAwayListener onClickAway={this.handleClose}>
              <Grow in={open} id="log" style={{ transformOrigin: "0 0 0" }}>
                <Paper className={classes.dropdown}>
                  <MenuList role="menu">
                    <MenuItem onClick={this.logOut} className={dropdownItem}>
                      {rtlActive ? "إجلاء أوزار الأسيوي حين بل, كما" : "Log Out"}
                    </MenuItem>
                  </MenuList>
                </Paper>
              </Grow>
            </ClickAwayListener>
          </Popper>
        </Manager>
      </div>;
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
};

export default withStyles(headerLinksStyle)(HeaderLinks);
