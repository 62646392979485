/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-empty */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
import React from 'react';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// material-ui icons
import Assignment from '@material-ui/icons/Assignment';

// core components
import Table from 'components/Table/Table.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import Edit from '@material-ui/icons/Edit';
import Button from 'components/CustomButtons/Button.jsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import AddAlert from '@material-ui/icons/AddAlert';

import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import extendedTablesStyle from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx';
import {
  switchActivePage,
  setCurrentTransaction,
  setTransactions,
} from '../../../reducers/transactions';
import history from '../../../history';
import firebase from '../../../config/config';

const db = firebase.firestore();
const moment = require('moment');

class DashboardTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      transactions: [],
      checkedIndex: -1,
      tc: false,
      message: '',
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.getTransactions = this.getTransactions.bind(this);
    this.goToViewTransactions = this.goToViewTransactions.bind(this);
    this.addTransactions = this.addTransactions.bind(this);
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({ isLoading: true });
        this.getTransactions(user);
      }
    });
  }

  handleToggle(value, transactionId, companyId) {
    db.collection(`Companies`)
      .doc(companyId)
      .collection('Transactions')
      .doc(transactionId)
      .update({ pod: value })
      .then(() => {
        const NotificationMessage = 'POD Updated';
        this.showNotification('tc', NotificationMessage);
      });
  }

  /**
   * will return the notification snackbar
   * @param {string} place
   */
  showNotification(place, name) {
    this.setState({
      message: name,
    });
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 2000);
    }
  }

  goToViewTransactions(transaction) {
    let pod = false;
    if (transaction.pod) {
      pod = true;
    } else {
      pod = false;
    }
    this.props.setCurrentTransaction(
      transaction,
      transaction.currentPayableAmount,
      transaction.currentReceivableAmount,
      pod
    );
    this.props.switchActivePage({
      isListPage: true,
      isDetailedPage: false,
      isEditPage: false,
    });
    history.push(`/agent/transactions/view/${transaction.transactionId}`);
  }

  /**
   * will return ALl running transactions of the User
   * @param {object} user
   */
  getTransactions(user) {
    const { classes } = this.props;
    const mainRef = db.collection('Users').doc(user.uid);
    mainRef.get().then(userData => {
      if (userData.exists) {
        if (userData.data().companyId) {
          const TransactionsRef = db
            .collection('Companies')
            .doc(userData.data().companyId)
            .collection('Transactions')
            .where('companyId', '==', userData.data().companyId)
            .orderBy('createdAt', 'desc');
          TransactionsRef.onSnapshot(snapData => {
            const transactions = [];
            snapData.forEach(resultData => {
              let checked = false;
              if (resultData.data().pod) {
                checked = resultData.data().pod;
              }
              const checkBox = (
                <FormControlLabel
                  classes={{
                    root: classes.checkboxLabelControl,
                    label: classes.checkboxLabel,
                  }}
                  control={
                    <Checkbox
                      onChange={() =>
                        this.handleToggle(
                          !checked,
                          resultData.data().transactionId,
                          resultData.data().companyId
                        )
                      }
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      checked={checked}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{ checked: classes.checked }}
                    />
                  }
                />
              );
              const fillButtons = [{ color: 'info', icon: Edit }].map(
                (prop, key) => (
                  <Button
                    color={prop.color}
                    className={classes.actionButton}
                    key={key}
                    onClick={() => this.goToViewTransactions(resultData.data())}
                  >
                    View
                  </Button>
                )
              );
              const date = resultData.data().createdAt.toDate();

              transactions.push({
                color: 'success',
                data: [
                  resultData.data().transactionSerial,
                  resultData.data().transporterName,
                  resultData.data().truckerName,
                  resultData.data().vehicleNumber,
                  Intl.NumberFormat('en-IN').format(resultData.data().amount),
                  Intl.NumberFormat('en-IN').format(
                    resultData.data().currentPayableAmount
                  ),
                  Intl.NumberFormat('en-IN').format(
                    resultData.data().currentReceivableAmount
                  ),
                  Intl.NumberFormat('en-IN').format(
                    resultData.data().commission
                  ),
                  moment(date).format('MMM Do YY'),
                  checkBox,
                  fillButtons,
                ],
              });
            });
            this.setState({ transactions });
          });
        }
      } else {
      }
    });
  }

  /**
   * will redirect to Add Transactions Page
   * @function
   */
  addTransactions() {
    history.push('/agent/transactions/add');
  }

  render() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader color="rose" icon>
          <CardIcon color="warning">
            <Assignment />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Transactions</h4>
        </CardHeader>

        {this.state.transactions.length > 0 ? (
          <CardBody>
            <Table
              tableHead={[
                '#',
                'Transporter',
                'Trucker',
                'Truck No',
                'Freight',
                'Payable',
                'Receivable',
                'Commission',
                'Date',
                'POD',
              ]}
              tableData={this.state.transactions}
              customCellClasses={[classes.center]}
              customClassesForCells={[6, 5, 5]}
              customHeadCellClasses={[classes.center]}
              customHeadClassesForCells={[6, 5, 5]}
            />
          </CardBody>
        ) : (
          <div className={classes.center}>
            <p className={`${classes.rajdhaniFont}`}>
              No Transactions Available
            </p>
            <Button round color="info" onClick={this.addTransactions}>
              Add Transactions
            </Button>
          </div>
        )}
        <Snackbar
          place="tc"
          color="info"
          icon={AddAlert}
          message={this.state.message}
          open={this.state.tc}
          closeNotification={() => this.setState({ tc: false })}
          close
        />
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  isDetailedPage: state.transactions.isDetailedPage,
  currerntTransactionData: state.transactions.currerntTransactionData,
  transactions: state.transactions.transactions,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchActivePage,
      setCurrentTransaction,
      setTransactions,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(extendedTablesStyle)(DashboardTable));
