/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import EditTruckers from './Sections/EditTruckers';
import ViewTruckers from './Sections/ViewTruckers';
import { switchTruckerActivePage } from '../../reducers/truckers';

class Truckers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        {this.props.isTruckerEditForm ? <EditTruckers /> : <ViewTruckers />}
      </div>
    );
  }
}

Truckers.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isTruckerEditForm: state.truckers.isTruckerEditForm,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchTruckerActivePage,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(TransactionStyle)(Truckers));
