/* eslint-disable react/no-array-index-key */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-state */
import React from 'react';
import { connect } from 'react-redux';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';

// @material-ui/icons
import ArrowBack from '@material-ui/icons/ArrowBack';
// import LocalShipping from "@material-ui/icons/LocalShipping";
import { bindActionCreators } from 'redux';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import Datetime from 'react-datetime';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import Danger from 'components/Typography/Danger.jsx';
import moment from 'moment';

import SweetAlert from 'react-bootstrap-sweetalert';
import AddTruckersStyle from 'assets/jss/material-dashboard-pro-react/views/AddTruckersStyle.jsx';
import history from '../../../history.js';
import {
  switchActivePage,
  setCurrentTransaction,
} from '../../../reducers/transactions';
// Fireabse
import firebase from '../../../config/config';

const db = firebase.firestore();

class AddTransactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transporterName: '',
      transporterNameState: '',
      truckerNameState: '',
      transporterId: '',
      truckerName: '',
      truckerId: '',
      fromCity: [],
      fromCityState: '',
      toCity: [],
      toCityState: '',
      vehicleNumber: '',
      vehicleNumberState: '',
      amount: '',
      amountState: '',
      commission: '',
      commissionState: '',
      truckType: '',
      truckTypeState: '',
      truckNumber: '',
      truckNumberState: '',
      companyName: '',
      isLoading: false,
      alert: null,
      companyId: '',
      trucks: [],
      truckTypes: [
        '12T- Open',
        '20FT Container',
        '22FT Closed Container',
        '22FT Jcb Carrier',
        '24FT Closed Container',
        '24FT Jcb Carrier',
        '32FT MultiAxle Container',
        '32FT Single Axle Container',
        '34FT Multi Axle Container',
        '34FT Single Axle Container',
        'FTL',
        'High Bed Trailer',
        'High Bed Trailer Double Axle',
        'High Bed Trailer Multi Axle',
        'LCV Closed Container - 14FT',
        'LCV-14FT',
        'LCV-19FT',
        'LCV-7FT',
        'LCV-17FT',
        'Low Bed Trailer',
        'Low Bed Trailer Double Axle',
        'Low Bed Trailer Multi Axle',
        'LPT-19 FT',
        'LPT-19FT Closed Container',
      ],
      transporters: [],
      truckers: [],
      fromCities: [],
      toCities: [],
      totalPayablePendingAmount: 0,
      totalReceivablePendingAmount: 0,
      totalCommission: 0,
      transactionSerial: 1,
      bonus: '',
      loadingDate: null,
    };
    this.change = this.change.bind(this);
    this.submitTransaction = this.submitTransaction.bind(this);
    this.validateAllFields = this.validateAllFields.bind(this);
    this.getTruckTypes = this.getTruckTypes.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.getTransporters = this.getTransporters.bind(this);
    this.getTruckers = this.getTruckers.bind(this);
    this.getCities = this.getCities.bind(this);
    this.updateDashboardData = this.updateDashboardData.bind(this);
    this.handleTrucker = this.handleTrucker.bind(this);
    this.handleTransporter = this.handleTransporter.bind(this);
    this.handleTrucks = this.handleTrucks.bind(this);
    this.updateTransactionSerial = this.updateTransactionSerial.bind(this);
    this.goToViewTransaction = this.goToViewTransaction.bind(this);
    this.goToListView = this.goToListView.bind(this);
    this.loadingDateChange = this.loadingDateChange.bind(this);
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  componentDidMount() {
    this.getUserCompanyName();
    const { currentTransactionData } = this.props;
    const {
      amount,
      commission,
      fromCity,
      toCity,
      truckType,
      truckerName,
      truckerId,
      transporterName,
      transporterId,
      transactionSerial,
      companyId,
      vehicleNumber,
      createdAt,
    } = currentTransactionData;
    let { bonus } = currentTransactionData;
    const selectedTransporter = {
      value: transporterId,
      label: transporterName,
    };
    const selectedTrucker = {
      value: truckerId,
      label: truckerName,
    };
    if (bonus) {
      // Do nothing
    } else {
      bonus = 0;
    }
    this.setState({
      amount: Number(amount),
      commission: Number(commission),
      fromCity,
      toCity,
      truckType,
      truckerName,
      truckerId,
      transporterName,
      transporterId,
      transactionSerial,
      companyId,
      truckNumber: vehicleNumber,
      bonus,
      selectedTransporter,
      selectedTrucker,
      loadingDate: moment(createdAt.toDate()),
    });
    const tour = {
      id: 'hello-hopscotch',
      showPrevButton: true,
      onEnd: this.endTour,
      onClose: this.endTour,
      showCloseButton: true,
      i18n: { closeTooltip: 'Skip Tour' },
      steps: [
        {
          title: 'Select Transporter',
          content: '',
          target: 'select-transporter',
          placement: 'bottom',
        },
        {
          title: 'Select Trucker',
          content: '',
          target: 'select-trucker',
          placement: 'bottom',
        },
        {
          title: 'Select Origin',
          content: '',
          target: 'select-from-city',
          placement: 'bottom',
        },
        {
          title: 'Submit Destination',
          content: '',
          target: 'submit-trucker',
          placement: 'left',
        },
        {
          title: 'Select Truck',
          content: 'Options from the Trucker you selected previously',
          target: 'select-truck',
          placement: 'bottom',
        },
        {
          title: 'Enter Freight Value',
          content: '',
          target: 'freight-value',
          placement: 'bottom',
        },
        {
          title: 'Enter Commission',
          content: '',
          target: 'commission',
          placement: 'bottom',
        },
        {
          title: 'Submit and Create your Transaction ',
          content: '',
          target: 'submit-transaction',
          placement: 'left',
        },
        {
          title: "Let's Continue",
          content: '',
          target: 'logoText',
          placement: 'right',
          showNextButton: false,
          showCTAButton: true,
          ctaLabel: 'View Transaction',
          onCTA: this.goToViewTransaction,
        },
      ],
    };

    if (this.props.userDetails.onBoard.transactionCreate) {
      window.hopscotch.startTour(tour);
    }
  }

  loadingDateChange(loadingDate) {
    this.setState({ loadingDate });
  }

  endTour() {
    window.hopscotch.endTour([]);
    const user = firebase.auth().currentUser;
    if (user) {
      db.collection('Users')
        .doc(user.uid)
        .update({ 'onBoard.transactionCreate': false });
    }
  }

  componentWillUnmount() {
    window.hopscotch.endTour([]);
  }

  goToViewTransaction() {
    window.hopscotch.endTour([]);
    history.push('/agent/transactions/view');
  }

  /**
   * Will Get Balances Total
   */
  getCompanyBalances(companyId) {
    db.collection('Companies')
      .doc(companyId)
      .onSnapshot(resultData => {
        if (resultData.data().companyId) {
          let transactionSerial = 1;
          if (resultData.data().transactionSerial) {
            transactionSerial = resultData.data().transactionSerial;
          }
          this.setState({
            totalPayablePendingAmount: resultData.data()
              .totalPayablePendingAmount,
            totalReceivablePendingAmount: resultData.data()
              .totalReceivablePendingAmount,
            totalCommission: resultData.data().totalCommission,
            transactionSerial,
          });
        }
      });
  }

  /**
   * Submits the transporter details to the Database
   * @function
   */
  getUserCompanyName() {
    const _me = this;
    firebase.auth().onAuthStateChanged(user => {
      this.getCities(user);
      const userRef = db.collection(`Users`).doc(`${user.uid}`);
      userRef.get().then(
        resultData => {
          _me.setState({
            companyName: resultData.data().companyName,
            companyId: resultData.data().companyId,
          });
          this.getCompanyBalances(resultData.data().companyId);
          // Get All the Transporters And Truckers

          this.getTruckers(resultData.data().companyId);

          this.getTransporters(resultData.data().companyId);
        },
        err => {
          console.log('err', err);
        }
      );
    });
  }

  /**
   * will return All the Truckers Available for User
   * @function
   * @param {String} companyId
   */
  getTruckers(companyId) {
    const TruckerRef = db
      .collection(`Companies`)
      .doc(`${companyId}`)
      .collection('Truckers');
    TruckerRef.get()
      .then(
        resultData => {
          const truckers = [];
          resultData.forEach(element => {
            const data = element.data();
            data.label = element.data().name;
            data.value = element.data().truckerId;
            truckers.push(data);
          });
          this.setState({
            truckers,
          });
          const {
            truckerId,
            vehicleNumber,
            truckType,
          } = this.props.currentTransactionData;
          for (const i in truckers) {
            if (truckers[i].truckerId === truckerId) {
              if (truckers[i].trucks) {
                this.setState({
                  trucks: truckers[i].trucks,
                  truckType,
                  truckNumber: vehicleNumber,
                });
              }
              this.setState({
                truckerName: truckers[i].name,
              });
            }
          }
        },
        err => {
          console.log('err', err);
        }
      )
      .catch(err => {
        console.log('err', err);
      });
  }

  /**
   * will return All the Transporters Available for User
   * @function
   * @param {String} companyId
   */
  getTransporters(companyId) {
    const TransporterRef = db
      .collection(`Companies`)
      .doc(`${companyId}`)
      .collection('Transporters');
    TransporterRef.get()
      .then(
        resultData => {
          const transporters = [];
          resultData.forEach(element => {
            const data = element.data();
            data.label = element.data().name;
            data.value = element.data().transporterId;
            transporters.push(data);
          });
          this.setState({ transporters });
        },
        err => {
          console.log('err', err);
        }
      )
      .catch(err => {
        console.log('err', err);
      });
  }

  /**
   * will return All the Cities Available for User
   * @function
   * @param {String} companyId
   */
  getCities(user) {
    const citiesRef = db.collection(`Users`).doc(`${user.uid}`);
    citiesRef
      .get()
      .then(
        resultData => {
          const { cities } = resultData.data();
          this.setState({ fromCities: cities, toCities: cities });
        },
        err => {
          console.log('err', err);
        }
      )
      .catch(err => {
        console.log('err', err);
      });
  }

  // function that verifies if value contains only numbers
  verifyNumber(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  // Will Verify cities
  verifyCities(value) {
    if (value.length < 2) {
      return false;
    }
    return true;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        break;
    }
  }

  // validates all required fields and returns a promise
  validateAllFields() {
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify  Transporter Name
      if (this.verifyLength(this.state.transporterName, 1)) {
        this.setState({
          transporterNameState: 'success',
        });
      } else {
        this.setState({ transporterNameState: 'error' });
        error = true;
      }

      // Verify  Trucker Name
      if (this.verifyLength(this.state.truckerName, 1)) {
        this.setState({
          truckerNameState: 'success',
        });
      } else {
        this.setState({ truckerNameState: 'error' });
        error = true;
      }

      // Verify  From City
      if (this.verifyLength(this.state.fromCity, 1)) {
        this.setState({
          fromCityState: 'success',
        });
      } else {
        this.setState({ fromCityState: 'error' });
        error = true;
      }
      // Verify  To City
      if (this.verifyLength(this.state.toCity, 1)) {
        this.setState({
          toCityState: 'success',
        });
      } else {
        this.setState({ toCityState: 'error' });
        error = true;
      }

      // Verify  Amount
      if (this.verifyNumber(this.state.amount)) {
        this.setState({
          amountState: 'success',
        });
      } else {
        this.setState({ amountState: 'error' });
        error = true;
      }

      // Verify  Commission
      if (this.verifyNumber(this.state.commission)) {
        this.setState({
          commissionState: 'success',
        });
      } else {
        this.setState({ commissionState: 'error' });
        error = true;
      }

      // Verify  Truck Number
      if (this.verifyLength(this.state.truckNumber, 1)) {
        this.setState({ truckNumberState: 'success' });
      } else {
        this.setState({ truckNumberState: 'error' });
        error = true;
      }

      // Verify whether the amount is less the commission
      if (
        parseInt(this.state.amount, 10) <
        parseInt(Number(this.state.commission) + Number(this.state.bonus), 10)
      ) {
        this.setState({
          commissionState: 'error',
          bonusState: 'error',
        });
        error = true;
      }

      // Verify  Commission
      if (this.verifyNumber(this.state.bonus)) {
        this.setState({
          bonusState: 'success',
        });
      } else {
        this.setState({ bonusState: 'error' });
        error = true;
      }

      if (!error) {
        resolve(true);
      } else {
        reject('validation Failed');
      }
    });
  }

  /**
   * Will Update the Transaction Serial
   */
  updateTransactionSerial() {
    const nextSerial = Number(this.state.transactionSerial) + 1;
    db.collection('Companies')
      .doc(this.state.companyId)
      .update({ modifiedAt: new Date(), transactionSerial: nextSerial });
  }

  /**
   * Will udate the Dashboard Data To The DB
   * @function
   */
  updateDashboardData() {
    const currentPayableAmount =
      Number(this.state.amount) - Number(this.state.commission);
    const currentReceivableAmount = Number(this.state.amount);
    const commission = Number(this.state.commission);
    const totalPayablePendingAmount =
      Number(this.state.totalPayablePendingAmount) +
      Number(currentPayableAmount);
    const totalReceivablePendingAmount =
      Number(this.state.totalReceivablePendingAmount) +
      Number(currentReceivableAmount);
    const totalCommission =
      Number(this.state.totalCommission) + Number(commission);
    db.collection('Companies')
      .doc(this.state.companyId)
      .update({
        totalPayablePendingAmount,
        totalReceivablePendingAmount,
        totalCommission,
        modifiedAt: new Date(),
      })
      .then(() => {
        this.clearForm();
        this.setState({ isLoading: false });
      });
  }

  // WIll Hide the Detailed Transactions And returns to Transactions Main Page
  goToListView() {
    this.props.switchActivePage({
      isListPage: true,
      isDetailedPage: false,
      isEditPage: false,
    });
  }

  /**
   * Submits the transporter details to the Database
   * @function
   */
  submitTransaction() {
    const _me = this;
    this.setState({
      isLoading: true,
    });
    this.validateAllFields()
      .then(
        success => {
          if (success) {
            const {
              transporterName,
              truckerName,
              fromCity,
              toCity,
              commission,
              bonus,
              amount,
              loadingDate,
            } = this.state;
            const date = new Date(loadingDate);
            const { currentTransactionData } = this.props;
            const { transactionId, companyId } = currentTransactionData;
            const totalCommission = Number(bonus) + Number(commission);
            const currentPayableAmount =
              Number(amount) - Number(totalCommission);
            const currentReceivableAmount = Number(this.state.amount);
            const user = firebase.auth().currentUser;
            const addMessage = firebase
              .functions()
              .httpsCallable('editTransaction');
            const body = {
              agentId: user.uid,
              transporterName,
              transporterId: this.state.transporterId,
              truckerId: this.state.truckerId,
              truckerName,
              fromCity,
              toCity,
              vehicleNumber: this.state.truckNumber,
              truckType: this.state.truckType,
              amount: currentReceivableAmount,
              commission: Number(commission),
              currentPayableAmount,
              currentReceivableAmount,
              companyId,
              transactionId,
              bonus,
              createdAtDate: date.toJSON(),
            };
            addMessage(body)
              .then(result => {
                _me.goToListView();
                _me.clearForm();
                _me.setState({ isLoading: false });
              })
              .catch(err => {
                this.setState({
                  alert: (
                    <SweetAlert
                      error
                      style={{ display: 'block', marginTop: '-100px' }}
                      title="Error"
                      onConfirm={() => this.hideAlert()}
                      confirmBtnCssClass={`${this.props.classes.button} ${this.props.classes.success}`}
                    >
                      Oops!, Something Went Wrong.
                    </SweetAlert>
                  ),
                });
                _me.setState({ isLoading: false });
              });
          }
        },
        err => {
          console.log(err);
          this.setState({
            isLoading: false,
          });
        }
      )
      .catch(err => {
        console.log(err);
        this.setState({
          isLoading: false,
        });
      });
  }

  /**
   * Will Clears ALl the State values after form submision
   * @function
   */
  clearForm() {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Good job!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={`${this.props.classes.button} ${this.props.classes.success}`}
        >
          Transaction Added Succeessfully
        </SweetAlert>
      ),
    });
    this.setState({
      transporterName: '',
      transporterId: '',
      truckerName: '',
      truckerId: '',
      fromCity: '',
      fromCityState: '',
      toCity: '',
      toCityState: '',
      vehicleNumber: '',
      vehicleNumberState: '',
      amount: '',
      amountState: '',
      commission: '',
      commissionState: '',
      truckType: '',
      truckTypeState: '',
      truckNumber: '',
      truckNumberState: '',
      isLoading: false,
    });
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleTransporter = event => {
    const trasnporters = this.state.transporters;
    for (const i in trasnporters) {
      if (trasnporters[i].transporterId === event.target.value) {
        this.setState({
          transporterName: trasnporters[i].name,
        });
      }
    }
    this.setState({ transporterId: event.target.value });
  };

  handleTransporter = selectedTransporter => {
    this.setState({
      selectedTransporter,
      transporterName: selectedTransporter.name,
      transporterId: selectedTransporter.transporterId,
    });
  };

  handleTrucker = selectedTrucker => {
    this.setState({
      selectedTrucker,
      truckerName: selectedTrucker.name,
      truckerId: selectedTrucker.truckerId,
    });
  };

  handleTrucks = event => {
    for (const i in this.state.trucks) {
      if (this.state.trucks[i].truckNumber === event.target.value) {
        this.setState({ truckType: this.state.trucks[i].truckType });
      }
    }
    this.setState({ truckNumber: event.target.value });
  };

  getTransporterNames() {
    const { classes } = this.props;
    return (
      <FormControl
        id="select-transporter"
        fullWidth
        className={classes.selectFormControl}
      >
        <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
          Select Transporter
        </InputLabel>
        <Select
          MenuProps={{ className: classes.selectMenu }}
          classes={{ select: classes.select }}
          value={this.state.transporterId}
          onChange={this.handleTransporter}
          inputProps={{ name: 'transporterName', id: 'simple-select' }}
        >
          <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
            Select Transporter
          </MenuItem>
          {this.state.transporters.map((transporter, i) => (
            <MenuItem
              key={i}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={transporter.transporterId}
            >
              {transporter.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  // Will Return select From cities component
  selectFromCities() {
    const { classes } = this.props;
    return (
      <FormControl
        id="select-from-ciy"
        fullWidth
        className={classes.selectFormControl}
      >
        <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
          Select From City
        </InputLabel>
        <Select
          MenuProps={{ className: classes.selectMenu }}
          classes={{ select: classes.select }}
          value={this.state.fromCity}
          onChange={this.handleSimple}
          inputProps={{ name: 'fromCity', id: 'simple-select' }}
        >
          <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
            Select City
          </MenuItem>
          {this.state.fromCities.map((city, i) => (
            <MenuItem
              key={i}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={city}
            >
              {city}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  // Will Return select From cities component
  selectToCities() {
    const { classes } = this.props;
    return (
      <FormControl
        id="select-to-city"
        fullWidth
        className={classes.selectFormControl}
      >
        <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
          Select To City
        </InputLabel>
        <Select
          MenuProps={{ className: classes.selectMenu }}
          classes={{ select: classes.select }}
          value={this.state.toCity}
          onChange={this.handleSimple}
          inputProps={{ name: 'toCity', id: 'simple-select' }}
        >
          <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
            Select City
          </MenuItem>
          {this.state.toCities.map((city, i) => (
            <MenuItem
              key={i}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={city}
            >
              {city}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  getTruckerNames() {
    const { classes } = this.props;
    return (
      <FormControl
        id="select-trucker"
        fullWidth
        className={classes.selectFormControl}
      >
        <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
          Select Trucker
        </InputLabel>
        <Select
          MenuProps={{ className: classes.selectMenu }}
          classes={{ select: classes.select }}
          value={this.state.truckerId}
          onChange={this.handleTrucker}
          inputProps={{ name: 'truckerName', id: 'simple-select' }}
        >
          <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
            Select Trucker
          </MenuItem>
          {this.state.truckers.map((trucker, i) => (
            <MenuItem
              key={i}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={trucker.truckerId}
            >
              {trucker.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  getTruckTypes() {
    const { classes } = this.props;
    return (
      <FormControl
        id="select-truck"
        fullWidth
        className={classes.selectFormControl}
      >
        <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
          Select Truck
        </InputLabel>
        <Select
          MenuProps={{ className: classes.selectMenu }}
          classes={{ select: classes.select }}
          value={this.state.truckNumber}
          onChange={this.handleTrucks}
          inputProps={{ name: 'truckNumber', id: 'simple-select' }}
        >
          <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
            Select Truck
          </MenuItem>
          {this.state.trucks.map((truck, i) => (
            <MenuItem
              key={i}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={truck.truckNumber}
            >
              {truck.truckNumber}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  render() {
    const { classes } = this.props;
    const {
      transporters,
      selectedTransporter,
      selectedTrucker,
      truckers,
      loadingDate,
    } = this.state;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {this.state.alert}
            <Card>
              <CardHeader className={`${classes.cardHeader}`} color="info">
                <CardIcon
                  color="primary"
                  className={`${classes.cardHeader}`}
                  onClick={() => this.goToListView()}
                >
                  <ArrowBack />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Edit transaction #
                  {this.props.currentTransactionData.transactionSerial}
                  <small />
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem id="select-transporter" xs={12} sm={12} md={6}>
                    <InputLabel className={classes.labelText}>
                      Select Transporter
                    </InputLabel>
                    <ReactSelect
                      value={selectedTransporter}
                      onChange={this.handleTransporter}
                      options={transporters}
                      placeholder="Select Transporter"
                    />
                    {this.state.transporterNameState === 'error' && (
                      <Danger>{this.state.transporterNameState}</Danger>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <InputLabel className={classes.labelText}>
                      Select Trucker
                    </InputLabel>
                    <ReactSelect
                      value={selectedTrucker}
                      onChange={this.handleTrucker}
                      options={truckers}
                      placeholder="Select Trucker"
                    />
                    {this.state.truckerNameState === 'error' && (
                      <Danger>{this.state.truckerNameState}</Danger>
                    )}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    {this.selectFromCities()}
                    {this.state.fromCityState === 'error' && (
                      <Danger>{this.state.fromCityState}</Danger>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    {this.selectToCities()}
                    {this.state.toCityState === 'error' && (
                      <Danger>{this.state.toCityState}</Danger>
                    )}
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Truck Number"
                      id="truck number"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'truckNumber', 'length', 2),
                        type: 'length',
                        value: this.state.truckNumber,
                      }}
                      success={this.state.truckNumberState === 'success'}
                      error={this.state.truckNumberState === 'error'}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Truck Type"
                      id="freight-value"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'truckType', 'length', 2),
                        type: 'length',
                        value: this.state.truckType,
                      }}
                      success={this.state.truckTypeState === 'success'}
                      error={this.state.truckTypeState === 'error'}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <InputLabel className={classes.label}>
                      Created Date
                    </InputLabel>
                    <br />
                    <FormControl fullWidth>
                      <Datetime
                        onChange={this.loadingDateChange}
                        value={loadingDate}
                        timeFormat={false}
                        inputProps={{ placeholder: 'Select Date' }}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Freight Value"
                      id="freight-value"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'amount', 'number'),
                        type: 'number',
                        value: this.state.amount,
                      }}
                      success={this.state.amountState === 'success'}
                      error={this.state.amountState === 'error'}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Commision"
                      id="commission"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'commission', 'number'),
                        type: 'number',
                        value: this.state.commission,
                      }}
                      success={this.state.commissionState === 'success'}
                      error={this.state.commissionState === 'error'}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Bonus"
                      id="bonus"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'bonus', 'number'),
                        type: 'number',
                        value: this.state.bonus,
                      }}
                      success={this.state.bonusState === 'success'}
                      error={this.state.bonusState === 'error'}
                    />
                  </GridItem>
                </GridContainer>
                {this.state.isLoading ? (
                  <CircularProgress
                    className={classes.progress}
                    style={{ color: purple[500] }}
                    thickness={7}
                  />
                ) : (
                  <Button
                    id="submit-transaction"
                    color="info"
                    onClick={this.submitTransaction}
                    className={classes.updateProfileButton}
                  >
                    Submit
                  </Button>
                )}
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
AddTransactions.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userDetails: state.main.userDetails,
  isDetailedPage: state.transactions.isDetailedPage,
  currentTransactionData: state.transactions.currentTransactionData,
  pod: state.transactions.pod,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchActivePage,
      setCurrentTransaction,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(AddTruckersStyle)(AddTransactions));
