/* eslint-disable no-empty */
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import logo from 'assets/img/Lobb-Logo.png';
import PersonAdd from '@material-ui/icons/PersonAdd';

// @material-ui/icons
import Dashboard from '@material-ui/icons/Dashboard';
import Menu from '@material-ui/icons/Menu';

// core components
import Button from 'components/CustomButtons/Button';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

import pagesRoutes from 'routes/pages.jsx';
import * as firebase from 'firebase';

import Cookies from 'universal-cookie';

import pagesHeaderStyle from 'assets/jss/material-dashboard-pro-react/components/pagesHeaderStyle.jsx';

const cookies = new Cookies();

class PagesHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      uid: '',
      isAuthenticated: false,
      isLoggedIn: false,
    };
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    const db = firebase.firestore();
    firebase.auth().onAuthStateChanged(
      user => {
        const usersRef = db.collection('Users');
        if (user) {
          // Check if user already exists
          this.setState({
            uid: user.uid,
            isLoggedIn: true,
          });
          usersRef
            .doc(user.uid)
            .get()
            .then(userDoc => {
              if (userDoc.exists) {
                cookies.set('userName', userDoc.data().name);
                this.setState({ isAuthenticated: true });
              }
            });
        } else {
          this.setState({
            isLoggedIn: false,
          });
          // if not logged go to login screen
          // this.props.history.push("/pages/login-page");
        }
      },
      err => {
        this.setState({
          isLoggedIn: false,
        });
      }
    );
  }

  handleDrawerToggle = () => {
    this.setState({ open: !this.state.open });
  };

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1;
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.setState({ open: false });
    }
  }

  /**
   * Will Logout
   * @function
   */
  logout() {
    firebase.auth().signOut();
    this.setState({
      isAuthenticated: false,
      isLoggedIn: false,
    });
    this.props.history.push('/pages/initial-page');
  }

  render() {
    const { classes, color, rtlActive } = this.props;
    const appBarClasses = cx({
      [` ${classes[color]}`]: color,
    });
    const logoMini = `${classes.logoMini} ${cx({
      [classes.logoMiniRTL]: rtlActive,
    })}`;

    //  const logoClasses =
    //   classes.logo +
    //   " " +
    //   cx({
    //     [classes.whiteAfter]: bgColor === "white"
    //   });

    const list = (
      <List className={classes.list}>
        {this.state.isAuthenticated ? (
          <React.Fragment>
            <ListItem className={classes.listItem}>
              <NavLink to="/pages/initial-page" className={classes.navLink}>
                <ListItemIcon className={classes.listItemIcon}>
                  <Dashboard />
                </ListItemIcon>
                <ListItemText
                  primary="Dashboard"
                  disableTypography
                  className={classes.listItemText}
                />
              </NavLink>
            </ListItem>
            <ListItem className={classes.listItem}>
              <Button
                color="transparent"
                className={classes.navLink}
                onClick={() => this.logout()}
              >
                Logout
              </Button>
            </ListItem>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {!this.state.isLoggedIn ? (
              <ListItem className={classes.listItem}>
                <NavLink to="/pages/initial-page" className={classes.navLink}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <Dashboard />
                  </ListItemIcon>
                  <ListItemText
                    primary="Login"
                    disableTypography
                    className={classes.listItemText}
                  />
                </NavLink>
              </ListItem>
            ) : (
              <ListItem className={classes.listItem}>
                <Button
                  color="transparent"
                  className={classes.navLink}
                  onClick={() => this.logout()}
                >
                  Logout
                </Button>
              </ListItem>
            )}
            <ListItem className={classes.listItem}>
              <NavLink to="/pages/initial-page" className={classes.navLink}>
                <ListItemIcon className={classes.listItemIcon}>
                  <PersonAdd />
                </ListItemIcon>
                <ListItemText
                  primary="Register"
                  disableTypography
                  className={classes.listItemText}
                />
              </NavLink>
            </ListItem>
          </React.Fragment>
        )}

        {pagesRoutes.map((prop, key) => {
          if (prop.redirect) {
            return null;
          }
          const navLink =
            classes.navLink +
            cx({
              [` ${classes.navLinkActive}`]: this.activeRoute(prop.path),
            });
          return (
            <ListItem key={key} className={classes.listItem}>
              <NavLink to={prop.path} className={navLink}>
                <ListItemIcon className={classes.listItemIcon}>
                  <prop.icon />
                </ListItemIcon>
                <ListItemText
                  primary={prop.short}
                  disableTypography
                  className={classes.listItemText}
                />
              </NavLink>
            </ListItem>
          );
        })}
      </List>
    );
    return (
      <AppBar position="static" className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container}>
          <Hidden smDown implementation="css">
            <div className={classes.flex}>
              <div className={classes.title}>
                <a href="https://agent.lobb.in/" className={logoMini}>
                  <img src={logo} alt="logo" className={classes.avatarImg} />
                </a>
              </div>
            </div>
          </Hidden>
          <Hidden mdUp>
            <div className={classes.flex}>
              <div className={classes.title}>
                <a href="https://agent.lobb.in/" className={logoMini}>
                  <img src={logo} alt="logo" className={classes.avatarImg} />
                </a>
              </div>
            </div>
          </Hidden>
          <GridContainer justify="flex-end">
            <GridItem>
              <Hidden smDown>{list}</Hidden>
            </GridItem>
          </GridContainer>
          <Hidden mdUp>
            <Button
              className={classes.sidebarButton}
              color="transparent"
              justIcon
              aria-label="open drawer"
              onClick={this.handleDrawerToggle}
            >
              <Menu />
            </Button>
          </Hidden>
          <Hidden mdUp implementation="css">
            <Hidden mdUp>
              <Drawer
                variant="temporary"
                anchor="right"
                open={this.state.open}
                classes={{ paper: classes.drawerPaper }}
                onClose={this.handleDrawerToggle}
                ModalProps={
                  { keepMounted: true } // Better open performance on mobile.
                }
              >
                {list}
              </Drawer>
            </Hidden>
          </Hidden>
        </Toolbar>
      </AppBar>
    );
  }
}

PagesHeader.defaultProps = {
  // bgColor: "",
};
PagesHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool,
  bgColor: PropTypes.oneOf(['white', 'black', 'blue']),
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
};

export default withStyles(pagesHeaderStyle)(PagesHeader);
