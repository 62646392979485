/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CustomTabs from 'components/CustomTabs/CustomTabs.jsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import AthHistory from './AthHistory';
import BthHistory from './BthHistory';
import { switchActivePage } from '../../../reducers/transactions';

class ReceivableHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      payableTransactions: [
        {
          color: 'info',
          data: ['28-09-2019', '10,000', 'message is'],
        },
      ],
      isPayableForm: false,
    };
    this.hideDetailedForm = this.hideDetailedForm.bind(this);
  }

  hideDetailedForm() {
    this.props.switchActivePage({
      isListPage: true,
      isDetailedPage: false,
      isEditPage: false,
    });
  }

  /**
   * Will return the Tabs ATH and BTH
   * @function
   */
  getTabs() {
    return (
      <CustomTabs
        headerColor="#fff"
        tabs={[
          {
            tabName: 'ATH',
            tabContent: (
              <AthHistory
                id="ath-history"
                transactionData={this.props.transactionData}
              />
            ),
          },
          {
            tabName: 'BTH',
            tabContent: (
              <BthHistory
                id="bth-history"
                transactionData={this.props.transactionData}
              />
            ),
          },
        ]}
      />
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader
          className={`${classes.cardHeader}`}
          style={{
            marginBottom: '1px',
            paddingBottom: '1px',
            paddingTop: '1px',
          }}
        >
          <h3 className={classes.cardTitle}>
            <span
              style={{
                fontWeight: '400',
                color: '#4caf50',
                fontFamily: 'Rajdhani',
              }}
            >
              {' '}
              Receivable:{' '}
            </span>
            <span
              style={{
                fontWeight: 'bold',
                color: '#163BAC',
                fontFamily: 'Rajdhani',
              }}
            >
              {' '}
              <i className="fa fa-inr" aria-hidden="true" />
              {Intl.NumberFormat('en-IN').format(
                this.props.currentReceivableAmount
              )}
            </span>
          </h3>
        </CardHeader>
        <CardBody
          id="receivable-history"
          style={{
            marginBottom: '1px',
            paddingBottom: '1px',
            paddingTop: '1px',
          }}
        >
          {this.getTabs()}
        </CardBody>
      </Card>
    );
  }
}

ReceivableHistory.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isDetailedPage: state.transactions.isDetailedPage,
  currentTransactionData: state.transactions.currentTransactionData,
  transactions: state.transactions.transactions,
  payableTransactions: state.transactions.payableTransactions,
  currentReceivableAmount: state.transactions.currentReceivableAmount,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchActivePage,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(TransactionStyle)(ReceivableHistory));
