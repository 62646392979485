import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import firebase from "../../config/config";
import CircularProgress from "@material-ui/core/CircularProgress";
import purple from "@material-ui/core/colors/purple";
// import Cookies from "universal-cookie";
import InfoArea from "components/InfoArea/InfoArea.jsx";
import Info from "@material-ui/icons/Info";
import {
  setUserDetails
 } from "../../reducers/main";

import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";

// const cookies = new Cookies();
class InitialPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      name: "",
      email: "",
      companyName: "",
      contactNumber: "",
      uid: '',
      isLoading: false
    };
    this.checkAuth = this.checkAuth.bind(this);
  }

  componentDidMount() {
    // Check weather the user is Authenticated
    this.checkAuth()
  }

  /**
   * Check weather the user is Authenticated
   * @function
   */
  checkAuth(){
      let _me = this
      this.setState({
          isLoading: true
      })
    const db = firebase.firestore();
    const usersRef = db.collection("Users");
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // Check if user already exists
        this.setState({
          uid: user.uid
        })
        usersRef.doc(user.uid).onSnapshot(doc=>{
          this.props.setUserDetails(doc.data(),user.uid);
        });
        usersRef
          .doc(user.uid)
          .get()
          .then(userDoc => {
            this.props.setUserDetails(userDoc.data(),user.uid);
            if (userDoc.exists) {
              //Update the Last signed In 
                usersRef.doc(user.uid).update({
                  lastSignIn : new Date()
                })
                if(userDoc.data().isAdmin === true){
                  _me.props.history.push("/admin/dashboard");
                }else if(userDoc.data().isAgent === true){
                    _me.props.history.push("/agent/dashboard");
                } else if (userDoc.data().isAgent === 'pending'){
                   _me.setState({
                       isPending: true
                   })
                }
              _me.setState({ isLoading: false });
            } else {
              _me.props.history.push("/pages/register-page");
              _me.setState({ isLoading: false });
            }
          }, err => {
            console.log("no users found error", err)
          })
      } else {
        //if not logged go to login screen
        _me.setState({
          isLoading: false
        })
        _me.props.history.push("/pages/login-page");
      }
    });
  }
  
  /**
   * Update the User Details in Db
   * @function
   */
  updateExhibitorInUsers(userDetails){
    // let _me = this
    const databaseRef = firebase
      .database()
      .ref(`Users/${userDetails.uid}/`);
    databaseRef.update(userDetails).then(() => {
      // updated successfully
      // _me.props.history.push('')
    }, err => {
      // not updated successfully
    });
  }

  render() {
    const { classes } = this.props;
    return <div className={classes.container}>
      <GridContainer justify="center">
        {this.state.isLoading &&
          <GridItem style={{marginTop:'150px'}}>
            <CircularProgress className={classes.progress} style={{ color: purple[500] }} thickness={7} />
          </GridItem>
         }
         {
        this.state.isPending &&
        <GridItem style={{marginTop:'150px'}}>
            <InfoArea title="Pending" description="Please Wait for the Approval" icon={Info} iconColor="rose" />
        </GridItem>
     }
      </GridContainer>
    </div>;
  }
}

InitialPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
     setUserDetails
    },
    dispatch
  );

export default connect(null,
  mapDispatchToProps,
)(withStyles(registerPageStyle)(InitialPage));
