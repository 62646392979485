/* eslint-disable react/no-array-index-key */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable guard-for-in */
/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import Info from 'components/Typography/Info.jsx';
import firebase from '../../config/config';

const db = firebase.firestore();

class Cities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transporters: [],
      companyID: '',
      uid: '',
      cities: [],
      isLoading: false,
    };
    this.getTable = this.getTable.bind(this);
    this.AddCities = this.AddCities.bind(this);
  }

  componentDidMount() {
    const userRef = db.collection('Users');
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({ isLoading: true });
        userRef
          .doc(`${user.uid}`)
          .get()
          .then(resultData => {
            if (resultData.exists) {
              const cities = [];
              if (resultData.data().cities) {
                for (const i in resultData.data().cities) {
                  cities.push(resultData.data().cities[i]);
                }
              }
              this.setState({ cities: cities.sort(), isLoading: false });
            }
          });
      } else {
        this.setState({
          isLoading: false,
        });
      }
    });
  }

  /**
   * Will return the Table Component
   * @function
   */
  getTable() {
    return (
      <GridContainer justify="center">
        {this.state.cities.map((cityName, i) => (
          <GridItem xs={6} md={2} lg={2} key={i}>
            <Card>
              <CardBody>
                <Info>{cityName}</Info>
              </CardBody>
            </Card>
          </GridItem>
        ))}
      </GridContainer>
    );
  }

  /**
   * will redirect to Add Truckers Page
   * @function
   */
  AddCities() {
    this.props.history.push('/agent/cities/add');
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader className={`${classes.cardHeader}`} color="warning">
                <p className={`${classes.cardHeader}`}>Cities</p>
                <h3 className={classes.cardTitle}>
                  {this.state.cities.length} <small />
                </h3>
              </CardHeader>
              <CardBody
                className={`${classes.cardHeader} ${classes.textCenter}`}
              >
                {this.state.isLoading ? (
                  <CircularProgress
                    className={classes.progress}
                    style={{ color: purple[500] }}
                    thickness={7}
                  />
                ) : (
                  <React.Fragment>
                    {this.state.cities.length <= 0 ? (
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <p className={classes.textCenter}>
                          No Cities Available
                        </p>
                        <div className={classes.textCenter}>
                          <Button
                            round
                            color="warning"
                            onClick={this.AddCities}
                          >
                            Add Cities
                          </Button>
                        </div>
                      </GridItem>
                    ) : (
                      <GridContainer justify="center">
                        {this.getTable()}
                      </GridContainer>
                    )}
                  </React.Fragment>
                )}
              </CardBody>
              <CardFooter stats>
                <div className={classes.stats} />
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Cities.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(TransactionStyle)(Cities);
