// ##############################
// // // Dashboard View styles
// #############################

import {
  successColor,
  tooltip,
  cardTitle,
} from 'assets/jss/material-dashboard-pro-react.jsx';
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx';

import hoverCardStyle from 'assets/jss/material-dashboard-pro-react/hoverCardStyle.jsx';

const dashboardStyle = {
  ...hoverCardStyle,
  ...buttonStyle,
  ...customCheckboxRadioSwitch,
  tooltip,
  cardTitle: {
    ...cardTitle,
    fontFamily: 'Rajdhani',
    marginTop: '0px',
    marginBottom: '3px',
  },
  textCenter: {
    textAlign: 'center',
  },
  content: {
    paddingTop: '18vh',
    minHeight: 'calc(100vh - 80px)',
    position: 'relative',
    zIndex: '4',
  },
  justifyContentCenter: {
    justifyContent: 'center !important',
  },
  cardHeader: {
    fontFamily: 'Rajdhani',
    marginBottom: '20px',
  },
  cardIconTitle: {
    ...cardTitle,
    fontFamily: 'Rajdhani',
    marginTop: '15px',
    marginBottom: '0px',
  },
  cardProductTitle: {
    ...cardTitle,
    fontFamily: 'Rajdhani',
    marginTop: '0px',
    marginBottom: '3px',
    textAlign: 'center',
  },
  cardCategory: {
    color: '#999999',
    fontFamily: 'Rajdhani',
    fontSize: '14px',
    paddingTop: '10px',
    marginBottom: '0',
    marginTop: '0',
    margin: '0',
  },
  cardProductDesciprion: {
    fontFamily: 'Rajdhani',
    textAlign: 'center',
    color: '#999999',
  },
  stats: {
    color: '#999999',
    fontSize: '12px',
    lineHeight: '22px',
    display: 'inline-flex',
    '& svg': {
      position: 'relative',
      top: '4px',
      width: '16px',
      height: '16px',
      marginRight: '3px',
    },
    '& i': {
      position: 'relative',
      top: '4px',
      fontSize: '16px',
      marginRight: '3px',
    },
  },
  productStats: {
    paddingTop: '7px',
    paddingBottom: '7px',
    margin: '0',
  },
  successText: {
    color: successColor,
  },
  upArrowCardCategory: {
    width: 14,
    height: 14,
  },
  underChartIcons: {
    width: '17px',
    height: '17px',
  },
  price: {
    color: 'inherit',
    '& h4': {
      marginBottom: '0px',
      marginTop: '0px',
    },
  },
  rajdhaniFont: {
    fontFamily: 'Rajdhani',
  },
  datePicker: {
    border: 'none',
    borderBottom: 'solid #b7b2b2 1.2px',
    marginLeft: '15px',
    marginTop: '10px',
  },
  rupeeIcon: {
    color: '#163BAC',
  },
  searchInput: {
    color: '#FFF',
    fontSize: '20px',
  },
};

export default dashboardStyle;
