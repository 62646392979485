import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import PropTypes from "prop-types";

// @material-ui/icons
import TransferWithinAStation from "@material-ui/icons/TransferWithinAStation";
import Add from "@material-ui/icons/Add";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Danger from "components/Typography/Danger.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import purple from "@material-ui/core/colors/purple";
import Info from "components/Typography/Info.jsx";

import SweetAlert from "react-bootstrap-sweetalert";

// Fireabse
import firebase from "../../config/config";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

const db = firebase.firestore();

class AddCities extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
     
      city: "",
      cityState: "",

      state: "",
      stateState: "",
      isLoading: false,

      alert: null,
      cities: [],
      citiesState: [],
      selectedCities:[],
      addedCities:[]
    };
    this.change = this.change.bind(this);
    this.submitCities = this.submitCities.bind(this);
    this.validateAllFields = this.validateAllFields.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.getMultiselectForm = this.getMultiselectForm.bind(this);
    this.handleCities = this.handleCities.bind(this);
    this.addCity = this.addCity.bind(this);
  }

  componentDidMount() {
    const userRef = db.collection("Users");
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({ isLoading: true });
        userRef
          .doc(`${user.uid}`)
          .get()
          .then(resultData => {
            if (resultData.exists) {
              let cities = [];
              if (resultData.data().cities) {
                cities = resultData.data().cities;
              }
              this.setState({
                cities,
                isLoading: false
              });
            }
          });
      } else {
        this.setState({
          isLoading: false
        });
      }
    });
  }
  handleCities = event => {
      this.setState({ cities: event.target.value });
  };
  hideAlert() {
    this.setState({
      alert: null
    });
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
     
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [stateName + "State"]: "success",
            [stateName]: event.target.value
          });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            [stateName]: event.target.value
          });
        }
        break;
      default:
        break;
    }
  }
  // Will Enter The manual City Name To The List
  addCity() {
    if (this.verifyLength(this.state.city, 1)) {
      this.setState({
        cityState: "success"
      });
        let addedCities = this.state.addedCities
        addedCities.push(this.state.city)
        this.setState({ addedCities,city:''})
    } else {
      this.setState({ cityState: "error" });
    }
  }

  // validates all required fields and returns a promise
  validateAllFields() {
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify Name
      //concat Added Cities and Selected Cities
      let finalCities = this.state.cities.concat(this.state.addedCities)
        if (finalCities.length > 0){
          error = false
            this.setState({ citiesState: '' })
      }else{
            this.setState({
              citiesState: "Please Add Cites and then Submit"
            });
          error = true
      }
      if (!error) {
        resolve(true);
      } else {
        reject("validation Failed");
      }
    });
  }

  /**
   * Will return the Table Component
   * @function
   */
  getTable() {
    const { classes } = this.props;
    let tableData = this.state.cities.concat(this.state.addedCities)
      return <GridContainer justify="center">
          <GridItem xs={12} md={12} lg={12} >
              <h4 className={classes.textCenter}>Cities</h4>
          </GridItem>
              {
                  tableData.length > 0 ?
                  <React.Fragment>
                      {tableData.map((cityName, i) => {
                          return <GridItem xs={6} md={4} lg={4} key={i}>
                              <Card style={{ margin: "2px", padding: "2px" }}>
                                  <CardBody>
                                      <Info>{cityName}</Info>
                                  </CardBody>
                              </Card>
                          </GridItem>;
                      })}
                  </React.Fragment>
                  :
                  <p className={classes.textCenter}>No Cities Added</p>
              }
        </GridContainer>;
  }

  /**
   * Submits the Cities details to the Database
   * @function
   */
  submitCities() {
    let _me = this;
    this.setState({
      isLoading: true
    });
    this.validateAllFields()
      .then(
        success => {
          //   let _me = this;
          if (success) {
            let finalCities = this.state.cities.concat(this.state.addedCities)
            const user = firebase.auth().currentUser;
            const userRef = db.collection(`Users`);
              userRef
              .doc(user.uid)
              .update({
                cities: finalCities,
                modifiedAt: new Date()
              })
              .then(userDoc => {
                //Updated Succeessfully
                this.clearForm();
                _me.setState({ isLoading: false });
              });
          }
        },
        err => {
          console.log(err);
          _me.setState({
            isLoading: false
          });
        }
      )
      .catch(err => {
        console.log(err);
        _me.setState({
          isLoading: false
        });
      });
  }

  /**
   * Will Clears ALl the State values after form submision
   * @function
   */
  clearForm() {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Good job!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >
          Cities Added Succeessfully
        </SweetAlert>
      )
    });
    let _me = this;
    _me.setState({
      city: "",
      cityState: "",
      state: "",
      stateState: "",
      citiesState: '',
    });
  }

  getMultiselectForm() {
    const { classes } = this.props;
    return (
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel htmlFor="multiple-select" className={classes.selectLabel}>
            Choose City
          </InputLabel>
          <Select
            multiple
            value={this.state.cities}
            onChange={this.handleCities}
            MenuProps={{ className: classes.selectMenu }}
            classes={{ select: classes.select }}
            inputProps={{ name: "multipleSelect", id: "multiple-select" }}
          >
            <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
              Choose City
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value="Agra"
            >
              Agra
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value="Ahmedabad"
            >
              Ahmedabad
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value="Bengaluru"
            >
              Bengaluru
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value="Chennai"
            >
              Chennai
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value="Coimbatore"
            >
              Coimbatore
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value="Hyderabad"
            >
              Hyderabad
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value="Kolkata"
            >
              Kolkata
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value="Kochi"
            >
              Kochi
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value="Lucknow"
            >
              Lucknow
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value="Mumbai"
            >
              Mumbai
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value="Mysore"
            >
              Mysore
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value="Nagpur"
            >
              Nagpur
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected
              }}
              value="Nashik"
            >
              Nashik
            </MenuItem>
          </Select>
        </FormControl>
        <Danger>{this.state.citiesState}</Danger>
      </GridItem>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {this.state.alert}
            <Card>
              <CardHeader className={`${classes.cardHeader}`} color="warning">
                <CardIcon color="primary" className={`${classes.cardHeader}`}>
                  <TransferWithinAStation />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Add Cities <small />
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    {this.getMultiselectForm()}
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={7}>
                        <CustomInput
                          labelText="Enter City Name *"
                          id="city"
                          formControlProps={{ fullWidth: true }}
                          success={this.state.cityState === "success"}
                          error={this.state.cityState === "error"}
                          inputProps={{
                            onChange: event =>
                              this.change(event, "city", "length", 1),
                            type: "length",
                            value: this.state.city
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <Button
                          color="info"
                          justIcon
                          onClick={this.addCity}
                          style={{ marginTop: "10px" }}
                          // className={classes.updateProfileButton}
                        >
                          <Add />
                        </Button>
                      </GridItem>
                      <GridItem sx={12} sm={12} md={12}>
                  {this.state.isLoading ? (
                  <CircularProgress
                    className={classes.progress}
                    style={{ color: purple[500] }}
                    thickness={7}
                  />
                ) : (
                  <Button
                    color="success"
                    onClick={this.submitCities}
                    className={classes.updateProfileButton}
                  >
                    Submit
                  </Button>
                )}
                <Clearfix />
                  </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    {this.getTable()}
                  </GridItem>
                  
                </GridContainer>
                
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
AddCities.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(userProfileStyles)(AddCities);
