/* eslint-disable no-prototype-builtins */
/* eslint-disable no-undef */
/* eslint-disable react/no-array-index-key */
/* eslint-disable class-methods-use-this */
/* eslint-disable guard-for-in */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import CardIcon from 'components/Card/CardIcon.jsx';
import Assignment from '@material-ui/icons/Assignment';
import Edit from '@material-ui/icons/Edit';
import Fuse from 'fuse.js';
import CustomInput from 'components/CustomInput/CustomInput.jsx';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Table from 'components/Table/Table.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import { switchTruckerActivePage } from '../../../reducers/truckers';
import history from '../../../history.js';
import firebase from '../../../config/config';

const db = firebase.firestore();

const keys = {
  phoneNumber: 'truckerData.phoneNumber',
  truckerName: 'truckerData.name',
};

const { phoneNumber, truckerName } = keys;

const fuseOptions = {
  shouldSort: true,
  threshold: 0.4,
  location: 0,
  distance: 50,
  maxPatternLength: 12,
  keys: [phoneNumber, truckerName],
};

class ViewTruckers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      truckers: [],
      companyID: '',
      uid: '',
      truckersData: [],
      truckersInitialData: [],
      isLoading: false,
      searchText: '',
    };
    this.getTable = this.getTable.bind(this);
    this.getTruckersData = this.getTruckersData.bind(this);
    this.AddTruckers = this.AddTruckers.bind(this);
  }

  componentDidMount() {
    this.getTruckersData();
  }

  openEditForm(data) {
    this.props.switchTruckerActivePage(true, data);
  }

  /**
   * Will Get ALl the Truckers For The User
   * @function
   */
  getTruckersData() {
    const userRef = db.collection('Users');
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({
          isLoading: true,
        });
        userRef
          .doc(`${user.uid}`)
          .get()
          .then(resultData => {
            if (resultData.exists) {
              this.setState({
                companyId: resultData.data().companyId,
              });
              const { companyId } = resultData.data();
              const colors = ['info', 'success'];
              const truckerRef = db.collection('Companies');
              const truckers = this.state.truckersData;
              truckerRef
                .doc(`${companyId}`)
                .collection('Truckers')
                .orderBy('name', 'asc')
                .get()
                .then(truckerData => {
                  this.setState({
                    isLoading: false,
                  });
                  let flag = 0;
                  truckerData.forEach(trucker => {
                    const truckerResult = trucker.data();
                    const { classes } = this.props;
                    const fillButtons = [{ color: 'info', icon: Edit }].map(
                      (prop, key) => (
                        <Button
                          color={prop.color}
                          className={classes.actionButton}
                          key={key}
                          onClick={() => this.openEditForm(truckerResult)}
                          justIcon
                        >
                          <prop.icon className={classes.icon} />
                        </Button>
                      )
                    );
                    //   Only Company Admin can Edit
                    truckers.push({
                      color: colors[flag],
                      data: [
                        truckerResult.name,
                        truckerResult.phoneNumber,
                        truckerResult.email || '___',
                        fillButtons,
                      ],
                      truckerData: truckerResult,
                    });

                    if (flag === 0) {
                      flag = 1;
                    } else {
                      flag = 0;
                    }
                  });
                  this.setState({
                    truckersData: truckers,
                    truckersInitialData: truckers,
                  });
                });
            }
          });
      } else {
        this.setState({
          isLoading: false,
        });
      }
    });
  }

  onSearch(e) {
    const { truckersInitialData } = this.state;
    const { target = {} } = e;
    const { value = '' } = target;
    if (value === '') {
      this.setState({
        truckersData: truckersInitialData,
        searchText: '',
      });
    } else {
      const fuse = new Fuse(truckersInitialData, fuseOptions);
      const data = value ? fuse.search(value) : truckersInitialData;
      const newData = [];
      data.forEach(val => {
        if (val.hasOwnProperty('item')) {
          newData.push(val.item);
        }
      });

      this.setState({ searchText: value, truckersData: newData });
    }
  }

  /**
   * Will return the Table Component
   * @function
   */
  getTable() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader color="rose" icon>
          <CardIcon color="danger">
            <Assignment />
          </CardIcon>
        </CardHeader>
        <CardBody className={classes.customCardContentClass}>
          <Table
            hover
            tableHead={['Name', 'Phone', 'Email']}
            tableData={this.state.truckersData}
          />
        </CardBody>
      </Card>
    );
  }

  /**
   * will redirect to Add Truckers Page
   * @function
   */
  AddTruckers() {
    history.push('/agent/truckers/add');
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  render() {
    const { classes } = this.props;
    const { searchText } = this.state;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader className={`${classes.cardHeader}`} color="danger">
                {/* <p className={`${classes.cardHeader}`}>Truckers</p>
                <h3 className={classes.cardTitle}>
                  {this.state.truckersData.length} <small />
                </h3> */}
                <GridContainer>
                  <GridItem xs={12} md={8} lg={10}>
                    <p
                      className={`${classes.cardHeader} ${classes.rajdhaniFont}`}
                    >
                      Truckers
                    </p>
                    <h3 className={classes.cardTitle}>
                      {this.state.truckersData.length} <small />
                    </h3>
                  </GridItem>
                  <GridItem xs={12} md={4} lg={2}>
                    <div style={{ float: 'right' }}>
                      <form>
                        <CustomInput
                          formControlProps={{
                            className: `${classes.top} ${classes.search}`,
                          }}
                          inputProps={{
                            placeholder: 'Search',
                            inputProps: {
                              'aria-label': 'Search',
                              value: searchText,
                              onChange: event => this.onSearch(event),
                              className: classes.searchInput,
                            },
                          }}
                        />
                      </form>
                    </div>
                  </GridItem>
                </GridContainer>
              </CardHeader>
              <CardBody
                className={`${classes.cardHeader} ${classes.textCenter}`}
              >
                {this.state.isLoading ? (
                  <CircularProgress
                    className={classes.progress}
                    style={{ color: purple[500] }}
                    thickness={7}
                  />
                ) : (
                  <React.Fragment>
                    {this.state.truckersData.length <= 0 ? (
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <p className={classes.textCenter}>
                          No Truckers Available
                        </p>
                        <div className={classes.textCenter}>
                          <Button
                            round
                            color="danger"
                            onClick={this.AddTruckers}
                          >
                            Add Truckers
                          </Button>
                        </div>
                      </GridItem>
                    ) : (
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        {this.getTable()}
                      </GridItem>
                    )}
                  </React.Fragment>
                )}
              </CardBody>
              <CardFooter stats>
                <div className={classes.stats} />
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

ViewTruckers.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isTruckerEditForm: state.truckers.isTruckerEditForm,
  userDetails: state.main.userDetails,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchTruckerActivePage,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(TransactionStyle)(ViewTruckers));
