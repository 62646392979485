import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import counter from './counter';
import transactions from './transactions';
import truckers from './truckers';
import transporters from './transporters';

import main from './main';

export default combineReducers({
  routing: routerReducer,
  counter,
  transactions,
  truckers,
  transporters,
  main,
});
