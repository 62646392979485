/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-state */
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import boy from 'assets/img/man.png';
import girl from 'assets/img/girl.png';
// core components
import Header from 'components/Header/Header.jsx';
import Footer from 'components/Footer/Footer.jsx';
import Sidebar from 'components/Sidebar/Sidebar.jsx';

import AdminDashRoutes from 'routes/adminDashboard.jsx';
// import RequireAuth from "../../app/auth";
import * as firebase from 'firebase';

import appStyle from 'assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx';

import logo from 'assets/img/Lobb-Logo.png';

const switchRoutes = (
  <Switch>
    {AdminDashRoutes.map((prop, key) => {
      if (prop.redirect)
        return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
      if (prop.collapse)
        return prop.views.map((prop, key) => (
          <Route path={prop.path} component={prop.component} key={key} />
        ));
      return <Route path={prop.path} component={prop.component} key={key} />;
    })}
  </Switch>
);

let ps;

class AdminDashboard extends React.Component {
  state = {
    mobileOpen: false,
    miniActive: false,
    name: '',
    isLoading: false,
    isAdmin: false,
    isMale: true,
    isFemale: false,
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  getRoute() {
    return this.props.location.pathname !== '/maps/full-screen-maps';
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
      isAdmin: false,
    });
    const db = firebase.firestore();
    const usersRef = db.collection('Users');
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = 'hidden';
    }
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // Check if user already exists
        usersRef
          .doc(user.uid)
          .get()
          .then(userDoc => {
            if (userDoc.exists) {
              // Succeess
              if (userDoc.data().gender === 'female') {
                this.setState({
                  isFemale: true,
                  isMale: false,
                });
              } else {
                this.setState({
                  isMale: true,
                  isFemale: false,
                });
              }
              if (userDoc.data().isAdmin === true) {
                this.setState({ isAdmin: true });
              }
              if (userDoc.data().isAdmin !== true) {
                this.props.history.push('/pages/initial-page');
              }
              this.setState({
                name: userDoc.data().name,
              });
            } else {
              this.props.history.push('/pages/initial-page');
            }
          });
      } else {
        // if not logged go to login screen
        this.props.history.push('/pages/login-page');
      }
    });
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }

  render() {
    let image = '';
    if (this.state.isMale) {
      image = boy;
    } else {
      image = girl;
    }

    const { classes, ...rest } = this.props;
    const mainPanel = `${classes.mainPanel} ${cx({
      [classes.mainPanelSidebarMini]: this.state.miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf('Win') > -1,
    })}`;
    return (
      <div className={classes.wrapper}>
        {this.state.isAdmin ? (
          <React.Fragment>
            <Sidebar
              routes={AdminDashRoutes}
              logoText="Lobb Agent"
              logo={logo}
              name={this.state.name}
              image={image}
              handleDrawerToggle={this.handleDrawerToggle}
              open={this.state.mobileOpen}
              color="blue"
              bgColor="black"
              miniActive={this.state.miniActive}
              {...rest}
            />
            <div className={mainPanel} ref="mainPanel">
              <Header
                sidebarMinimize={this.sidebarMinimize.bind(this)}
                miniActive={this.state.miniActive}
                routes={AdminDashRoutes}
                handleDrawerToggle={this.handleDrawerToggle}
                {...rest}
              />
              {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
              {this.getRoute() ? (
                <div className={classes.content}>
                  <div>{switchRoutes}</div>
                </div>
              ) : (
                <div className={classes.map}>{switchRoutes}</div>
              )}
              {this.getRoute() ? <Footer fluid /> : null}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Sidebar
              routes={[]}
              logoText="Lobb Agent"
              logo={logo}
              name={
                this.state.name // image={image}
              }
              handleDrawerToggle={this.handleDrawerToggle}
              open={this.state.mobileOpen}
              color="blue"
              bgColor="black"
              miniActive={this.state.miniActive}
              {...rest}
            />
            <div className={mainPanel} ref="mainPanel" />
          </React.Fragment>
        )}
      </div>
    );
  }
}

AdminDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(appStyle)(AdminDashboard);
