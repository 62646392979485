export const SET_USER_DETAILS = "main/SET_USER_DETAILS";
export const SET_ACTIVE_ROLE = "main/SET_ACTIVE_ROLE";

const initialState = {
    uid:'',
    userDetails:{},
    activeRole:''// sales, finance, admin, or ops
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_DETAILS:
            return {
                ...state,
                userDetails:action.userDetails,
                uid: action.uid
                
            };
        case SET_ACTIVE_ROLE:
        return {
            ...state,
            activeRole:action.role
            
        };
        default:
            return state;
    }
};

export const setUserDetails = (userDetails,uid) => {
    return dispatch => {
        dispatch({
            type: SET_USER_DETAILS,
            userDetails,
            uid
        });
    };
};

export const setActiveRole = (role) => {
    return dispatch => {
        dispatch({
            type: SET_ACTIVE_ROLE,
            role
        });
    };
};
