/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint no-underscore-dangle: 0 */

import React from 'react';
import { withRouter } from 'react-router-dom';
import { Route, Switch } from 'react-router';
import indexRoutes from 'routes/index.jsx';
import { Online } from 'react-detect-offline';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AddAlert from '@material-ui/icons/AddAlert';
import Snackbars from 'components/Snackbar/Snackbar.jsx';

import ReactGA from 'react-ga';
import firebase from '../config/config';
import { setUserDetails } from '../reducers/main';

const db = firebase.firestore();
/**
 * Represents a Root Componet.
 * @class
 */
class App extends React.Component {
  /*
   * Main app Starts
   */

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      connectionNotifStatus: '',
      connectionNotifText: '',
      userId: '',
    };
    this.onChangeConnection = this.onChangeConnection.bind(this);
    this.refreshPage = this.refreshPage.bind(this);
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        db.collection(`Users`)
          .doc(user.uid)
          .onSnapshot(doc => {
            this.props.setUserDetails(doc.data(), user.uid);
            let { isRefresh } = doc.data();
            if (isRefresh === undefined) {
              isRefresh = false;
            }
            this.setState({ loaded: true, isRefresh, userId: user.uid });
          })
          .catch(() => {
            this.setState({ loaded: true });
          });

        ReactGA.initialize('UA-105183664-4', {
          debug: true,
          titleCase: false,
          gaOptions: {
            userId: user.uid,
          },
        });
      } else {
        this.setState({ loaded: true });
        ReactGA.initialize('UA-105183664-4', {
          debug: true,
          titleCase: false,
        });
      }
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentDidUpdate() {
    /** all pages will start from the top */
    window.scrollTo(0, 0);
  }

  onChangeConnection(online) {
    // console.log('HELLO')
    if (!online) {
      this.setState(
        {
          // connectionNotif: true,
          connectionNotifStatus: 'danger',
          connectionNotifText: 'You are offline.',
        },
        this.showNotification('connectionNotif')
      );
    } else {
      this.setState(
        {
          // connectionNotif: true,
          connectionNotifStatus: 'success',
          connectionNotifText: 'You are back online.',
        },
        this.showNotification('connectionNotif')
      );
    }
  }

  showNotification(place) {
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      // use this to make the notification autoclose
      setTimeout(() => {
        // console.log('TIMEOUT');
        x[place] = false;
        this.setState(x);
      }, 6000);
    }
  }

  componentWillUnmount() {}

  /**
   * Will Refresh The Page
   * @function
   */
  refreshPage() {
    const { userId } = this.state;
    if (userId !== '' && userId !== undefined) {
      db.collection(`Users`)
        .doc(userId)
        .update({
          isRefresh: false,
          modifiedAt: new Date(),
        })
        .then(success => {
          window.location.reload();
        });
    }
  }

  /**
   * Logs out user
   * @function
   */

  render() {
    const {
      loaded,
      connectionNotif,
      connectionNotifText,
      isRefresh,
      connectionNotifStatus,
    } = this.state;
    return (
      <div>
        {loaded && (
          <Switch>
            {indexRoutes.map((prop, key) => (
              <Route path={prop.path} component={prop.component} key={key} />
            ))}
          </Switch>
        )}
        <Online onChange={this.onChangeConnection} />
        <Snackbars
          place="br"
          color={connectionNotifStatus}
          icon={AddAlert}
          message={connectionNotifText}
          open={connectionNotif}
          closeNotification={() => this.setState({ connectionNotif: false })}
          close
        />
        {isRefresh && (
          <Snackbars
            place="tc"
            color="success"
            icon={AddAlert}
            message="We’ve fine-tuned Lobb Agent under the hood. Refresh this tab to experience the latest Lobb Agent."
            open={isRefresh}
            actionButton
            actionButtonName="Refresh"
            onSubmit={this.refreshPage}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setUserDetails,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(withRouter(App));
