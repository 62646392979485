import React from 'react';
import PropTypes from 'prop-types';
// react plugin for creating charts
// react plugin for creating vector maps

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import Table from 'components/Table/Table.jsx';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import moment from 'moment';

import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import firebase from '../../config/config';

const db = firebase.firestore();

class AdminDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: {},
      transactionNos: [],
      users: [],
    };
    // this.getTransporterData = this.getTransporterData.bind(this);
    // this.getCompanyData = this.getCompanyData.bind(this);
    // this.getTruckersData = this.getTruckersData.bind(this);
    // this.getUsers = this.getUsers.bind(this);
  }

  componentDidMount() {
    // this.getCompanyData();
    // this.getUsers();
    this.getData();
  }

  getData() {
    db.collection('Companies')
      .get()
      .then(snapshot => {
        const companies = {};
        snapshot.forEach(company => {
          const {
            companyName,
            companyId,
            freeTrialStartDate,
            subscriptionStartDate,
          } = company.data();
          companies[company.data().companyId] = {
            companyName,
            companyId,
            freeTrialStartDate,
            subscriptionStartDate,
          };
        });
        this.setState({ companies }, () => {
          this.getCompaniesTransactions();
          this.getCompaniesUsers();
        });
      });
  }

  getCompaniesTransactions() {
    const { companies } = this.state;
    const nowDate = new Date();
    const beforeDate = new Date(new Date().setDate(nowDate.getDate() - 30));
    const transactionNos = [];
    const promises = [];
    Object.values(companies).forEach(company => {
      promises.push(
        db
          .collection('Companies')
          .doc(company.companyId)
          .collection('Transactions')
          .where('createdAt', '>=', beforeDate)
          .get()
      );
    });

    Promise.all(promises)
      .then(snapshots => {
        snapshots.forEach(snapshot => {
          transactionNos.push(snapshot.size);
        });
        this.setState({ transactionNos });
        // console.log(snapshot.size)
      })
      .catch(err => {
        console.log('ERROR:', err);
      });
  }

  getCompaniesUsers() {
    const { companies } = this.state;

    const users = [];
    const promises = [];
    Object.values(companies).forEach(company => {
      // users[company.companyId] = [];
      promises.push(
        db
          .collection('Users')
          .where('companyId', '==', company.companyId)
          .get()
      );
    });

    Promise.all(promises).then(snapshots => {
      snapshots.forEach(snapshot => {
        const companyUsers = [];
        snapshot.forEach(user => {
          const {
            name,
            isCompanyAdmin,
            createdAt,
            lastSignIn,
            phoneNumber,
          } = user.data();
          let role;
          if (isCompanyAdmin) role = 'Company Admin';
          else role = 'Agent';

          let lastSignInDate = '';
          let signUpDate = '';
          if (
            createdAt === undefined ||
            createdAt === null ||
            createdAt === ''
          ) {
            signUpDate = 'N/A';
          } else {
            signUpDate = moment(createdAt.toDate()).format('Do MMM Y');
          }
          if (
            lastSignIn === undefined ||
            lastSignIn === null ||
            lastSignIn === ''
          ) {
            lastSignInDate = 'N/A';
          } else {
            lastSignInDate = moment(lastSignIn.toDate()).format('Do MMM Y');
          }
          // console.log(moment(createdAt.toDate()))

          companyUsers.push([
            name,
            role,
            signUpDate,
            lastSignInDate,
            phoneNumber,
          ]);
        });
        users.push(companyUsers);
      });
      this.setState({ users });
    });
  }

  render() {
    const { companies, transactionNos, users } = this.state;

    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader className={`${classes.cardHeader}`} color="warning">
                <h4 style={{ color: '#ffffff' }} className={classes.cardTitle}>
                  Reports
                </h4>
              </CardHeader>
              <CardBody className={`${classes.cardBody}`}>
                <GridContainer justify="center">
                  {companies &&
                  transactionNos.length > 0 &&
                  users.length > 0 ? (
                    Object.values(companies).map((company, i) => (
                      <GridItem xs={12}>
                        <Card>
                          <CardHeader>
                            <h4
                              style={{ fontWeight: 'bold' }}
                              className={classes.cardTitle}
                            >
                              {company.companyName}
                            </h4>
                            <p style={{ fontWeight: 'bold' }}>
                              {transactionNos[i]} Transactions in the past month
                            </p>
                          </CardHeader>
                          <CardBody>
                            <Table
                              hover
                              tableHeaderColor="warning"
                              tableHead={[
                                'Name',
                                'Role',
                                'Sign Up',
                                'Last Sign In',
                                'Phone Number',
                              ]}
                              tableData={users[i]}
                            />
                          </CardBody>
                        </Card>
                      </GridItem>
                    ))
                  ) : (
                    <CircularProgress
                      className={classes.progress}
                      style={{ color: purple[500] }}
                      thickness={7}
                    />
                  )}
                </GridContainer>
              </CardBody>
              <CardFooter stats>
                <div className={classes.stats} />
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

AdminDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(AdminDashboard);
