/* eslint-disable camelcase */
/* eslint-disable no-console */
/* eslint-disable no-prototype-builtins */
/* eslint-disable vars-on-top */
/* eslint-disable guard-for-in */
/* eslint-disable no-var */
/* eslint-disable block-scoped-var */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable consistent-return */
/* eslint-disable func-names */
/* eslint-disable no-undef */
/* eslint-disable prefer-destructuring */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/order */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/Icons

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import Button from 'components/CustomButtons/Button.jsx';
import classNames from 'classnames';
import SweetAlert from 'react-bootstrap-sweetalert';
import InputLabel from '@material-ui/core/InputLabel';

import FormControl from '@material-ui/core/FormControl';
import Datetime from 'react-datetime';
import ReactSelect from 'react-select';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import AddAlert from '@material-ui/icons/AddAlert';
import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import CustomInput from 'components/CustomInput/CustomInput.jsx';

// Component for Transactions Card
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import TransactionsCard from './Sections/TransactionsCard';

import DetailedTransactions from './Sections/DetailedTransactions';
import EditTransaction from './Sections/EditTransaction';

import { switchActivePage, setTransactions } from '../../reducers/transactions';
import firebase from '../../config/config';
import history from '../../history.js';
// import apiCaller from '../../utils/apiCaller';

const db = firebase.firestore();
const moment = require('moment');

const exportExcel = firebase.functions().httpsCallable('exportExcel');

class Transactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      isDetailedForm: false,
      isFilter: false,
      checksAlert: false,
      selectedTransporter: '',
      transporterName: '',
      searchText: '',
      transporterId: '',
      selectedTrucker: '',
      selectedTruckerCopy: '',
      truckerName: '',
      truckerId: '',
      fromDate: '',
      toDate: '',
      transactions: [],
      isPod: false,
      truckersCopy: [],
      excelChecksAlert: false,
      excelchecksError: '',
      isChangeColor: false,
      excelFromDate: '',
      excelToDate: '',
      errMessage: '',
      infoMessage: '',
      excelLoading: false,
      progressValue: 10,
      progressBuffer: 15,
      isDownload: false,
      truckNumber: '',
    };
    this.addTransactions = this.addTransactions.bind(this);
    this.goToAddUser = this.goToAddUser.bind(this);
    this.showChecksAlert = this.showChecksAlert.bind(this);
    this.closeChecksAlert = this.closeChecksAlert.bind(this);
    this.handleTrucker = this.handleTrucker.bind(this);
    this.handleTransporter = this.handleTransporter.bind(this);
    this.toDateChange = this.toDateChange.bind(this);
    this.fromDateChange = this.fromDateChange.bind(this);
    this.confirmDates = this.confirmDates.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.excelShowChecksAlert = this.excelShowChecksAlert.bind(this);
    this.excelCloseChecksAlert = this.excelCloseChecksAlert.bind(this);
    this.resendExcel = this.resendExcel.bind(this);
    this.change = this.change.bind(this);
  }

  componentDidMount() {
    window.hopscotch.endTour([]);
    this.getUserCompanyName();
    firebase.auth().onAuthStateChanged(user => {
      const mainRef = db.collection('Users').doc(user.uid);
      this.getTransactions();
      mainRef.get().then(userData => {
        if (userData.data().companyId) {
          const transactionsRef = db
            .collection('Companies')
            .doc(userData.data().companyId)
            .collection(`Transactions`)
            .where('companyId', '==', userData.data().companyId)
            .orderBy('createdAt', 'desc');
          transactionsRef.onSnapshot(resultData => {
            const transactions = [];
            resultData.forEach(transaction => {
              transaction.data().createdAt = moment().format(
                'MMM Do YY',
                transaction.data().createdAt
              );
              transactions.push(transaction.data());
            });
            this.props.setTransactions(transactions);
            if (transactions.length > 0) {
              if (this.props.userDetails.onBoard.transactionEdit) {
                this.openTour();
              }
            }
            this.setState({
              transactions,
            });
          });
        }
      });
    });
  }

  // eslint-disable-next-line class-methods-use-this
  filterCheck(data) {
    let action = '';
    if (data !== '' && data !== null && data !== undefined) {
      action = true;
    } else {
      action = false;
    }
    return action;
  }

  handleToggle(value) {
    this.setState({
      isPod: value,
    });
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        break;
    }
  }

  /**
   * will return ALl running transactions of the User
   * @param {object} user
   */
  getTransactions() {
    const {
      isFilter,
      fromDate,
      toDate,
      transporterId,
      truckerId,
      isPod,
      truckNumber,
    } = this.state;
    let startDate = '';
    let endDate = '';
    // To restrict the dates to be automatically taken
    if (fromDate === '') {
      startDate = null;
    } else {
      startDate = new Date(fromDate);
    }
    if (toDate === '') {
      endDate = null;
    } else {
      endDate = new Date(toDate);
      endDate = endDate.setHours(23, 59, 59, 999);
      endDate = new Date(endDate);
    }
    firebase.auth().onAuthStateChanged(user => {
      const mainRef = db.collection('Users').doc(user.uid);
      mainRef.get().then(userData => {
        if (userData.exists) {
          if (userData.data().companyId) {
            let TransactionsRef = db
              .collection('Companies')
              .doc(userData.data().companyId)
              .collection('Transactions')
              .where('companyId', '==', userData.data().companyId)
              .orderBy('createdAt', 'desc');
            if (isFilter) {
              const fromDateAction = this.filterCheck(startDate);
              const toDateAction = this.filterCheck(endDate);
              const transporterAction = this.filterCheck(transporterId);
              const truckerAction = this.filterCheck(truckerId);
              const truckNumberAction = this.filterCheck(truckNumber);

              if (truckNumberAction) {
                TransactionsRef = TransactionsRef.where(
                  'vehicleNumber',
                  '==',
                  truckNumber
                );
              }
              if (isPod) {
                TransactionsRef = TransactionsRef.where('pod', '==', true);
              }
              if (fromDateAction && toDateAction) {
                TransactionsRef = TransactionsRef.where(
                  'createdAt',
                  '>',
                  startDate
                ).where('createdAt', '<', endDate);
              }
              if (transporterAction) {
                TransactionsRef = TransactionsRef.where(
                  'transporterId',
                  '==',
                  transporterId
                );
              }
              if (truckerAction) {
                TransactionsRef = TransactionsRef.where(
                  'truckerId',
                  '==',
                  truckerId
                );
              }
            } else {
              // null
            }
            TransactionsRef.onSnapshot(snapData => {
              const transactions = [];
              snapData.forEach(resultData => {
                transactions.push(resultData.data());
              });
              this.setState({ transactions });
            });
          }
        }
      });
    });
  }

  excelShowChecksAlert() {
    this.setState({
      excelChecksAlert: true,
      excelchecksError: '',
    });
  }

  excelCloseChecksAlert() {
    this.setState({
      excelChecksAlert: false,
      excelchecksError: '',
      selectedTransporter: '',
      excelEpocFromDate: '',
      excelEpocToDate: '',
      isChangeColor: false,
      // selectedTrucker: null,
    });
  }

  excelFromDateChange(excelFromDate) {
    this.setState({ excelFromDate });
  }

  excelToDateChange(excelToDate) {
    this.setState({ excelToDate });
  }

  excelModalWindow() {
    const { classes } = this.props;
    const {
      excelFromDate,
      excelToDate,
      isDownload,
      progressValue,
      progressBuffer,
    } = this.state;
    const yesterday = Datetime.moment().subtract(0, 'day');
    const valid = function(current) {
      return current.isBefore(yesterday);
    };
    const tomorrow = Datetime.moment(excelFromDate).subtract(1, 'day');
    const oneMonth = Datetime.moment(excelFromDate).add(2, 'month');
    const validat = function(current) {
      if (
        current.isAfter(tomorrow) &&
        current.isBefore(yesterday) &&
        current.isBefore(oneMonth)
      ) {
        return true;
      }
    };
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <SweetAlert
            showCancel
            confirmBtnText={this.state.excelLoading ? 'Loading...' : 'Download'}
            title=""
            onConfirm={val => this.excelConfirmDates(val)}
            onCancel={val => this.excelCloseChecksAlert(val)}
            confirmBtnCssClass={`${this.props.classes.button} ${
              this.state.excelLoading
                ? this.props.classes.info
                : this.props.classes.success
            }`}
            cancelBtnCssClass={`${this.props.classes.button} ${this.props.classes.error}`}
            style={{ height: '60%' }}
          >
            <h3 style={{ marginBottom: '0px' }}>
              <span className={classes.rajdhaniFont}>
                Download Transactions
              </span>
            </h3>
            {isDownload ? (
              <LinearProgress
                variant="buffer"
                value={progressValue}
                valueBuffer={progressBuffer}
              />
            ) : (
              ''
            )}
            <GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={2} />
                <GridItem xs={12} sm={12} md={8} style={{ marginTop: '20px' }}>
                  <InputLabel className={classes.label}>From Date *</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      onChange={val => this.excelFromDateChange(val)}
                      value={excelFromDate}
                      dateFormat="DD-MM-YYYY"
                      timeFormat={false}
                      inputProps={{ placeholder: 'Select Date' }}
                      isValidDate={valid}
                      required
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={2} />
                <GridItem xs={12} sm={12} md={8} style={{ marginTop: '20px' }}>
                  <InputLabel className={classes.label}>To Date *</InputLabel>
                  <br />
                  <FormControl fullWidth>
                    <Datetime
                      onChange={val => this.excelToDateChange(val)}
                      value={excelToDate}
                      dateFormat="DD-MM-YYYY"
                      timeFormat={false}
                      inputProps={{ placeholder: 'Select Date' }}
                      isValidDate={validat}
                      required
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
            </GridContainer>
            <Snackbar
              place="tc"
              color="danger"
              icon={AddAlert}
              message="Select From and To date."
              open={this.state.tc}
              closeNotification={() => this.setState({ tc: false })}
              close
            />
          </SweetAlert>
        </GridItem>
      </GridContainer>
    );
  }

  excelConfirmDates() {
    const { excelFromDate, excelToDate, excelLoading } = this.state;
    if (excelFromDate !== '' && excelToDate === '') {
      this.setState(
        {
          errMessage: 'Please fill To date',
          isLoading: false,
          excelChecksAlert: true,
        },
        this.showNotification('errSnack')
      );
    } else if (excelFromDate === '' && excelToDate !== '') {
      this.setState(
        {
          errMessage: 'Please fill From date',
          isLoading: false,
          excelChecksAlert: true,
        },
        this.showNotification('errSnack')
      );
    } else if (excelFromDate === '' && excelToDate === '') {
      this.setState(
        {
          errMessage: 'Please fill From and To date',
          isLoading: false,
          excelChecksAlert: true,
        },
        this.showNotification('errSnack')
      );
    } else if (!excelLoading) {
      this.setState(
        {
          // isFilter: true,
          isDownload: true,
          excelChecksAlert: true,
          excelchecksError: '',
        },
        () => this.downloadExcel('click')
      );
    }
  }

  downloadExcel(value) {
    const { excelFromDate, excelToDate } = this.state;
    let startDate = '';
    let endDate = '';
    const headers = {
      'Transaction Serial': 'string',
      Transporter: 'string',
      Trucker: 'string',
      TruckNumber: 'string',
      From: 'string',
      To: 'string',
      Freight: 'currency',
      Commission: 'currency',
      Date: 'string',
      POD: 'string',
      'Payment Date': 'string',
      'Payment Amount': 'currency',
      'Payment Remark': 'string',
      'Received Date': 'string',
      'Received Amount': 'currency',
      'Received Remark': 'string',
    };
    this.setState({
      excelLoading: true,
    });
    if (excelFromDate === '') {
      startDate = null;
    } else {
      startDate = new Date(excelFromDate);
      startDate = startDate.setHours(0, 0, 0, 0);
      startDate = new Date(startDate);
    }
    if (excelToDate === '') {
      endDate = null;
    } else {
      endDate = new Date(excelToDate);
      endDate = endDate.setHours(24, 0, 0, 0);
      endDate = new Date(endDate);
    }
    const { userDetails } = this.props;
    const { uid } = userDetails;
    const mainRef = db.collection('Users').doc(uid);
    const body = {};
    const { companyName, companyId } = userDetails;
    let fileName = `LA_${companyName}_${startDate.getTime()}_${endDate.getTime()}_${companyId}.xlsx`;
    fileName = fileName.replace(/ /g, '_');
    mainRef.get().then(userData => {
      if (userData.exists) {
        if (userData.data().companyId) {
          body.is_polling = false;
          const rows = [];
          const transactions = {};
          const transactionsRef = db
            .collection('Companies')
            .doc(userData.data().companyId)
            .collection('Transactions')
            .where('companyId', '==', userData.data().companyId)
            .where('createdAt', '>', startDate)
            .where('createdAt', '<', endDate)
            .orderBy('createdAt', 'desc');
          transactionsRef.get().then(snapshot => {
            const promises = [];
            snapshot.forEach(transactionDoc => {
              const {
                agentId,
                amount,
                bonus,
                commission,
                currentPayableAmount,
                currentReceivableAmount,
                createdAt,
                fromCity,
                toCity,
                transactionId,
                transactionSerial,
                transporterName,
                truckerName,
                vehicleNumber,
              } = transactionDoc.data();
              let { pod } = transactionDoc.data();
              if (pod === undefined) {
                pod = false;
              }
              const freight = amount;
              transactions[transactionId] = {
                agentId,
                freight,
                bonus,
                commission,
                companyId,
                currentPayableAmount,
                currentReceivableAmount,
                createdAt,
                fromCity,
                toCity,
                transactionId,
                transactionSerial,
                transporterName,
                truckerName,
                vehicleNumber,
                pod,
              };
              const payableRef = db
                .collection('Companies')
                .doc(userData.data().companyId)
                .collection('Transactions')
                .doc(transactionId)
                .collection('Payable');
              const receivableRef = db
                .collection('Companies')
                .doc(userData.data().companyId)
                .collection('Transactions')
                .doc(transactionId)
                .collection('Receivable');
              promises.push(payableRef.get());
              promises.push(receivableRef.get());
            });
            Promise.all(promises).then(response => {
              response.forEach(result => {
                result.forEach(endData => {
                  let path = '';
                  path = endData.ref.path;
                  const data = endData.data();
                  const { transactionId } = data;
                  const transactionData = transactions[transactionId];
                  const {
                    transporterName,
                    truckerName,
                    fromCity,
                    toCity,
                    freight,
                    date,
                    pod,
                    vehicleNumber,
                    commission,
                    transactionSerial,
                  } = transactionData;
                  const newDate = moment().format('MMM Do YY', date);
                  let payment_date = '';
                  let payment_amount = '';
                  let received_amount = '';
                  let received_date = '';
                  let payment_remark = '';
                  let received_remark = '';
                  if (path.includes('Receivable')) {
                    received_date = moment().format(
                      'MMM Do YY',
                      data.createdAt
                    );
                    received_amount = Number(data.amount);
                    received_remark = data.message;
                  } else {
                    payment_date = moment().format('MMM Do YY', data.createdAt);
                    payment_amount = Number(data.amount);
                    payment_remark = data.message;
                  }
                  if (!data.void) {
                    let podString = 'No';
                    if (pod) {
                      podString = 'Yes';
                    }
                    const row = {
                      transaction_serial: transactionSerial,
                      transporter: transporterName,
                      trucker: truckerName,
                      truck_number: vehicleNumber,
                      from: fromCity,
                      to: toCity,
                      freight: Number(freight),
                      commission: Number(commission),
                      date: newDate,
                      pod: podString,
                      payment_date,
                      payment_amount,
                      payment_remark,
                      received_date,
                      received_amount,
                      received_remark,
                    };
                    rows.push(row);
                  }
                });
              });
              body.data = {
                headers,
                rows,
              };
              body.filename = fileName;
              body.format = 'xlsx';
              exportExcel(body)
                .then(res => {
                  const apiRes = res.data;
                  if (apiRes.found === false) {
                    this.setState({
                      excelChecksAlert: true,
                      isDownload: true,
                    });
                    this.resendRequest(body);
                  } else {
                    if (apiRes.found === true) {
                      if (apiRes.hasOwnProperty('url')) {
                        var a = document.createElement('A');
                        a.href = apiRes.url;
                        a.download = apiRes.url.substr(
                          apiRes.url.lastIndexOf('/') + 1
                        );

                        // window.location.href = apiRes.url;
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        // window.location.href = apiRes.url;
                      }
                    }
                    this.setState({
                      progressValue: 100,
                      progressBuffer: 100,
                    });
                    // window.location.href = response.url;
                    setTimeout(() => {
                      this.setState({
                        excelChecksAlert: false,
                        isDownload: false,
                        excelFromDate: '',
                        exceltoDate: '',
                        excelLoading: false,
                        progressValue: 10,
                        progressBuffer: 15,
                      });
                    }, 1000);
                  }
                })
                .catch(err => {
                  console.log('Error', err);
                  this.setState(
                    {
                      errMessage: err.message || 'Oops Something went wrong',
                      isLoading: false,
                      excelLoading: false,
                      progressValue: 0,
                    },
                    this.showNotification('errSnack')
                  );
                });
            });
          });
        }
      }
    });
  }

  resendRequest(body) {
    const { progressValue, progressBuffer } = this.state;
    const adding = 2;
    setTimeout(() => {
      this.setState({
        progressValue: Number(progressValue + adding),
        progressBuffer: Number(progressBuffer + adding),
      });
      this.resendExcel(body);
    }, 2000);
  }

  resendExcel(body) {
    body.is_polling = true;
    exportExcel(body)
      .then(res => {
        const apiRes = res.data;
        if (apiRes.found === false) {
          this.setState({
            excelChecksAlert: true,
            isDownload: true,
          });
          this.resendRequest(body);
        } else {
          this.setState({
            progressValue: 100,
            progressBuffer: 100,
          });
          if (apiRes.found) {
            if (apiRes.hasOwnProperty('url')) {
              var a = document.createElement('A');
              a.href = apiRes.url;
              a.download = apiRes.url.substr(apiRes.url.lastIndexOf('/') + 1);
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }
          }
          setTimeout(() => {
            this.setState({
              excelChecksAlert: false,
              isDownload: false,
              excelFromDate: '',
              excelToDate: '',
              excelLoading: false,
              progressValue: 0,
              progressBuffer: 15,
            });
          }, 1000);
        }
      })
      .catch(err => {
        console.log('Error', err);
        this.setState(
          {
            errMessage: err.message || 'Oops Something went wrong',
            isLoading: false,
            excelLoading: false,
            progressValue: 0,
            // excelChecksAlert: false,
          },
          this.showNotification('errSnack')
        );
      });
  }

  showNotification(place) {
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 6000);
    }
  }

  /**
   * Submits the transporter details to the Database
   * @function
   */
  getUserCompanyName() {
    const _me = this;
    firebase.auth().onAuthStateChanged(user => {
      const userRef = db.collection(`Users`).doc(`${user.uid}`);
      userRef.get().then(
        resultData => {
          _me.setState({
            companyName: resultData.data().companyName,
            companyId: resultData.data().companyId,
          });
          // Get All the Transporters And Truckers

          this.getTruckers(resultData.data().companyId);

          this.getTransporters(resultData.data().companyId);
        },
        err => {
          console.log('err', err);
        }
      );
    });
  }

  /**
   * will return All the Truckers Available for User
   * @function
   * @param {String} companyId
   */
  getTruckers(companyId) {
    const TruckerRef = db
      .collection(`Companies`)
      .doc(`${companyId}`)
      .collection('Truckers')
      .orderBy('name', 'asc');
    TruckerRef.get()
      .then(
        resultData => {
          const truckers = [];
          const truckersCopy = [];

          resultData.forEach(element => {
            const data = element.data();
            const dataCopy = element.data();
            data.label = element.data().name;
            data.value = element.data().truckerId;
            dataCopy.label = element.data().phoneNumber;
            dataCopy.value = element.data().truckerId;
            truckers.push(data);
            truckersCopy.push(dataCopy);
          });
          this.setState({
            truckers,
            truckersCopy,
          });
        },
        err => {
          console.log('err', err);
        }
      )
      .catch(err => {
        console.log('err', err);
      });
  }

  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  /**
   * will return All the Transporters Available for User
   * @function
   * @param {String} companyId
   */
  getTransporters(companyId) {
    const TransporterRef = db
      .collection(`Companies`)
      .doc(`${companyId}`)
      .collection('Transporters')
      .orderBy('name', 'asc');
    TransporterRef.get()
      .then(
        resultData => {
          const transporters = [];
          resultData.forEach(element => {
            const data = element.data();
            data.label = element.data().name;
            data.value = element.data().transporterId;
            transporters.push(data);
          });
          this.setState({ transporters });
        },
        err => {
          console.log('err', err);
        }
      )
      .catch(err => {
        console.log('err', err);
      });
  }

  handleTransporter = selectedTransporter => {
    this.setState({
      selectedTransporter,
      transporterName: selectedTransporter.name,
      transporterId: selectedTransporter.transporterId,
    });
  };

  handleTrucker = (selectedTrucker, label) => {
    const deepClone = JSON.parse(JSON.stringify(selectedTrucker));
    if (label === 'name') {
      deepClone.label = selectedTrucker.phoneNumber;
      this.setState({
        selectedTrucker,
        selectedTruckerCopy: deepClone,
        truckerName: selectedTrucker.name,
        truckerId: selectedTrucker.truckerId,
      });
    } else {
      deepClone.label = selectedTrucker.name;
      this.setState({
        selectedTrucker: deepClone,
        selectedTruckerCopy: selectedTrucker,
        truckerName: selectedTrucker.name,
        truckerId: selectedTrucker.truckerId,
      });
    }
  };

  fromDateChange(fromDate) {
    this.setState({ fromDate });
  }

  toDateChange(toDate) {
    this.setState({ toDate });
  }

  showChecksAlert() {
    this.setState({
      checksAlert: true,
      checksError: '',
    });
  }

  closeChecksAlert() {
    this.setState({
      checksAlert: false,
      checksError: '',
    });
  }

  resetFilter() {
    this.setState(
      {
        checksAlert: false,
        checksError: '',
        isFilter: false,
        fromDate: '',
        toDate: '',
        selectedTransporter: '',
        selectedTrucker: '',
        selectedTruckerCopy: '',
        isPod: false,
        truckerId: '',
        transporterId: '',
        truckNumber: '',
      },
      () => this.getTransactions()
    );
  }

  confirmDates() {
    const { fromDate, toDate } = this.state;
    if (fromDate !== '' && toDate === '') {
      this.showNotification('tc');
    } else if (fromDate === '' && toDate !== '') {
      this.showNotification('tc');
    } else {
      this.setState(
        {
          isFilter: true,
          checksAlert: false,
          checksError: '',
          transactionId: '',
        },
        () => this.getTransactions()
      );
    }
  }

  searchBetweenDates(status) {
    const { fromDate, toDate } = this.state;
    const startDate = new Date(fromDate);
    const endDate = new Date(toDate);
    let transactionsRef;
    const { userDetails } = this.props;
    let { selectedBranch } = this.state;
    if (!selectedBranch) selectedBranch = userDetails.branches[0];
    // If user is an admin, show them all transactions.

    if (userDetails.isAdmin) {
      transactionsRef = db
        .collection(`Transactions`)
        .where('status', '==', status)
        .where('createdAt', '>', startDate)
        .where('createdAt', '<', endDate)
        .orderBy('createdAt', 'desc');
    }
    //  If user is just ops, show them only their transactions.
    else {
      transactionsRef = db
        .collection(`Transactions`)
        .where('status', '==', status)
        .where('branch', '==', selectedBranch)
        // .where('agentId', '==', userDetails.uid)
        .where('createdAt', '>', startDate)
        .where('created', '<', endDate)
        .orderBy('createdAt', 'desc');

      // If user is not a sales manager, display only their created transactions
      if (!userDetails.isSalesManager) {
        transactionsRef = transactionsRef.where(
          'agentId',
          '==',
          userDetails.uid
        );
      }
    }

    if (status === 'approved')
      transactionsRef = transactionsRef.where(
        'isEditedTransaction',
        '==',
        false
      );

    this.setState({ [`${status}PrevActive`]: false });

    const unsubscribePage = transactionsRef.onSnapshot(transactionsData => {
      const transactions = [];
      transactionsData.forEach(transaction => {
        const formattedTransaction = {
          ...transaction.data(),
        };
        transactions.push(formattedTransaction);
      });
      this.setState({
        [`${status}Transactions`]: transactions,
      });
      this.resetFilter();
    });
    if (status === 'pending') this.unsubscribePendingPage = unsubscribePage;
    else if (status === 'approved')
      this.unsubscribeApprovedPage = unsubscribePage;
    else if (status === 'rejected')
      this.unsubscribeRejectedPage = unsubscribePage;
    else if (status === 'modify') this.unsubscribeModifyPage = unsubscribePage;
  }

  openTour() {
    const agentTour = {
      id: 'hello-hopscotch',
      showPrevButton: true,
      onEnd: this.endTour,
      onClose: this.endTour,
      showCloseButton: true,
      i18n: { closeTooltip: 'Skip Tour' },
      steps: [
        {
          title: 'Select a Transaction',
          content: '',
          target: 'select-transaction',
          placement: 'top',
          showNextButton: true,
          nextOnTargetClick: true,
        },
        {
          title: 'Add Payable Entry',
          content: '',
          target: 'add-payable-entry',
          placement: 'top',
          showNextButton: false,
          // delay:200,
          nextOnTargetClick: true,
        },
        {
          title: 'Enter Amount',
          content: '',
          target: 'payable-amount',
          placement: 'bottom',
          showNextButton: true,
          delay: 200,
          nextOnTargetClick: true,
        },
        {
          title: 'Enter Reference Message',
          content: '',
          target: 'payable-message',
          placement: 'bottom',
          showNextButton: true,
          nextOnTargetClick: true,
        },
        {
          title: 'Select Date',
          content: '',
          target: 'payable-date',
          placement: 'top',
          showNextButton: true,
          nextOnTargetClick: false,
        },
        {
          title: 'Submit Payable Entry',
          content: '',
          target: 'submit-payable',
          placement: 'bottom',
          showNextButton: true,
        },
        {
          title: 'Add Receivable Entry',
          content: 'Select ATH or BTH',
          target: 'receivable-history',
          placement: 'left',
          showNextButton: true,
        },
        {
          title: 'Add Receivable Entry',
          content: '',
          target: 'add-receivable-entry',
          placement: 'top',
          showNextButton: false,
          // delay:200,
          nextOnTargetClick: true,
        },
        {
          title: 'Enter Amount',
          content: '',
          target: 'receivable-amount',
          placement: 'bottom',
          showNextButton: true,
          delay: 200,
          nextOnTargetClick: true,
        },
        {
          title: 'Enter Reference Message',
          content: '',
          target: 'receivable-message',
          placement: 'bottom',
          showNextButton: true,
          nextOnTargetClick: true,
        },
        {
          title: 'Select Date',
          content: '',
          target: 'receivable-date',
          placement: 'top',
          showNextButton: true,
          nextOnTargetClick: false,
        },
        {
          title: 'Submit Payable Entry',
          content: '',
          target: 'submit-receivable',
          placement: 'bottom',
          showNextButton: true,
        },
      ],
    };

    if (this.props.userDetails.onBoard.transactionEdit) {
      if (this.props.userDetails.isCompanyAdmin) {
        const adminTour = { ...agentTour };
        adminTour.steps.push({
          title: "Let's Continue",
          content: '',
          target: 'logoText',
          placement: 'right',
          showNextButton: false,
          showCTAButton: true,
          ctaLabel: 'Add User',
          onCTA: this.goToAddUser,
        });

        window.hopscotch.startTour(adminTour);
      } else window.hopscotch.startTour(agentTour);
    }
  }

  goToAddUser() {
    window.hopscotch.endTour([]);
    history.push('/agent/users/add');
  }

  endTour() {
    window.hopscotch.endTour([]);
    const user = firebase.auth().currentUser;
    if (user) {
      db.collection('Users')
        .doc(user.uid)
        .update({ 'onBoard.transactionEdit': false });
    }
  }

  componentWillUnmount() {
    window.hopscotch.endTour([]);
  }

  /**
   * will redirect to Add Transactions Page
   * @function
   */
  addTransactions() {
    this.props.history.push('/agent/transactions/add');
  }

  modalWindow() {
    const { classes } = this.props;
    const {
      fromDate,
      toDate,
      selectedTransporter,
      transporters,
      truckers,
      truckersCopy,
      selectedTrucker,
      selectedTruckerCopy,
    } = this.state;

    return (
      <SweetAlert
        showCancel
        confirmBtnText="Apply"
        title=""
        cancelBtnText="Cancel"
        onConfirm={this.confirmDates}
        onCancel={this.closeChecksAlert}
        confirmBtnCssClass={`${classes.button} ${classes.success}`}
        cancelBtnCssClass={`${classes.button} ${classes.warning}`}
        style={{ height: '70%', width: '580px' }}
      >
        <h3 style={{ marginBottom: '0px' }}>
          <span className={classes.rajdhaniFont}>Filter Transactions</span>
        </h3>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: '20px' }}>
            <InputLabel className={classes.label}>From Date</InputLabel>
            <br />
            <FormControl fullWidth>
              <Datetime
                onChange={this.fromDateChange}
                value={fromDate}
                dateFormat="DD-MM-YYYY"
                timeFormat={false}
                inputProps={{ placeholder: 'Select Date' }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: '20px' }}>
            <InputLabel className={classes.label}>To Date</InputLabel>
            <br />
            <FormControl fullWidth>
              <Datetime
                onChange={this.toDateChange}
                value={toDate}
                dateFormat="DD-MM-YYYY"
                timeFormat={false}
                inputProps={{ placeholder: 'Select Date' }}
              />
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: '20px' }}>
            <InputLabel className={classes.labelText}>
              Select Trucker Name
            </InputLabel>
            <ReactSelect
              value={selectedTrucker}
              onChange={value => this.handleTrucker(value, 'name')}
              options={truckers}
              placeholder="Select Trucker"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: '20px' }}>
            <CustomInput
              labelText="Truck Number"
              id="truck number"
              formControlProps={{ fullWidth: true }}
              inputProps={{
                onChange: event =>
                  this.change(event, 'truckNumber', 'length', 2),
                type: 'length',
                value: this.state.truckNumber,
              }}
              success={this.state.truckNumberState === 'success'}
              error={this.state.truckNumberState === 'error'}
            />
            {/* <InputLabel className={classes.labelText}>
              Select Trucker Phone
            </InputLabel>
            <ReactSelect
              value={selectedTruckerCopy}
              onChange={value => this.handleTrucker(value, 'phone')}
              options={truckersCopy}
              placeholder="Select Trucker"
            /> */}
          </GridItem>
          <GridItem xs={12} sm={12} md={6} style={{ marginTop: '20px' }}>
            <InputLabel className={classes.labelText}>
              Select Transporter
            </InputLabel>
            <ReactSelect
              value={selectedTransporter}
              onChange={this.handleTransporter}
              options={transporters}
              placeholder="Select Transporter"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={2} style={{ marginTop: '40px' }}>
            <FormControlLabel
              classes={{
                root: classes.checkboxLabelControl,
                label: classes.checkboxLabel,
              }}
              control={
                <Checkbox
                  onChange={() => this.handleToggle(!this.state.isPod)}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  checked={this.state.isPod}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{ checked: classes.checked }}
                />
              }
              label={<span>POD</span>}
            />
          </GridItem>
        </GridContainer>
        <Snackbar
          place="tc"
          color="danger"
          icon={AddAlert}
          message="Select From and To date."
          open={this.state.tc}
          closeNotification={() => this.setState({ tc: false })}
          close
        />
        {this.state.isFilter && (
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6} textCenter>
              <Button
                color="danger"
                aria-label="edit"
                size="sm"
                style={{ float: 'center' }}
                // className={}
                onClick={() => this.resetFilter()}
              >
                Reset
              </Button>
            </GridItem>
          </GridContainer>
        )}
      </SweetAlert>
    );
  }

  render() {
    const { classes } = this.props;
    const {
      isFilter,
      checksAlert,
      excelChecksAlert,
      infoSnack,
      infoMessage,
      errMessage,
      errSnack,
    } = this.state;
    const searchButton = `${classes.top} ${classes.searchButton} ${classNames({
      [classes.searchRTL]: '',
    })}`;
    return (
      <div>
        {checksAlert && this.modalWindow()}
        <GridContainer justify="center">
          <Snackbar
            place="tc"
            color="success"
            icon={AddAlert}
            message={infoMessage || ''}
            open={infoSnack}
            closeNotification={() => this.setState({ infoSnack: false })}
            close
          />
          <Snackbar
            place="tc"
            color="danger"
            icon={AddAlert}
            message={errMessage || ''}
            open={errSnack}
            closeNotification={() => this.setState({ errSnack: false })}
            close
          />
          {excelChecksAlert && this.excelModalWindow()}
          {this.props.isListPage && (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <Card>
                <CardHeader
                  id="select-transaction"
                  className={`${classes.cardHeader}`}
                  color="info"
                >
                  <GridContainer>
                    <GridItem xs={12} md={10} lg={11}>
                      <p
                        className={`${classes.cardHeader} ${classes.rajdhaniFont}`}
                      >
                        Transactions
                      </p>
                      <h3
                        className={`${classes.cardTitle} ${classes.rajdhaniFont}`}
                      >
                        {this.props.transactions.length} <small />
                      </h3>
                    </GridItem>

                    <GridItem xs={12} md={2} lg={1}>
                      <Button
                        color="white"
                        aria-label="edit"
                        justIcon
                        round
                        className={searchButton}
                        onClick={() => this.excelShowChecksAlert()}
                        style={{
                          marginTop: '15px',
                          float: 'right',
                          top: '0px',
                          marginRight: '15px',
                        }}
                      >
                        <i
                          className="fa fa-arrow-down"
                          aria-hidden="true"
                          style={{ color: 'green' }}
                        />
                      </Button>
                      <Button
                        color={isFilter ? 'success' : 'white'}
                        aria-label="edit"
                        justIcon
                        round
                        className={searchButton}
                        onClick={() => this.showChecksAlert()}
                        style={{ marginTop: '16px' }}
                      >
                        <i className="fa fa-filter" aria-hidden="true" />
                      </Button>
                      {/* <h3
                        className={`${classes.cardTitle} ${classes.rajdhaniFont}`}
                      >
                        {this.props.transactions.length} <small />
                      </h3> */}
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                >
                  {this.props.transactions.length <= 0 ? (
                    <div className={classes.textCenter}>
                      <p className={`${classes.rajdhaniFont}`}>
                        No Transactions Available
                      </p>
                      <Button round color="info" onClick={this.addTransactions}>
                        Add Transactions
                      </Button>
                    </div>
                  ) : (
                    <TransactionsCard transactions={this.state.transactions} />
                  )}
                </CardBody>
                <CardFooter stats>
                  <div className={classes.stats} />
                </CardFooter>
              </Card>
            </GridItem>
          )}
          {this.props.isDetailedPage && (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <DetailedTransactions />
            </GridItem>
          )}
          {this.props.isEditPage && (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <EditTransaction />
            </GridItem>
          )}
        </GridContainer>
      </div>
    );
  }
}

Transactions.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isDetailedPage: state.transactions.isDetailedPage,
  isListPage: state.transactions.isListPage,
  isEditPage: state.transactions.isEditPage,
  transactions: state.transactions.transactions,
  userDetails: state.main.userDetails,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchActivePage,
      setTransactions,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(TransactionStyle)(Transactions));
