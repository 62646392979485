const popoverStyles = {
    popover: {
        padding: "0",
        boxShadow:
            "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
        lineHeight: "1.5em",
        background: "#fff",
        border: "none",
        borderRadius: "3px",
        display: "block",
        maxWidth: "276px",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontStyle: "normal",
        fontWeight: "400",
        textAlign: "start",
        textDecoration: "none",
        textShadow: "none",
        textTransform: "none",
        letterSpacing: "normal",
        wordBreak: "normal",
        wordSpacing: "normal",
        whiteSpace: "normal",
        lineBreak: "auto",
        fontSize: "0.875rem",
        wordWrap: "break-word"
    },
    popoverBottom: {
        marginTop: "5px"
    },
    popoverHeader: {
        backgroundColor: "#fff",
        border: "none",
        padding: "15px 15px 5px",
        fontSize: "1.125rem",
        margin: "0",
        color: "#555",
        borderTopLeftRadius: "calc(0.3rem - 1px)",
        borderTopRightRadius: "calc(0.3rem - 1px)"
    },
    popoverBody: {
        padding: "10px 15px 15px",
        lineHeight: "1.4",
        color: "#555"
    }
};

export default popoverStyles;
