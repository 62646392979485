export const CHECK_ACTIVE_PAGE = 'transactions/CHECK_ACTIVE_PAGE';

export const SET_CURRENT_TRANSACTION = 'transactions/SET_CURRENT_TRANSACTION';
export const SET_TRANSACTIONS = 'transactions/SET_TRANSACTIONS';
export const SET_PAYABLE_TRANSACTIONS = 'transactions/SET_PAYABLE_TRANSACTIONS';
export const SET_ATH_TRANSACTIONS = 'transactions/SET_ATH_TRANSACTIONS';
export const SET_BTH_TRANSACTIONS = 'transactions/SET_BTH_TRANSACTIONS';
export const SET_PAYABLE_PENDING_TRANSACTIONS =
  'transactions/SET_PAYABLE_PENDING_TRANSACTIONS';
export const SET_RECEIVABLE_PENDING_TRANSACTIONS =
  'transactions/SET_RECEIVABLE_PENDING_TRANSACTIONS';

const initialState = {
  currentTransactionData: {},
  currentPayableAmount: '',
  currentReceivableAmount: '',
  transactions: [],
  payableTransactions: [],
  athTransactions: [],
  bthTransactions: [],
  payablePendingTransactions: {},
  receivablePendingTransactions: {},
  pod: false,
  isListPage: true,
  isDetailedPage: false,
  isEditPage: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CHECK_ACTIVE_PAGE:
      return {
        ...state,
        isDetailedPage: action.isDetailedPage,
        isListPage: action.isListPage,
        isEditPage: action.isEditPage,
      };
    case SET_CURRENT_TRANSACTION:
      return {
        ...state,
        currentTransactionData: action.currentTransactionData,
        currentPayableAmount: action.currentPayableAmount,
        currentReceivableAmount: action.currentReceivableAmount,
        pod: action.pod,
      };
    case SET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.transactions,
      };
    case SET_PAYABLE_TRANSACTIONS:
      return {
        ...state,
        payableTransactions: action.payableTransactions,
      };
    case SET_ATH_TRANSACTIONS:
      return {
        ...state,
        athTransactions: action.athTransactions,
      };
    case SET_BTH_TRANSACTIONS:
      return {
        ...state,
        bthTransactions: action.bthTransactions,
      };
    case SET_PAYABLE_PENDING_TRANSACTIONS:
      return {
        ...state,
        payablePendingTransactions: action.payablePendingTransactions,
      };
    case SET_RECEIVABLE_PENDING_TRANSACTIONS:
      return {
        ...state,
        receivablePendingTransactions: action.receivablePendingTransactions,
      };
    default:
      return state;
  }
};

export const switchActivePage = activePages => dispatch => {
  dispatch({
    type: CHECK_ACTIVE_PAGE,
    isDetailedPage: activePages.isDetailedPage,
    isListPage: activePages.isListPage,
    isEditPage: activePages.isEditPage,
  });
};

export const setCurrentTransaction = (
  transactionData,
  currentPayableAmount,
  currentReceivableAmount,
  pod
) => dispatch => {
  dispatch({
    type: SET_CURRENT_TRANSACTION,
    currentTransactionData: transactionData,
    currentPayableAmount,
    currentReceivableAmount,
    pod,
  });
};

export const setTransactions = transactions => dispatch => {
  dispatch({
    type: SET_TRANSACTIONS,
    transactions,
  });
};

export const setPayableTransactions = payableTransactions => dispatch => {
  dispatch({
    type: SET_PAYABLE_TRANSACTIONS,
    payableTransactions,
  });
};

export const setAthTransactions = athTransactions => dispatch => {
  dispatch({
    type: SET_ATH_TRANSACTIONS,
    athTransactions,
  });
};

export const setBthTransactions = bthTransactions => dispatch => {
  dispatch({
    type: SET_BTH_TRANSACTIONS,
    bthTransactions,
  });
};

export const setPayablePendingTransactions = payablePendingTransactions => dispatch => {
  dispatch({
    type: SET_PAYABLE_PENDING_TRANSACTIONS,
    payablePendingTransactions,
  });
};

export const setReceivablePendingTransactions = receivablePendingTransactions => dispatch => {
  dispatch({
    type: SET_RECEIVABLE_PENDING_TRANSACTIONS,
    receivablePendingTransactions,
  });
};
