/* eslint-disable react/sort-comp */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/order */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/Icons
import ArrowBack from '@material-ui/icons/ArrowBack';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import AddAlert from '@material-ui/icons/AddAlert';
import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';

import {
  switchActivePage,
  setCurrentTransaction,
} from '../../../reducers/transactions';
import PayableHistory from './PayableHistory';
import firebase from '../../../config/config';
import ReceivableHistory from './ReceivableHistory';
import history from '../../../history';

const moment = require('moment');

const db = firebase.firestore();

class DetailedTransactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      checked: false,
      isDetailedForm: false,
      truckerName: '',
      transporterName: '',
      vehicleNumber: '',
      fromCity: '',
      toCity: '',
      date: '',
      tc: false,
      message: '',
      amount: '',
      commission: '',
      bonus: '',
      pod: false,
      createdAt: null,
      transactionSerial: '',
      transactionId: '',
      currentTransactionData: {},
      payableTransactions: [
        { color: 'info', data: ['28-09-2019', '10,000', 'message is'] },
      ],
      txnExistsError: false,
    };
    this.hideDetailedForm = this.hideDetailedForm.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  componentDidMount() {
    const transactionId = this.props.match.params.txnId;
    firebase.auth().onAuthStateChanged(user => {
      const mainRef = db.collection('Users').doc(user.uid);
      mainRef.get().then(userData => {
        if (userData.data().companyId) {
          const transactionsRef = db
            .collection('Companies')
            .doc(userData.data().companyId)
            .collection(`Transactions`)
            .doc(transactionId);
          transactionsRef.onSnapshot(transactionSnap => {
            if (transactionSnap.exists) {
              const transactionData = transactionSnap.data();
              const {
                amount,
                bonus,
                commission,
                companyId,
                currentPayableAmount,
                currentReceivableAmount,
                fromCity,
                toCity,
                createdAt,
                pod,
                transporterName,
                truckerName,
                truckType,
                vehicleNumber,
                transactionSerial,
              } = transactionData;
              this.setState({
                amount,
                bonus,
                commission,
                companyId,
                currentPayableAmount,
                currentReceivableAmount,
                fromCity,
                toCity,
                createdAt,
                pod,
                transporterName,
                truckerName,
                truckType,
                vehicleNumber,
                transactionSerial,
                currentTransactionData: transactionData,
                transactionId,
              });
              this.props.setCurrentTransaction(
                transactionData,
                currentPayableAmount,
                currentReceivableAmount,
                pod
              );
            } else {
              this.setState({
                txnExistsError: true,
              });
            }
          });
        }
      });
    });
  }

  // WIll Hide the Detailed Transactions And returns to Transactions Main Page
  hideDetailedForm() {
    // this.props.switchActivePage({
    //   isListPage: true,
    //   isDetailedPage: false,
    //   isEditPage: false,
    // });
    history.push('/agent/transactions/view');
  }

  handleToggle(value, transactionId, transaction, pod) {
    const currentTransaction = transaction;
    if (currentTransaction.pod) {
      currentTransaction.pod = false;
    } else {
      currentTransaction.pod = true;
    }
    // this.props.setCurrentTransaction(
    //   currentTransaction,
    //   currentTransaction.currentPayableAmount,
    //   currentTransaction.currentReceivableAmount,
    //   !pod
    // );
    db.collection('Companies')
      .doc(currentTransaction.companyId)
      .collection('Transactions')
      .doc(transactionId)
      .update({
        pod: value,
      })
      .then(() => {
        const NotificationMessage = 'POD Updated';
        this.showNotification('tc', NotificationMessage);
      });
  }

  /**
   * will return the notification snackbar
   * @param {string} place
   */
  showNotification(place, name) {
    this.setState({
      message: name,
    });
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 2000);
    }
  }

  // Will Return the Detailed Transaction Form
  showDetailedTransactions() {
    const {
      amount,
      bonus,
      commission,
      fromCity,
      toCity,
      createdAt,
      pod,
      transporterName,
      truckerName,
      vehicleNumber,
      transactionSerial,
      transactionId,
      currentTransactionData,
    } = this.state;
    const { classes } = this.props;
    let checked = false;
    if (pod) {
      checked = pod;
    }

    return (
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <Card>
          <CardHeader className={`${classes.cardHeader}`} color="info">
            <CardIcon
              color="primary"
              className={`${classes.cardHeader}`}
              onClick={() => this.hideDetailedForm()}
            >
              <ArrowBack />
            </CardIcon>
            <h3 className={`${classes.cardTitle}`}>
              <span className={classes.rajdhaniFont}>#{transactionSerial}</span>
            </h3>
            <p className={`${classes.cardHeader}`}>
              <span className={classes.rajdhaniFont}>
                {createdAt !== null
                  ? moment(createdAt.toDate()).format('MMM Do YY')
                  : ''}
              </span>
            </p>
          </CardHeader>
          <CardBody className={`${classes.cardHeader} ${classes.textCenter}`}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                  className={`${classes.rajdhaniFont}`}
                  labelText="Transporter "
                  id="transporter"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.rajdhaniFont,
                  }}
                  inputProps={{
                    value: transporterName,
                    className: classes.rajdhaniFont,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                  labelText="Trucker"
                  id="trucker"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    value: truckerName,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                  labelText="Vehicle Number"
                  id="trucker"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    value: vehicleNumber,
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                  labelText="Date"
                  id="trucker"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    value:
                      createdAt !== null
                        ? moment(createdAt.toDate()).format('MMM Do YY')
                        : '',
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                  labelText="From"
                  id="from"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    value: fromCity,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                  labelText="To"
                  id="to"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    value: toCity,
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                  className={`${classes.rajdhaniFont}`}
                  labelText="Freight Value"
                  id="fright"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.rajdhaniFont,
                  }}
                  inputProps={{
                    value: Intl.NumberFormat('en-IN').format(amount),
                    className: classes.rajdhaniFont,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                  className={`${classes.rajdhaniFont}`}
                  labelText="Commission"
                  id="comission"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.rajdhaniFont,
                  }}
                  inputProps={{
                    value: Intl.NumberFormat('en-IN').format(commission),
                    className: classes.rajdhaniFont,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                  className={`${classes.rajdhaniFont}`}
                  labelText="Bonus"
                  id="bonus"
                  formControlProps={{
                    fullWidth: true,
                    className: classes.rajdhaniFont,
                  }}
                  inputProps={{
                    value: `${
                      bonus ? Intl.NumberFormat('en-IN').format(bonus) : 0
                    }`,
                    className: classes.rajdhaniFont,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4} style={{ marginTop: '25px' }}>
                <FormControlLabel
                  classes={{
                    root: classes.checkboxLabelControl,
                    label: classes.checkboxLabel,
                  }}
                  control={
                    <Checkbox
                      onChange={() =>
                        this.handleToggle(
                          !checked,
                          transactionId,
                          currentTransactionData,
                          pod
                        )
                      }
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      checked={checked}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{ checked: classes.checked }}
                    />
                  }
                  label={<span>POD</span>}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                {transactionId !== '' && (
                  <PayableHistory transactionData={currentTransactionData} />
                )}
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                {transactionId !== '' && (
                  <ReceivableHistory transactionData={currentTransactionData} />
                )}
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter stats>
            <div className={classes.stats} />
          </CardFooter>
        </Card>
        <Snackbar
          place="tc"
          color="info"
          icon={AddAlert}
          message={this.state.message}
          open={this.state.tc}
          closeNotification={() => this.setState({ tc: false })}
          close
        />
      </GridItem>
    );
  }

  render() {
    const { txnExistsError } = this.state;
    return (
      <div>
        <GridContainer justify="center">
          {txnExistsError ? (
            <GridItem xs={12} sm={12} md={12}>
              <p
                style={{
                  textAlign: 'center',
                  marginTop: '30px',
                  fontSize: '18px',
                }}
              >
                Oops, Something went wrong !
              </p>{' '}
            </GridItem>
          ) : (
            this.showDetailedTransactions()
          )}
        </GridContainer>
      </div>
    );
  }
}

DetailedTransactions.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isDetailedPage: state.transactions.isDetailedPage,
  currentTransactionData: state.transactions.currentTransactionData,
  pod: state.transactions.pod,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchActivePage,
      setCurrentTransaction,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(TransactionStyle)(DetailedTransactions));
