import Dashboard from 'views/Dashboard/Dashboard.jsx';

import Transactions from 'views/Transactions/Transactions.jsx';
import AddTransactions from 'views/Transactions/AddTransactions.jsx';

import Transporters from 'views/Transporters/Transporters.jsx';
import AddTransporters from 'views/Transporters/AddTransporters.jsx';

import PayablePending from 'views/Pending/PayablePending.jsx';
import ReceivablePending from 'views/Pending/ReceivablePending.jsx';

import Truckers from 'views/Truckers/Truckers.jsx';
import AddTruckers from 'views/Truckers/AddTruckers.jsx';
import AddCities from 'views/Cities/AddCities.jsx';
import Cities from 'views/Cities/Cities.jsx';

import Users from 'views/Users/Users.jsx';
import AddUsers from 'views/Users/AddUsers.jsx';
import GroupAdd from '@material-ui/icons/GroupAdd';

// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import SwapHoriz from '@material-ui/icons/SwapHoriz';
import LocalShipping from '@material-ui/icons/LocalShipping';
import TransferWithinAStation from '@material-ui/icons/TransferWithinAStation';
import LocationCity from '@material-ui/icons/LocationCity';
import AssignmentLate from '@material-ui/icons/AssignmentLate';

const dashRoutes = {
  agentRoutes: [
    {
      path: '/agent/dashboard',
      name: 'Dashboard',
      icon: DashboardIcon,
      component: Dashboard,
    },
    {
      collapse: true,
      path: '/agent/pending',
      name: 'Pending',
      icon: AssignmentLate,
      state: 'openPending',
      views: [
        {
          path: '/agent/pending/payable',
          name: 'Payable',
          mini: 'P',
          component: PayablePending,
        },
        {
          path: '/agent/pending/receivable',
          name: 'Receivable',
          mini: 'R',
          component: ReceivablePending,
        },
      ],
    },
    {
      collapse: true,
      path: '/agent/transactions',
      name: 'Transactions',
      icon: SwapHoriz,
      state: 'openTransactions',
      views: [
        {
          path: '/agent/transactions/view',
          name: 'View',
          mini: 'V',
          component: Transactions,
        },
        {
          path: '/agent/transactions/add',
          name: 'Add',
          mini: 'A',
          component: AddTransactions,
        },
      ],
    },
    {
      collapse: true,
      path: '/agent/transporters',
      name: 'Transporters',
      state: 'openTransporters',
      icon: TransferWithinAStation,
      views: [
        {
          path: '/agent/transporters/view',
          name: 'View',
          mini: 'V',
          component: Transporters,
        },
        {
          path: '/agent/transporters/add',
          name: 'Add',
          mini: 'A',
          component: AddTransporters,
        },
      ],
    },
    {
      collapse: true,
      path: '/agent/truckers',
      name: 'Truckers',
      icon: LocalShipping,
      state: 'openTruckers',
      views: [
        {
          path: '/agent/truckers/view',
          name: 'View',
          mini: 'V',
          component: Truckers,
        },
        {
          path: '/agent/truckers/add',
          name: 'Add',
          mini: 'A',
          component: AddTruckers,
        },
      ],
    },
    {
      collapse: true,
      path: '/agent/cities',
      name: 'Cities',
      icon: LocationCity,
      state: 'openCiies',
      views: [
        {
          path: '/agent/cities/view',
          name: 'View',
          mini: 'V',
          component: Cities,
        },
        {
          path: '/agent/cities/add',
          name: 'Add',
          mini: 'A',
          component: AddCities,
        },
      ],
    },
    {
      redirect: true,
      path: '/agent',
      pathTo: '/agent/dashboard',
      name: 'Dashboard',
    },
  ],
  adminRoutes: [
    {
      path: '/agent/dashboard',
      name: 'Dashboard',
      icon: DashboardIcon,
      component: Dashboard,
    },
    {
      collapse: true,
      path: '/agent/pending',
      name: 'Pending',
      icon: AssignmentLate,
      state: 'openPending',
      views: [
        {
          path: '/agent/pending/payable',
          name: 'Payable',
          mini: 'P',
          component: PayablePending,
        },
        {
          path: '/agent/pending/receivable',
          name: 'Receivable',
          mini: 'R',
          component: ReceivablePending,
        },
      ],
    },
    {
      collapse: true,
      path: '/agent/transactions',
      name: 'Transactions',
      icon: SwapHoriz,
      state: 'openTransactions',
      views: [
        {
          path: '/agent/transactions/view',
          name: 'View',
          mini: 'V',
          component: Transactions,
        },
        {
          path: '/agent/transactions/add',
          name: 'Add',
          mini: 'A',
          component: AddTransactions,
        },
      ],
    },
    {
      collapse: true,
      path: '/agent/transporters',
      name: 'Transporters',
      state: 'openTransporters',
      icon: TransferWithinAStation,
      views: [
        {
          path: '/agent/transporters/view',
          name: 'View',
          mini: 'V',
          component: Transporters,
        },
        {
          path: '/agent/transporters/add',
          name: 'Add',
          mini: 'A',
          component: AddTransporters,
        },
      ],
    },
    {
      collapse: true,
      path: '/agent/truckers',
      name: 'Truckers',
      icon: LocalShipping,
      state: 'openTruckers',
      views: [
        {
          path: '/agent/truckers/view',
          name: 'View',
          mini: 'V',
          component: Truckers,
        },
        {
          path: '/agent/truckers/add',
          name: 'Add',
          mini: 'A',
          component: AddTruckers,
        },
      ],
    },
    {
      collapse: true,
      path: '/agent/cities',
      name: 'Cities',
      icon: LocationCity,
      state: 'openCiies',
      views: [
        {
          path: '/agent/cities/view',
          name: 'View',
          mini: 'V',
          component: Cities,
        },
        {
          path: '/agent/cities/add',
          name: 'Add',
          mini: 'A',
          component: AddCities,
        },
      ],
    },
    {
      collapse: true,
      path: '/agent/users',
      name: 'Users',
      icon: GroupAdd,
      state: 'openUsers',
      views: [
        {
          path: '/agent/users/view',
          name: 'View',
          mini: 'V',
          component: Users,
        },
        {
          path: '/agent/users/add',
          name: 'Add',
          mini: 'A',
          component: AddUsers,
        },
      ],
    },
    {
      redirect: true,
      path: '/agent',
      pathTo: '/agent/dashboard',
      name: 'Dashboard',
    },
  ],
};

export default dashRoutes;
