/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react/sort-comp */
/* eslint-disable guard-for-in */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
import React from 'react';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import PropTypes from 'prop-types';

// @material-ui/icons
// import TransferWithinAStation from "@material-ui/icons/TransferWithinAStation";
import ArrowBack from '@material-ui/icons/ArrowBack';
import Add from '@material-ui/icons/Add';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import Table from 'components/Table/Table.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AddTruckersStyle from 'assets/jss/material-dashboard-pro-react/views/AddTruckersStyle.jsx';
import { switchTruckerActivePage } from '../../../reducers/truckers';

// Fireabse
import firebase from '../../../config/config';

const db = firebase.firestore();
function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class EditTrucker extends React.Component {
  anchorElLeft = null;

  anchorElTop = null;

  anchorElBottom = null;

  anchorElRight = null;

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      nameState: '',
      email: '',
      emailState: '',
      contactPerson: '',
      phoneNumber: '',
      phoneNumberState: '',
      creditLimit: '',
      creditLimitState: '',
      gstNumber: '',
      gstNumberState: '',
      panNumber: '',
      panNumberState: '',
      truckType: '',
      truckNumber: '',
      truckNumberState: '',
      companyName: '',
      isLoading: false,
      alert: null,
      companyId: '',
      truckTypes: [
        '12T- Open',
        '20FT Container',
        '22FT Closed Container',
        '22FT Jcb Carrier',
        '24FT Closed Container',
        '24FT Jcb Carrier',
        '32FT MultiAxle Container',
        '32FT Single Axle Container',
        '34FT Multi Axle Container',
        '34FT Single Axle Container',
        'FTL',
        'High Bed Trailer',
        'High Bed Trailer Double Axle',
        'High Bed Trailer Multi Axle',
        'LCV Closed Container - 14FT',
        'LCV-14FT',
        'LCV-19FT',
        'LCV-7FT',
        'LCV-17FT',
        'Low Bed Trailer',
        'Low Bed Trailer Double Axle',
        'Low Bed Trailer Multi Axle',
        'LPT-19 FT',
        'LPT-19FT Closed Container',
      ],
      truckersData: [],
      classicModal: false,
      trucksState: '',
    };
    this.change = this.change.bind(this);
    this.submitTrucker = this.submitTrucker.bind(this);
    this.validateAllFields = this.validateAllFields.bind(this);
    this.getTruckTypes = this.getTruckTypes.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.addTrucks = this.addTrucks.bind(this);
    this.onChangeTruckerName = this.onChangeTruckerName.bind(this);
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  componentDidMount() {
    this.getUserCompanyName();
    const truckersData = [];
    const {
      name,
      contactPerson,
      phoneNumber,
      email,
      creditLimit,
      gstNumber,
      panNumber,
    } = this.props.selectedTruckerData;
    this.setState({
      truckersData,
      name,
      contactPerson,
      phoneNumber,
      email,
      creditLimit,
      gstNumber,
      panNumber,
    });
    // if (this.props.selectedTruckerData.trucks) {
    //   for (const i in this.props.selectedTruckerData.trucks) {
    //     truckersData.push({
    //       color: 'success',
    //       data: [
    //         this.props.selectedTruckerData.trucks[i].truckNumber,
    //         this.props.selectedTruckerData.trucks[i].truckType,
    //       ],
    //     });
    //   }
    // }
  }

  /**
   * opens the modal button
   * @param {*} modal
   */
  handleClickOpen(modal) {
    const x = [];
    x[modal] = true;
    this.setState(x);
  }

  /**
   * closes the modal button
   * @param {*} modal
   */
  handleClose(modal) {
    const x = [];
    x[modal] = false;
    this.setState(x);
  }

  // function that returns true if value is there, false otherwise
  verifyRequired(value) {
    if (value) {
      return true;
    }
    return false;
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains only numbers
  verifyNumber(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains PanCard
  verifyPan(value) {
    const regpan = /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/;
    if (regpan.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains PanCard
  verifyPhone(value) {
    const regPhone = /^[56789]\d{9}$/;
    if (regPhone.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  // function that returns true if value is Valid GST, false otherwise
  verifyGst(value) {
    const gstRex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (gstRex.test(value)) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'required':
        if (this.verifyRequired(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          const selectedData = this.props.selectedTruckerData;
          selectedData.name = event.target.value;
          this.props.switchTruckerActivePage(true, selectedData);
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          const selectedData = this.props.selectedTruckerData;
          selectedData.name = event.target.value;
          this.props.switchTruckerActivePage(true, selectedData);
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'pan':
        if (this.verifyPan(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'phone':
        if (this.verifyPhone(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'gst':
        if (this.verifyGst(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        break;
    }
  }

  onChangeTruckerName(event) {
    this.setState({
      name: event.target.value,
    });
    const { selectedTruckerData } = this.props;
    selectedTruckerData.name = event.target.value;
    this.props.switchTruckerActivePage(true, selectedTruckerData);
  }

  onChangeContactPerson(event) {
    this.setState({
      contactPerson: event.target.value,
    });
    const { selectedTruckerData } = this.props;
    selectedTruckerData.contactPerson = event.target.value;
    this.props.switchTruckerActivePage(true, selectedTruckerData);
  }

  onChangePhoneNumber(event) {
    this.setState({
      phoneNumber: event.target.value,
    });
    const { selectedTruckerData } = this.props;
    selectedTruckerData.phoneNumber = event.target.value;
    this.props.switchTruckerActivePage(true, selectedTruckerData);
  }

  onChangecreditLimit(event) {
    this.setState({
      creditLimit: event.target.value,
    });
    const { selectedTruckerData } = this.props;
    selectedTruckerData.creditLimit = event.target.value;
    this.props.switchTruckerActivePage(true, selectedTruckerData);
  }

  onChangeGst(event) {
    this.setState({
      gstNumber: event.target.value,
    });
    const { selectedTruckerData } = this.props;
    selectedTruckerData.gstNumber = event.target.value;
    this.props.switchTruckerActivePage(true, selectedTruckerData);
  }

  onChangePan(event) {
    this.setState({
      panNumber: event.target.value,
    });
    const { selectedTruckerData } = this.props;
    selectedTruckerData.panNumber = event.target.value;
    this.props.switchTruckerActivePage(true, selectedTruckerData);
  }

  onChangeEmail(event) {
    this.setState({
      email: event.target.value,
    });
    const { selectedTruckerData } = this.props;
    selectedTruckerData.email = event.target.value;
    this.props.switchTruckerActivePage(true, selectedTruckerData);
  }

  // validates all required fields and returns a promise
  validateAllFields() {
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify Email

      // Verify Name
      if (this.verifyLength(this.props.selectedTruckerData.name, 1)) {
        this.setState({
          nameState: 'success',
        });
      } else {
        this.setState({
          nameState: 'error',
        });
        error = true;
      }

      // Verify Phone Number
      if (this.verifyPhone(this.props.selectedTruckerData.phoneNumber)) {
        this.setState({ phoneNumberState: 'success' });
      } else {
        this.setState({ phoneNumberState: 'error' });
        error = true;
      }

      if (!error) {
        resolve(true);
      } else {
        reject('validation Failed');
      }
    });
  }

  /**
   * Submits the transporter details to the Database
   * @function
   */
  getUserCompanyName() {
    const _me = this;
    firebase.auth().onAuthStateChanged(user => {
      const userRef = db.collection(`Users`).doc(`${user.uid}`);
      userRef
        .get()
        .then(
          resultData => {
            if (resultData.exists) {
              _me.setState({
                companyName: resultData.data().companyName,
                companyId: resultData.data().companyId,
              });
            }
          },
          err => {
            console.log('err', err);
          }
        )
        .catch(err => {
          console.log('err', err);
        });
    });
  }

  /**
   * Submits the transporter details to the Database
   * @function
   */
  submitTrucker() {
    // const { trucks } = this.props.selectedTruckerData;
    this.setState({
      isLoading: true,
    });
    this.validateAllFields()
      .then(
        success => {
          //   let _me = this;
          if (success) {
            const user = firebase.auth().currentUser;
            const truckerRef = db.collection(`Companies`);
            truckerRef
              .doc(this.state.companyId)
              .collection(`Truckers`)
              .doc(this.props.selectedTruckerData.truckerId)
              .update({
                name: this.props.selectedTruckerData.name,
                email: this.props.selectedTruckerData.email,
                contactPerson: this.props.selectedTruckerData.contactPerson,
                phoneNumber: this.props.selectedTruckerData.phoneNumber,
                creditLimit: this.props.selectedTruckerData.creditLimit,
                gstNumber: this.props.selectedTruckerData.gstNumber,
                panNumber: this.props.selectedTruckerData.panNumber,
                updatedBy: user.uid,
                createdAt: new Date(),
                modifiedAt: new Date(),
                companyName: this.state.companyName,
              })
              .then(userDoc => {
                // Updated Succeessfully
                this.clearForm();
                this.setState({ isLoading: false });
              });
          }
        },
        err => {
          console.log(err);
          this.setState({
            isLoading: false,
          });
        }
      )
      .catch(err => {
        console.log(err);
        this.setState({
          isLoading: false,
        });
      });
  }

  /**
   * Will Clears ALl the State values after form submision
   * @function
   */
  clearForm() {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Good job!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={`${this.props.classes.button} ${this.props.classes.success}`}
        >
          Trucker Detail Updated Succeessfully
        </SweetAlert>
      ),
    });
    setTimeout(() => {
      this.props.switchTruckerActivePage(false, {});
    }, 2000);
    this.setState({
      name: '',
      nameState: '',
      email: '',
      emailState: '',
      contactPerson: '',
      phoneNumber: '',
      phoneNumberState: '',
      creditLimit: '',
      creditLimitState: '',
      gstNumber: '',
      gstNumberState: '',
      panNumber: '',
      panNumberState: '',
      truckType: '',
      truckNumber: '',
      truckNumberState: '',
      truckersData: [],
      trucksState: '',
    });
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  switchToView() {
    this.props.switchTruckerActivePage(false, {});
  }

  getTruckTypes() {
    const { classes } = this.props;
    return (
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
          Select Truck Type
        </InputLabel>
        <Select
          MenuProps={{ className: classes.selectMenu }}
          classes={{ select: classes.select }}
          value={this.state.truckType}
          onChange={this.handleSimple}
          inputProps={{ name: 'truckType', id: 'simple-select' }}
        >
          <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
            Select Truck Type
          </MenuItem>
          {this.state.truckTypes.map((truckType, i) => (
            <MenuItem
              key={i}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={truckType}
            >
              {truckType}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  // Will Add the truck To the List
  addTrucks() {
    if (this.verifyLength(this.state.truckNumber, 1)) {
      this.setState({
        truckNumberState: 'success',
      });
      const { selectedTruckerData } = this.props;

      if (selectedTruckerData.trucks) {
        selectedTruckerData.trucks = this.props.selectedTruckerData.trucks;
        selectedTruckerData.trucks.push({
          truckNumber: this.state.truckNumber,
          truckType: this.state.truckType,
        });
      } else {
        selectedTruckerData.trucks = [];
      }
      const { truckersData } = this.state;
      truckersData.push({
        color: 'success',
        data: [this.state.truckNumber, this.state.truckType],
      });
      this.props.switchTruckerActivePage(true, selectedTruckerData);
      this.handleClose('classicModal');
      this.setState({
        truckersData,
        truckNumber: '',
        truckType: '',
      });
    } else {
      this.setState({
        truckNumberState: 'error',
      });
    }
  }

  // Modal Window To Add The Trucks
  getSweetAlertForm() {
    const { classes } = this.props;
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        classes={{ root: classes.center, paper: classes.modal }}
        style={{ maxWidth: '400px !important', textAlign: 'center' }}
        open={this.state.classicModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleClose('classicModal')}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => this.handleClose('classicModal')}
          >
            <Close className={classes.modalClose} />
          </IconButton>
          <h4 className={classes.modalTitle}>Add Truck Details</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Truck Number *"
                id="truck-number"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'truckNumber', 'length', 0),
                  type: 'length',
                  value: this.state.truckNumber,
                }}
                success={this.state.truckNumberState === 'success'}
                error={this.state.truckNumberState === 'error'}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              {this.getTruckTypes()}
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button color="primary" onClick={() => this.addTrucks()}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  /**
   * Will return the Table Component
   * @function
   */
  getTable() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader style={{ marginBottom: '2px', paddingBottom: '2px' }}>
          <h4>Add Trucks</h4>
        </CardHeader>
        {this.state.truckersData.length > 0 ? (
          <CardBody className={classes.customCardContentClass}>
            <Table
              hover
              tableHead={['Truck Number', 'Type']}
              tableData={this.state.truckersData}
            />
          </CardBody>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <p
              className={`${classes.textCenter} ${classes.title}`}
              style={{ textAlign: 'center', color: 'red' }}
            >
              {this.state.trucksState}
            </p>
          </div>
        )}
        <CardFooter>
          <Button
            color="info"
            onClick={() => this.handleClickOpen('classicModal')}
            className={classes.updateProfileButton}
            justIcon
          >
            <Add />
          </Button>
        </CardFooter>
      </Card>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {this.state.alert}
            <Card>
              <CardHeader className={`${classes.cardHeader}`} color="danger">
                <CardIcon
                  color="primary"
                  className={`${classes.cardHeader}`}
                  onClick={() => this.switchToView()}
                >
                  <ArrowBack />
                </CardIcon>
                <h4
                  className={`${classes.cardIconTitle} ${classes.rajdhaniFont}`}
                >
                  Add Trucker <small />
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Trucker Name *"
                      id="username"
                      formControlProps={{ fullWidth: true }}
                      success={this.state.nameState === 'success'}
                      error={this.state.nameState === 'error'}
                      inputProps={{
                        onChange: event => this.onChangeTruckerName(event),
                        type: 'length',
                        value: this.state.name,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Contact Person "
                      id="contact-person"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event => this.onChangeContactPerson(event),
                        type: 'length',
                        value: this.state.contactPerson,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Phone Number *"
                      id="phone-number"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event => this.onChangePhoneNumber(event),
                        type: 'phone',
                        value: this.state.phoneNumber,
                      }}
                      success={this.state.phoneNumberState === 'success'}
                      error={this.state.phoneNumberState === 'error'}
                    />
                  </GridItem>

                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Credit Limit "
                      id="credit-limit"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event => this.onChangecreditLimit(event),
                        type: 'number',
                        value: this.state.creditLimit,
                      }}
                      success={this.state.creditLimitState === 'success'}
                      error={this.state.creditLimitState === 'error'}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="GST Number "
                      id="gst-number"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event => this.onChangeGst(event),
                        type: 'gst',
                        value: this.state.gstNumber,
                      }}
                      success={this.state.gstNumberState === 'success'}
                      error={this.state.gstNumberState === 'error'}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="PAN Number"
                      id="pan-number"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event => this.onChangePan(event),
                        type: 'pan',
                        value: this.state.panNumber,
                      }}
                      success={this.state.panNumberState === 'success'}
                      error={this.state.panNumberState === 'error'}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      success={this.state.emailState === 'success'}
                      error={this.state.emailState === 'error'}
                      labelText="Email address "
                      id="email-address"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event => this.onChangeEmail(event),
                        type: 'email',
                        value: this.state.email,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                {this.state.isLoading ? (
                  <CircularProgress
                    className={classes.progress}
                    style={{ color: purple[500] }}
                    thickness={7}
                  />
                ) : (
                  <Button
                    color="rose"
                    onClick={this.submitTrucker}
                    className={classes.updateProfileButton}
                  >
                    Submit
                  </Button>
                )}
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
EditTrucker.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isTruckerEditForm: state.truckers.isTruckerEditForm,
  selectedTruckerData: state.truckers.selectedTruckerData,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchTruckerActivePage,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(AddTruckersStyle)(EditTrucker));
