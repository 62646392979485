/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable class-methods-use-this */
/* eslint-disable import/order */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Done from '@material-ui/icons/Done';
import WebView from '../../assets/img/agent-web-view.png';
import MobileView from '../../assets/img/agent-mobile-view.png';
import history from '../../history.js';
import '../../assets/landingCss.css';
import '../../assets/color.css';
// import ''

import landingPageStyle from 'assets/jss/material-dashboard-pro-react/views/landingPageStyle';

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.goRegister = this.goRegister.bind(this);
  }

  componentDidMount() {
    // Check weather the user is Authenticated
  }

  goRegister() {
    history.push('/pages/register-page');
  }

  render() {
    const { classes } = this.props;
    return (
      <div
        className={classes.container}
        style={{ width: '100%', paddingLeft: '0px', paddingRight: '0px' }}
      >
        <div className="font-friendly">
          <div className="yellow-accent">
            <header className="header">
              <div className="container-lrg">
                {/* <div className="flex col-12 spread">
              <a className="logo logo">
                <img src="images/f0bf1b53-c680-4d3c-88ee-4725a1b6d0b2.png" alt="Lobb AgentCompany Logo"/>
              </a>
              <a href="mailto:support@lobb.in" className="nav-link secondary-color mr0">Contact</a>
            </div> */}
              </div>
            </header>
            <section className="section">
              <div className="container mb40">
                <div className="col-12 text-center">
                  <h1 className="heading-lrg primary-color">
                    Agents get simple using LOBB Digital
                  </h1>
                  <h2 className="subheading secondary-color mt20">
                    Accelerate your bussiness. Spend lesser time in managing
                    day-day follow up. We have made it simple.
                  </h2>
                  <div className="mt40">
                    <div
                      // href="#"
                      onClick={this.goRegister}
                      target="_blank"
                      className="button mobile-text-center mt10 mobile-text-center accent-bg primary-color"
                    >
                      <span> Go Digital Now! </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-lrg">
                <div className="col-12">
                  <div className="browserphone">
                    <div className="browserphone-browser ">
                      <div className="mask">
                        <img
                          src={WebView}
                          alt="Screenshot of App in Browser"
                          className="mask-img"
                        />
                      </div>
                    </div>
                    <div className="browserphone-iphone">
                      <div className="mask">
                        <img
                          src={MobileView}
                          alt="Screenshot of iPhone App"
                          className="mask-img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <section
            id="feature-1"
            className="section yellow-flat"
            sitemeta="[object Object]"
            activepage="Landing"
          >
            <div className="container-lrg flex">
              <div className="col-4 text-center flex flex-column center-horizontal">
                <i className="icon mobile-center-icon secondary-bg">
                  <svg
                    className="svg-fill"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M22.5.133h-21C.672.134 0 .805 0 1.633v16c0 .828.672 1.5 1.5 1.5h11.663c.11 0 .218.037.306.104l4.926 3.691A1 1 0 0 0 20 22.132v-2.999h2.5c.828 0 1.5-.672 1.5-1.5v-16c0-.828-.672-1.499-1.5-1.5zm-15.5 11a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm5 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm5 0a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                  </svg>
                </i>
                <h3 className="bold primary-color">Manage Outstanding</h3>
                <p className="paragraph secondary-color">
                  Automated SMS and E-mail reminders for Payments.
                </p>
              </div>
              <div className="col-4 text-center flex flex-column center-horizontal">
                <i className="icon mobile-center-icon secondary-bg">
                  <svg
                    className="svg-fill"
                    viewBox="0 0 25 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M2 3a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm8 12.5V12a5 5 0 1 0-10 0v3.5A1.5 1.5 0 0 0 1.5 17H2v5.5A1.5 1.5 0 0 0 3.5 24h3A1.5 1.5 0 0 0 8 22.5V17h.5a1.5 1.5 0 0 0 1.5-1.5zm12 0v-8a.5.5 0 1 0-1 0v8a.5.5 0 1 0 1 0zm-9 0v-3a.5.5 0 1 0-1 0v3a.5.5 0 1 0 1 0zm6 0v-5a.5.5 0 1 0-1 0v5a.5.5 0 1 0 1 0zm-3 0v-9a.5.5 0 1 0-1 0v9a.5.5 0 1 0 1 0zM23.5 2h-14a.5.5 0 0 0 0 1h14a.5.5 0 0 1 .5.5v14a.5.5 0 0 1-.5.5h-14a.5.5 0 1 0 0 1h14a1.5 1.5 0 0 0 1.5-1.5v-14A1.5 1.5 0 0 0 23.5 2z" />
                  </svg>
                </i>
                <h3 className="bold primary-color">Manage Payments</h3>
                <p className="paragraph secondary-color">
                  ATH, BTH of receivables and payables.
                </p>
              </div>
              <div className="col-4 text-center flex flex-column center-horizontal">
                <i className="icon mobile-center-icon secondary-bg">
                  <svg
                    className="svg-fill"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 25 25"
                  >
                    <path d="M25 3v4H0V3a1 1 0 0 1 1-1h5V.5a.5.5 0 0 1 1 0V2h11V.5a.5.5 0 0 1 1 0V2h5a1 1 0 0 1 1 1zM0 8h25v15a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1zm8.18 7.384l3 2.5a.5.5 0 0 0 .674-.03l5-5a.5.5 0 0 0-.707-.707l-4.677 4.677-2.65-2.208a.5.5 0 1 0-.64.768z" />
                  </svg>
                </i>
                <h3 className="bold primary-color">POD Status</h3>
                <p className="paragraph secondary-color">
                  Get details of POD submission status.
                </p>
              </div>
            </div>
          </section>
          <section
            id="checklist-2"
            className="section yellow-white"
            sitemeta="[object Object]"
            activepage="Landing"
          >
            <div className="container text-center mb75">
              <div className="col-12">
                <h3 className="heading primary-color">Easy to Start</h3>
                <p className="subheading secondary-color mt20">
                  Even easier to get great value
                </p>
              </div>
            </div>
            <div className="container flex">
              <ul className="checklist col-12">
                <li className="checklist-item flex center-horizontal spread pad20">
                  <p className="bold primary-color mr20">
                    No upfront costs: You don't need to make commitments to get
                    great prices
                  </p>
                  <Done style={{ color: '#F5CA0A' }} />
                </li>
                <li className="checklist-item flex center-horizontal spread pad20">
                  <p className="bold primary-color mr20">
                    Pay-as-you-go: Pay for services on an as-needed basis, you
                    save money and direct more focus on your core business​.
                  </p>
                  <Done style={{ color: '#F5CA0A' }} />
                </li>
                <li className="checklist-item flex center-horizontal spread pad20">
                  <p className="bold primary-color mr20">
                    No termination fees: The second you turn off services is the
                    second you stop paying for that service
                  </p>
                  <Done style={{ color: '#F5CA0A' }} />
                </li>
              </ul>
            </div>
          </section>
          <section
            id="cta-3"
            className="section yellow-gradient"
            sitemeta="[object Object]"
            activepage="Landing"
          >
            <div className="container text-center">
              <div className="col-12">
                <h4 className="heading-lrg primary-color">Friendly Pricing</h4>
                <p className="paragraph mt20 secondary-color mt20">
                  Get 60 day Free Trial. Continue to get free benefit until you
                  reach 50 transactions per month. Upon reaching 50
                  transactions/month pay as less as ₹2500 per month.
                </p>
                <div className="mt40">
                  <div
                    // href="#"
                    onClick={this.goRegister}
                    target="_blank"
                    className="button mobile-text-center mt10 mobile-text-center accent-bg primary-color"
                  >
                    <span> Go Digital Now </span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

LandingPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(landingPageStyle)(LandingPage);
