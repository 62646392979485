/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
import React from 'react';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// @material-ui/icons
import GroupAdd from '@material-ui/icons/GroupAdd';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';

import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';

import SweetAlert from 'react-bootstrap-sweetalert';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import Danger from 'components/Typography/Danger.jsx';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// Fireabse
import userProfileStyles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx';
import firebase from '../../config/config';

const db = firebase.firestore();

class AddUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      nameState: '',

      email: '',
      emailState: '',

      contactPerson: '',
      contactPersonState: '',

      phoneNumber: '',
      phoneNumberState: '',

      creditLimit: '',
      creditLimitState: '',

      gstNumber: '',
      gstNumberState: '',

      panNumber: '',
      panNumberState: '',

      city: '',
      cityState: '',

      state: '',
      stateState: '',

      postalCode: '',
      postalCodeState: '',

      companyName: '',
      isLoading: false,

      alert: null,
      companyId: '',

      cities: [],
      citiesState: [],

      selectedValue: 'male',
      selectedEnabled: 'male',
    };
    this.change = this.change.bind(this);
    this.submitUser = this.submitUser.bind(this);
    this.validateAllFields = this.validateAllFields.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.handleCities = this.handleCities.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getRadioForm = this.getRadioForm.bind(this);
  }

  componentDidMount() {
    this.getUserCompanyName();

    const tour = {
      id: 'hello-hopscotch',
      showPrevButton: true,
      onEnd: this.endTour,
      onClose: this.endTour,
      showCloseButton: true,
      i18n: { closeTooltip: 'Skip Tour' },
      steps: [
        {
          title: "Enter User's Name",
          content: '',
          target: 'username',
          placement: 'bottom',
        },
        {
          title: 'Enter Phone Number',
          content: '',
          target: 'phone-number',
          placement: 'bottom',
        },
        {
          title: 'Enter Email Address',
          content: '',
          target: 'email-address',
          placement: 'bottom',
        },
        {
          title: 'Select Gender',
          content: '',
          target: 'gender',
          placement: 'bottom',
        },
        {
          title: 'Select Cities',
          content: '',
          target: 'city',
          placement: 'left',
        },
        {
          title: 'Submit and Add ',
          content: '',
          target: 'submit-user',
          placement: 'left',
        },
      ],
    };

    // Start the tour!
    if (this.props.userDetails.onBoard.user) {
      window.hopscotch.startTour(tour);
    }
  }

  endTour() {
    //  console.log("Tour ended")
    window.hopscotch.endTour([]);
    const user = firebase.auth().currentUser;
    if (user) {
      db.collection('Users')
        .doc(user.uid)
        .update({ 'onBoard.user': false });
    }
  }

  componentWillUnmount() {
    window.hopscotch.endTour([]);
  }

  handleChange(event) {
    this.setState({ selectedValue: event.target.value });
  }

  handleCities = event => {
    this.setState({ cities: event.target.value });
  };

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  // function that returns true if value is there, false otherwise
  verifyRequired(value) {
    if (value) {
      return true;
    }
    return false;
  }

  // function that returns true if value is Valid GST, false otherwise
  verifyGst(value) {
    const gstRex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (gstRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains only numbers
  verifyNumber(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains PanCard
  verifyPan(value) {
    const regpan = /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/;
    if (regpan.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains PanCard
  verifyPhone(value) {
    const regPhone = /^[56789]\d{9}$/;
    if (regPhone.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'required':
        if (this.verifyRequired(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'pan':
        if (this.verifyPan(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'phone':
        if (this.verifyPhone(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'gst':
        if (this.verifyGst(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        break;
    }
  }

  // validates all required fields and returns a promise
  validateAllFields() {
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify Name
      if (this.verifyLength(this.state.name, 1)) {
        this.setState({
          nameState: 'success',
        });
      } else {
        this.setState({
          nameState: 'error',
        });
        error = true;
      }
      // Verify Phone Number
      if (this.verifyPhone(this.state.phoneNumber)) {
        this.setState({
          phoneNumberState: 'success',
        });
      } else {
        this.setState({
          phoneNumberState: 'error',
        });
        error = true;
      }
      if (this.state.cities.length > 1) {
        this.setState({ citiesState: '' });
      } else {
        this.setState({
          citiesState: 'Please Select atleast 2 cities',
        });
        error = true;
      }

      if (!error) {
        resolve(true);
      } else {
        reject('validation Failed');
      }
    });
  }

  /**
   * Get the Users Company Name from the Database
   * @function
   */
  getUserCompanyName() {
    const _me = this;
    firebase.auth().onAuthStateChanged(user => {
      const userRef = db.collection(`Users`).doc(`${user.uid}`);
      userRef
        .get()
        .then(
          resultData => {
            if (resultData.exists) {
              _me.setState({
                companyName: resultData.data().companyName,
                companyId: resultData.data().companyId,
              });
            }
          },
          err => {
            console.log('err', err);
          }
        )
        .catch(err => {
          console.log('err', err);
        });
    });
  }

  /**
   * Submits the user details to the Database
   * @function
   */
  submitUser() {
    const _me = this;
    this.setState({
      isLoading: true,
    });
    this.validateAllFields()
      .then(
        success => {
          //   let _me = this;
          if (success) {
            const addMessage = firebase.functions().httpsCallable('addUsers');
            const {
              name,
              phoneNumber,
              email,
              cities,
              selectedValue,
              companyId,
              companyName,
            } = this.state;
            const body = {
              name,
              phoneNumber: `+91${phoneNumber}`,
              email,
              cities,
              gender: selectedValue,
              companyId,
              companyName,
            };
            addMessage(body)
              .then(result => {
                // Read result of the Cloud Function.
                // var sanitizedMessage = result.data.text;
                _me.clearForm();
                _me.setState({
                  isLoading: false,
                });
              })
              .catch(err => {
                let message = '';
                message = err.message;
                _me.setState({
                  isLoading: false,
                  alert: (
                    <SweetAlert
                      error
                      style={{
                        display: 'block',
                        marginTop: '-100px',
                      }}
                      title="Error"
                      onConfirm={() => this.hideAlert()}
                      onCancel={() => this.hideAlert()}
                      confirmBtnCssClass={`${this.props.classes.button} ${this.props.classes.success}`}
                    >
                      {message}
                    </SweetAlert>
                  ),
                });
              });
          }
        },
        err => {
          console.log(err);
          _me.setState({
            isLoading: false,
          });
        }
      )
      .catch(err => {
        console.log(err);
        _me.setState({
          isLoading: false,
        });
      });
  }

  /**
   * Will Clears ALl the State values after form submision
   * @function
   */
  clearForm() {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Good job!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={`${this.props.classes.button} ${this.props.classes.success}`}
        >
          User Added Successfully
        </SweetAlert>
      ),
    });
    const _me = this;
    _me.setState({
      name: '',
      nameState: '',
      email: '',
      emailState: '',
      contactPerson: '',
      contactPersonState: '',
      phoneNumber: '',
      phoneNumberState: '',
      creditLimit: '',
      creditLimitState: '',
      gstNumber: '',
      gstNumberState: '',
      panNumber: '',
      panNumberState: '',
      city: '',
      cityState: '',
      state: '',
      stateState: '',
      postalCode: '',
      postalCodeState: '',
      cities: [],
      citiesState: '',
    });
  }

  getRadioForm() {
    const { classes } = this.props;
    return (
      <div className={classes.inlineChecks} style={{ marginTop: '5px' }}>
        <InputLabel htmlFor="multiple-select" className={classes.selectLabel}>
          Gender
        </InputLabel>
        <br />
        <FormControlLabel
          control={
            <Radio
              checked={this.state.selectedValue === 'male'}
              onChange={this.handleChange}
              value="male"
              name="radio button demo"
              aria-label="Male"
              icon={<FiberManualRecord className={classes.radioUnchecked} />}
              checkedIcon={
                <FiberManualRecord className={classes.radioChecked} />
              }
              classes={{
                checked: classes.radio,
              }}
            />
          }
          classes={{
            label: classes.label,
          }}
          label="Male"
        />

        <FormControlLabel
          control={
            <Radio
              checked={this.state.selectedValue === 'female'}
              onChange={this.handleChange}
              value="female"
              name="radio button demo"
              aria-label="Female"
              icon={<FiberManualRecord className={classes.radioUnchecked} />}
              checkedIcon={
                <FiberManualRecord className={classes.radioChecked} />
              }
              classes={{
                checked: classes.radio,
              }}
            />
          }
          classes={{
            label: classes.label,
          }}
          label="Female"
        />
      </div>
    );
  }

  getMultiselectForm() {
    const { classes } = this.props;
    return (
      <GridItem xs={12} sm={12} md={12} lg={12}>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel htmlFor="multiple-select" className={classes.selectLabel}>
            Which Cities do you operate out of
          </InputLabel>
          <Select
            multiple
            value={this.state.cities}
            onChange={this.handleCities}
            MenuProps={{ className: classes.selectMenu }}
            classes={{ select: classes.select }}
            inputProps={{ name: 'multipleSelect', id: 'multiple-select' }}
          >
            <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
              Choose City
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="Agra"
            >
              Agra
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="Ahmedabad"
            >
              Ahmedabad
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="Bengaluru"
            >
              Bengaluru
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="Chennai"
            >
              Chennai
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="Coimbatore"
            >
              Coimbatore
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="Hyderabad"
            >
              Hyderabad
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="Kolkata"
            >
              Kolkata
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="Kochi"
            >
              Kochi
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="Lucknow"
            >
              Lucknow
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="Mumbai"
            >
              Mumbai
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="Mysore"
            >
              Mysore
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="Nagpur"
            >
              Nagpur
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="Nashik"
            >
              Nashik
            </MenuItem>
          </Select>
        </FormControl>
        <Danger>{this.state.citiesState}</Danger>
      </GridItem>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {this.state.alert}
            <Card>
              <CardHeader className={`${classes.cardHeader}`} color="warning">
                <CardIcon color="primary" className={`${classes.cardHeader}`}>
                  <GroupAdd />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  Add User <small />
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Name *"
                      id="username"
                      formControlProps={{ fullWidth: true }}
                      success={this.state.nameState === 'success'}
                      error={this.state.nameState === 'error'}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'name', 'length', 1),
                        type: 'length',
                        value: this.state.name,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Phone Number *"
                      id="phone-number"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event =>
                          this.change(event, 'phoneNumber', 'phone'),
                        type: 'phone',
                        value: this.state.phoneNumber,
                      }}
                      success={this.state.phoneNumberState === 'success'}
                      error={this.state.phoneNumberState === 'error'}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      success={this.state.emailState === 'success'}
                      error={this.state.emailState === 'error'}
                      labelText="Email address "
                      id="email-address"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event => this.change(event, 'email', 'email'),
                        type: 'email',
                        value: this.state.email,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                  <GridItem id="gender" xs={12} sm={12} md={4}>
                    {this.getRadioForm()}
                  </GridItem>
                  <GridItem id="city" xs={12} sm={12} md={4}>
                    {this.getMultiselectForm()}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4} />
                </GridContainer>
                {this.state.isLoading ? (
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={4}>
                      <CircularProgress
                        className={classes.progress}
                        style={{ color: purple[500] }}
                        thickness={7}
                      />
                    </GridItem>
                  </GridContainer>
                ) : (
                  <Button
                    id="submit-user"
                    color="warning"
                    onClick={this.submitUser}
                    className={classes.updateProfileButton}
                  >
                    Submit
                  </Button>
                )}
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
AddUsers.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userDetails: state.main.userDetails,
});

export default connect(mapStateToProps)(
  withStyles(userProfileStyles)(AddUsers)
);
