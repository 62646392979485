import * as firebase from 'firebase';

// const config = {
//   apiKey: 'AIzaSyA7UOBfmXSf9rrra4mTrUNHwLLtUmhwn3E',
//   authDomain: 'lobb-agent-staging.firebaseapp.com',
//   databaseURL: 'https://lobb-agent-staging.firebaseio.com',
//   projectId: 'lobb-agent-staging',
//   storageBucket: 'lobb-agent-staging.appspot.com',
//   messagingSenderId: '861576349945',
// };
const config = {
  apiKey: 'AIzaSyBXck9cGEYGHMwVnKiLTNzbebKNZOtpBaU',
  authDomain: 'lobb-agent.firebaseapp.com',
  databaseURL: 'https://lobb-agent.firebaseio.com',
  projectId: 'lobb-agent',
  storageBucket: 'lobb-agent.appspot.com',
  messagingSenderId: '1049345904082',
  appId: '1:1049345904082:web:c5e3ba2daf6d8208e60c0b',
};
firebase.initializeApp(config);
const firestore = firebase.firestore();
firestore.settings({
  timestampsInSnapshots: true,
});
export default firebase;
