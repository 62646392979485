/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
import React from 'react';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

import PropTypes from 'prop-types';

// @material-ui/icons
// import TransferWithinAStation from "@material-ui/icons/TransferWithinAStation";
import ArrowBack from '@material-ui/icons/ArrowBack';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AddTruckersStyle from 'assets/jss/material-dashboard-pro-react/views/AddTruckersStyle.jsx';
import { switchTransporterActivePage } from '../../../reducers/transporters';

// Fireabse
import firebase from '../../../config/config';

const db = firebase.firestore();

class EditTransporter extends React.Component {
  anchorElLeft = null;

  anchorElTop = null;

  anchorElBottom = null;

  anchorElRight = null;

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      nameState: '',
      email: '',
      emailState: '',
      contactPerson: '',
      phoneNumber: '',
      phoneNumberState: '',
      creditLimit: '',
      creditLimitState: '',
      gstNumber: '',
      gstNumberState: '',
      panNumber: '',
      panNumberState: '',
      isLoading: false,
      alert: null,
      companyId: '',
    };
    this.change = this.change.bind(this);
    this.submitTransporter = this.submitTransporter.bind(this);
    this.validateAllFields = this.validateAllFields.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.onChangeTransporterName = this.onChangeTransporterName.bind(this);
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  componentDidMount() {
    this.getUserCompanyName();
    const transportersData = [];
    const {
      name,
      contactPerson,
      phoneNumber,
      email,
      creditLimit,
      gstNumber,
      panNumber,
      trucks,
    } = this.props.selectedTransporterData;
    this.setState({
      transportersData,
      name,
      contactPerson,
      phoneNumber,
      email,
      creditLimit,
      gstNumber,
      panNumber,
      trucks,
    });
  }

  /**
   * opens the modal button
   * @param {*} modal
   */
  handleClickOpen(modal) {
    const x = [];
    x[modal] = true;
    this.setState(x);
  }

  /**
   * closes the modal button
   * @param {*} modal
   */
  handleClose(modal) {
    const x = [];
    x[modal] = false;
    this.setState(x);
  }

  // function that returns true if value is there, false otherwise
  verifyRequired(value) {
    if (value) {
      return true;
    }
    return false;
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains only numbers
  verifyNumber(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains PanCard
  verifyPan(value) {
    const regpan = /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/;
    if (regpan.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains PanCard
  verifyPhone(value) {
    const regPhone = /^[56789]\d{9}$/;
    if (regPhone.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  // function that returns true if value is Valid GST, false otherwise
  verifyGst(value) {
    const gstRex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (gstRex.test(value)) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'required':
        if (this.verifyRequired(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          const selectedData = this.props.selectedTransporterData;
          selectedData.name = event.target.value;
          this.props.switchTransporterActivePage(true, selectedData);
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          const selectedData = this.props.selectedTransporterData;
          selectedData.name = event.target.value;
          this.props.switchTransporterActivePage(true, selectedData);
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'pan':
        if (this.verifyPan(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'phone':
        if (this.verifyPhone(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'gst':
        if (this.verifyGst(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        break;
    }
  }

  onChangeTransporterName(event) {
    this.setState({
      name: event.target.value,
    });
    const { selectedTransporterData } = this.props;
    selectedTransporterData.name = event.target.value;
    this.props.switchTransporterActivePage(true, selectedTransporterData);
  }

  onChangeContactPerson(event) {
    this.setState({
      contactPerson: event.target.value,
    });
    const { selectedTransporterData } = this.props;
    selectedTransporterData.contactPerson = event.target.value;
    this.props.switchTransporterActivePage(true, selectedTransporterData);
  }

  onChangePhoneNumber(event) {
    this.setState({
      phoneNumber: event.target.value,
    });
    const { selectedTransporterData } = this.props;
    selectedTransporterData.phoneNumber = event.target.value;
    this.props.switchTransporterActivePage(true, selectedTransporterData);
  }

  onChangecreditLimit(event) {
    this.setState({
      creditLimit: event.target.value,
    });
    const { selectedTransporterData } = this.props;
    selectedTransporterData.creditLimit = event.target.value;
    this.props.switchTransporterActivePage(true, selectedTransporterData);
  }

  onChangeGst(event) {
    this.setState({
      gstNumber: event.target.value,
    });
    const { selectedTransporterData } = this.props;
    selectedTransporterData.gstNumber = event.target.value;
    this.props.switchTransporterActivePage(true, selectedTransporterData);
  }

  onChangePan(event) {
    this.setState({
      panNumber: event.target.value,
    });
    const { selectedTransporterData } = this.props;
    selectedTransporterData.panNumber = event.target.value;
    this.props.switchTransporterActivePage(true, selectedTransporterData);
  }

  onChangeEmail(event) {
    this.setState({
      email: event.target.value,
    });
    const { selectedTransporterData } = this.props;
    selectedTransporterData.email = event.target.value;
    this.props.switchTransporterActivePage(true, selectedTransporterData);
  }

  // validates all required fields and returns a promise
  validateAllFields() {
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify Email

      // Verify Name
      if (this.verifyLength(this.props.selectedTransporterData.name, 1)) {
        this.setState({
          nameState: 'success',
        });
      } else {
        this.setState({
          nameState: 'error',
        });
        error = true;
      }

      // Verify Phone Number
      if (this.verifyPhone(this.props.selectedTransporterData.phoneNumber)) {
        this.setState({ phoneNumberState: 'success' });
      } else {
        this.setState({ phoneNumberState: 'error' });
        error = true;
      }

      if (!error) {
        resolve(true);
      } else {
        reject('validation Failed');
      }
    });
  }

  /**
   * Submits the transporter details to the Database
   * @function
   */
  getUserCompanyName() {
    const _me = this;
    firebase.auth().onAuthStateChanged(user => {
      const userRef = db.collection(`Users`).doc(`${user.uid}`);
      userRef
        .get()
        .then(
          resultData => {
            if (resultData.exists) {
              _me.setState({
                companyName: resultData.data().companyName,
                companyId: resultData.data().companyId,
              });
            }
          },
          err => {
            console.log('err', err);
          }
        )
        .catch(err => {
          console.log('err', err);
        });
    });
  }

  /**
   * Submits the transporter details to the Database
   * @function
   */
  submitTransporter() {
    this.setState({
      isLoading: true,
    });
    this.validateAllFields()
      .then(
        success => {
          //   let _me = this;
          if (success) {
            const user = firebase.auth().currentUser;
            const transporterRef = db.collection(`Companies`);
            transporterRef
              .doc(this.state.companyId)
              .collection(`Transporters`)
              .doc(this.props.selectedTransporterData.transporterId)
              .update({
                name: this.props.selectedTransporterData.name,
                email: this.props.selectedTransporterData.email,
                contactPerson: this.props.selectedTransporterData.contactPerson,
                phoneNumber: this.props.selectedTransporterData.phoneNumber,
                creditLimit: this.props.selectedTransporterData.creditLimit,
                gstNumber: this.props.selectedTransporterData.gstNumber,
                panNumber: this.props.selectedTransporterData.panNumber,
                updatedBy: user.uid,
                modifiedAt: new Date(),
              })
              .then(userDoc => {
                // Updated Succeessfully
                this.clearForm();
                this.setState({ isLoading: false });
              });
          }
        },
        err => {
          console.log(err);
          this.setState({
            isLoading: false,
          });
        }
      )
      .catch(err => {
        console.log(err);
        this.setState({
          isLoading: false,
        });
      });
  }

  /**
   * Will Clears ALl the State values after form submision
   * @function
   */
  clearForm() {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Good job!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={`${this.props.classes.button} ${this.props.classes.success}`}
        >
          Transporter Detail Updated Succeessfully
        </SweetAlert>
      ),
    });
    setTimeout(() => {
      this.props.switchTransporterActivePage(false, {});
    }, 2000);
    this.setState({
      name: '',
      nameState: '',
      email: '',
      emailState: '',
      contactPerson: '',
      phoneNumber: '',
      phoneNumberState: '',
      creditLimit: '',
      creditLimitState: '',
      gstNumber: '',
      gstNumberState: '',
      panNumber: '',
      panNumberState: '',
    });
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  switchToView() {
    this.props.switchTransporterActivePage(false, {});
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {this.state.alert}
            <Card>
              <CardHeader className={`${classes.cardHeader}`} color="warning">
                <CardIcon
                  color="primary"
                  className={`${classes.cardHeader}`}
                  onClick={() => this.switchToView()}
                >
                  <ArrowBack />
                </CardIcon>
                <h4
                  className={`${classes.cardIconTitle} ${classes.rajdhaniFont}`}
                >
                  Add Transporter <small />
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Transporter Name *"
                      id="username"
                      formControlProps={{ fullWidth: true }}
                      success={this.state.nameState === 'success'}
                      error={this.state.nameState === 'error'}
                      inputProps={{
                        onChange: event => this.onChangeTransporterName(event),
                        type: 'length',
                        value: this.state.name,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Contact Person "
                      id="contact-person"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event => this.onChangeContactPerson(event),
                        type: 'length',
                        value: this.state.contactPerson,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Phone Number *"
                      id="phone-number"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event => this.onChangePhoneNumber(event),
                        type: 'phone',
                        value: this.state.phoneNumber,
                      }}
                      success={this.state.phoneNumberState === 'success'}
                      error={this.state.phoneNumberState === 'error'}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Credit Limit "
                      id="credit-limit"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event => this.onChangecreditLimit(event),
                        type: 'number',
                        value: this.state.creditLimit,
                      }}
                      success={this.state.creditLimitState === 'success'}
                      error={this.state.creditLimitState === 'error'}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="GST Number "
                      id="gst-number"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event => this.onChangeGst(event),
                        type: 'gst',
                        value: this.state.gstNumber,
                      }}
                      success={this.state.gstNumberState === 'success'}
                      error={this.state.gstNumberState === 'error'}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="PAN Number"
                      id="pan-number"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event => this.onChangePan(event),
                        type: 'pan',
                        value: this.state.panNumber,
                      }}
                      success={this.state.panNumberState === 'success'}
                      error={this.state.panNumberState === 'error'}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      success={this.state.emailState === 'success'}
                      error={this.state.emailState === 'error'}
                      labelText="Email address "
                      id="email-address"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        onChange: event => this.onChangeEmail(event),
                        type: 'email',
                        value: this.state.email,
                      }}
                    />
                  </GridItem>
                </GridContainer>
                {this.state.isLoading ? (
                  <CircularProgress
                    className={classes.progress}
                    style={{ color: purple[500] }}
                    thickness={7}
                  />
                ) : (
                  <Button
                    color="rose"
                    onClick={this.submitTransporter}
                    className={classes.updateProfileButton}
                  >
                    Submit
                  </Button>
                )}
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
EditTransporter.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isTransporterEditForm: state.transporters.isTransporterEditForm,
  selectedTransporterData: state.transporters.selectedTransporterData,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchTransporterActivePage,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(AddTruckersStyle)(EditTransporter));
