import React from "react";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

// @material-ui/icons
import SupervisorAccount from "@material-ui/icons/SupervisorAccount";
import LocalShipping from "@material-ui/icons/LocalShipping";
import AccountBalanceWallet from "@material-ui/icons/AccountBalanceWallet";
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import LockOutline from "@material-ui/icons/LockOutline";
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import RegisterInfoArea from "components/InfoArea/RegisterInfoArea.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import firebase from "../../config/config";
import CircularProgress from "@material-ui/core/CircularProgress";
import purple from "@material-ui/core/colors/purple";
import Cookies from "universal-cookie";
import Danger from "components/Typography/Danger.jsx";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Radio from "@material-ui/core/Radio";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";


import registerPageStyle from "assets/jss/material-dashboard-pro-react/views/registerPageStyle";

const db = firebase.firestore();
// const usersRef = db.collection("Users");
const cookies = new Cookies();
class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      name: "",
      selectedValue: 'male',
      selectedEnabled: "male",
      nameState: "",
      email: "",
      emailState: "",
      companyName: "",
      companyNameState: "",
      contactNumber: "",
      uid: "",
      isLoading: false,
      isButtonLoading: false,
      cities:[],
      citiesState: [],
      open:true
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeName = this.onChangeName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChangeCompany = this.onChangeCompany.bind(this);
    this.register = this.register.bind(this);
    this.getMultiselectForm = this.getMultiselectForm.bind(this);
    this.handleCities = this.handleCities.bind(this)
  }

  componentDidMount() {
    let _me = this;
    this.setState({
      isLoading: true
    });
    const db = firebase.firestore();
    const usersRef = db.collection("Users");
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // Check if user already exists
        this.setState({
          uid: user.uid
        });
        usersRef
          .doc(user.uid)
          .get()
          .then(
            userDoc => {
              if (userDoc.exists) {
                cookies.set("userName", userDoc.data().name);
                _me.setState({ isLoading: false });
                _me.props.history.push("/pages/initial-page");
              } else {
                _me.setState({ isLoading: false });
              }
            },
            err => {
              console.log("no users found error", err);
            }
          );
      } else {
        //if not logged go to login screen
        _me.setState({
          isLoading: false
        });
          _me.props.history.push("/pages/login-page");
      }
    });
  }

  handleCities = event => {
    this.setState({ cities: event.target.value });
  };

  onChangeName(e) {
    this.setState({
      name: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }
  
  onChangeCompany(e) {
    this.setState({
      companyName: e.target.value
    });
  }
  // function that verifies if value contains only numbers
  verifyNumber(value) {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  //Will Verify cities
  verifyCities(value){
    if (value.length < 2) {
      return false;
    } else {
      return true;
    }
  }
  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "number":
        if (this.verifyNumber(event.target.value)) {
          this.setState({
            [stateName + "State"]: "success",
            [stateName]: event.target.value
          });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            [stateName]: event.target.value
          });
        }
        break;
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({
            [stateName + "State"]: "success",
            [stateName]: event.target.value
          });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            [stateName]: event.target.value
          });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [stateName + "State"]: "success",
            [stateName]: event.target.value
          });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            [stateName]: event.target.value
          });
        }
        break;
      default:
        break;
    }
  }

  // validates all required fields and returns a promise
  validateAllFields() {
    let error = false;
    return new Promise((resolve, reject) => {

      if(!this.state.checked){
        error = true;
      }
      // Verify  Name
      if (this.verifyLength(this.state.name, 1)) {
        this.setState({
          nameState: "success"
        });
      } else {
        this.setState({ nameState: "error" });
        error = true;
      }

      // Verify Cities Selected Are not
      if(this.verifyCities(this.state.cities)){
        this.setState({
          citiesState: ""
        })
      }else{
        this.setState({
          citiesState: "Select atleast two cities"
        })
        error = true;
      }
      // Verify  Email
      if (this.verifyEmail(this.state.email)) {
        this.setState({
          emailState: "success"
        });
      } else {
        this.setState({ emailState: "error" });
        error = true;
      }

      // Verify Company
      if (this.verifyLength(this.state.companyName, 2)) {
        this.setState({
          companyState: "success"
        });
      } else {
        this.setState({ companyNameState: "error" });
        error = true;
      }

      if (!error) {
        resolve(true);
      } else {
        reject("validation Failed");
      }
    });
  }
  handleChange(event) {
    this.setState({ selectedValue: event.target.value });
  }
  
  handleToggle(value) {
    
    const { checked } = this.state;
    const newChecked = !checked;
    this.setState({
      checked: newChecked
    });
  }

  /**
   * Updates the Comapny in The Db
   * @function
   */
  updateCompany(user) {
    const updateCompanyRef = db.collection("Companies");
    updateCompanyRef
      .add({
        companyName: this.state.companyName,
        createdAt: new Date(),
        updatedBy: user.uid,
        totalPayablePendingAmount:0,
        totalReceivablePendingAmount:0,
        totalCommission:0,
        freeTrialStartDate: new Date(),
        subscriptionStartDate: new Date(),
        interval:60
      })
      .then(docRef => {
        db.collection("Companies")
          .doc(docRef.id)
          .update({
            companyId: docRef.id
          });
          let isCompanyAdmin = true
        this.updateUser(user, docRef.id,isCompanyAdmin);
      });
  }

  handleClose(){
    this.setState({ open: false });
    
  };

  /**
   *  updates the Registerd user details to Db
   * @function
   */
  updateUser(user, companyId,isCompanyAdmin) {
    const usersRef = db.collection("Users");
    //Update The Comapny To Db
    usersRef
      .doc(user.uid)
      .set({
        name: this.state.name,
        email: this.state.email,
        companyName: this.state.companyName,
        uid: user.uid,
        phoneNumber: user.phoneNumber,
        isAgent: true,
        isCompanyAdmin,
        companyId,
        smsAlerts:true, // By Default SMS alert option is true
        cities:this.state.cities,
        createdAt: new Date(),
        gender: this.state.selectedValue,
        isOnboarding:true,onBoard:{transactionCreate:true, transactionEdit:true,transporter:true,trucker:true,user:true}
      })
      .then(userDoc => {
        //Updated Succeessfully
        this.props.history.push("/pages/initial-page");
        this.setState({ isButtonLoading: false,email:'',companyName:'',name:'',nameState:'',cities:[],citiesState:[] });
      });
  }

  /**
   * Register User to Db
   * @function
   */
  register() {
    const companyRef = db
      .collection("Companies")
      .where("companyName", "==", this.state.companyName);
    this.validateAllFields()
      .then(
        success => {
          //   let _me = this;
          if (success) {
           let user = firebase.auth().currentUser
              if (user) {
                this.setState({ isButtonLoading: true });
                companyRef
                  .get()
                  .then(
                    companyList => {
                      if (companyList.empty) {
                        // Update Company and User
                        this.updateCompany(user);
                      } else {
                        let companyId = "";
                        companyList.forEach(company => {
                          if (company.data().companyId) {
                            companyId = company.data().companyId;
                          }
                        });
                        let isCompanyAdmin = false
                        this.updateUser(user, companyId, isCompanyAdmin);
                      }
                    },
                    err => {
                      console.log(err);
                    }
                  )
                  .catch(err => {
                    console.log("catch ", err);
                  });
              }

          } else {
            this.setState({ isButtonLoading: false });
          }
        },
        err => {
          this.setState({ isButtonLoading: false });
        }
      )
      .catch(error => {
        this.setState({ isButtonLoading: false });
      });
  }

  getMultiselectForm() {
    const { classes } = this.props
    return <GridItem xs={12} sm={12} md={12} lg={12}>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel htmlFor="multiple-select" className={classes.selectLabel}>
            Which Cities do you operate out of
          </InputLabel>
          <Select multiple value={this.state.cities} onChange={this.handleCities} MenuProps={{ className: classes.selectMenu }} classes={{ select: classes.select }} inputProps={{ name: "multipleSelect", id: "multiple-select" }}>
            <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
              Choose City
            </MenuItem>
            <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value="Agra">
              Agra
            </MenuItem>
            <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value="Ahmedabad">
              Ahmedabad
            </MenuItem>
            <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value="Bengaluru">
              Bengaluru
            </MenuItem>
            <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value="Chennai">
              Chennai
            </MenuItem>
            <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value="Coimbatore">
              Coimbatore
            </MenuItem>
            <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value="Hyderabad">
              Hyderabad
            </MenuItem>
            <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value="Kolkata">
              Kolkata
            </MenuItem>
            <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value="Kochi">
              Kochi
            </MenuItem>
            <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value="Lucknow">
              Lucknow
            </MenuItem>
            <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value="Mumbai">
              Mumbai
            </MenuItem>
            <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value="Mysore">
              Mysore
            </MenuItem>
            <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value="Nagpur">
              Nagpur
            </MenuItem>
            <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }} value="Nashik">
              Nashik
            </MenuItem>
          </Select>
        </FormControl>
        <Danger>{this.state.citiesState}</Danger>
      </GridItem>;
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          {this.state.isLoading ? (
            <GridItem style={{ marginTop: "50px" }}>
              <CircularProgress
                className={classes.progress}
                style={{ color: purple[500] }}
                thickness={7}
              />
            </GridItem>
          ) : (
            <GridItem xs={12} sm={12} md={10}>
              <Card className={classes.cardSignup}>
                <h2 className={classes.cardTitle}>Register</h2>
                <CardBody>
                  <GridContainer justify="center">
                    <GridItem xs={12} sm={12} md={5}>
                      <RegisterInfoArea
                        title="Manage Your Transporters"
                        descriptions={['Add Transporters and Contact Person.','Automatically alert transporter for outstanding payments.']}
                        icon={SupervisorAccount}
                        iconColor="rose"
                      />
                      <RegisterInfoArea
                        title="Manage Your Fleet Owners & Trucks"
                        descriptions={['Add Fleet owners and their trucks','View Truck Documents on One Click (Coming Soon)']}
                        icon={LocalShipping}
                        iconColor="primary"
                      />
                      <RegisterInfoArea
                        title="Manage Payable to Receivable"
                        descriptions={['Quickly check pending Receivable and Payable','Transporter Wise, Transaction Wise Truck Placement & Outstanding Summary.']}
                        icon={AccountBalanceWallet}
                        iconColor="info"
                      />
                    </GridItem>
                    <GridItem xs={12} sm={8} md={5}>
                      <form className={classes.form}>
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses
                          }}
                          inputProps={{
                            onChange: event =>
                              this.change(event, "name", "length", 2),
                            type: "length",
                            value: this.state.name,
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Face className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            placeholder: "Full Name..."
                          }}
                          success={this.state.nameState === "success"}
                          error={this.state.nameState === "error"}
                        />
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses
                          }}
                          inputProps={{
                            onChange: event =>
                              this.change(event, "email", "email"),
                            type: "email",
                            value: this.state.email,
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <Email className={classes.inputAdornmentIcon} />
                              </InputAdornment>
                            ),
                            placeholder: "Email..."
                          }}
                          success={this.state.emailState === "success"}
                          error={this.state.emailState === "error"}
                        />
                        <CustomInput
                          formControlProps={{
                            fullWidth: true,
                            className: classes.customFormControlClasses
                          }}
                          inputProps={{
                            onChange: event =>
                              this.change(event, "companyName", "length", 2),
                            type: "length",
                            value: this.state.companyName,
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                className={classes.inputAdornment}
                              >
                                <LockOutline
                                  className={classes.inputAdornmentIcon}
                                />
                              </InputAdornment>
                            ),
                            placeholder: "Company Name..."
                          }}
                          success={this.state.companyNameState === "success"}
                          error={this.state.companyNameState === "error"}
                        />
                          
                          <GridContainer justify='center'>
                            <GridItem xs={12} sm={11}>
                              <div className={classes.inlineChecks}>
                                  <FormControlLabel
                                    control={
                                      <Radio
                                        checked={this.state.selectedValue === "male"}
                                        onChange={this.handleChange}
                                        value="male"
                                        name="radio button demo"
                                        aria-label="Male"
                                        icon={
                                          <FiberManualRecord
                                            className={classes.radioUnchecked}
                                          />
                                        }
                                        checkedIcon={
                                          <FiberManualRecord
                                            className={classes.radioChecked}
                                          />
                                        }
                                        classes={{
                                          checked: classes.radio
                                        }}
                                      />
                                    }
                                    classes={{
                                      label: classes.label
                                    }}
                                    label="Male"
                                  />
                               
                                  <FormControlLabel
                                    control={
                                      <Radio
                                        checked={this.state.selectedValue === "female"}
                                        onChange={this.handleChange}
                                        value="female"
                                        name="radio button demo"
                                        aria-label="Female"
                                        icon={
                                          <FiberManualRecord
                                            className={classes.radioUnchecked}
                                          />
                                        }
                                        checkedIcon={
                                          <FiberManualRecord
                                            className={classes.radioChecked}
                                          />
                                        }
                                        classes={{
                                          checked: classes.radio
                                        }}
                                      />
                                    }
                                    classes={{
                                      label: classes.label
                                    }}
                                    label="Female"
                                  />
                              </div>
                            </GridItem>
                          </GridContainer>
                          {this.getMultiselectForm()}
                        <FormControlLabel
                          classes={{
                            root: classes.checkboxLabelControl,
                            label: classes.checkboxLabel
                          }}
                          control={
                            <Checkbox
                              tabIndex={-1}
                              onClick={() => this.handleToggle(1)}
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{ checked: classes.checked }}
                            />
                          }
                          label={
                            <span>
                              I agree to the{" "}
                              <a href="#pablo">terms and conditions</a>.
                            </span>
                          }
                        />
                     
                        <div className={classes.center}>
                          {this.state.isButtonLoading ? (
                            <CircularProgress
                              className={classes.progress}
                              style={{ color: purple[500] }}
                              thickness={7}
                            />
                          ) : (
                            <Button
                              round
                              color="primary"
                              onClick={this.register}
                            >
                              Register
                            </Button>
                          )}
                        </div>
                      </form>
                    </GridItem>
                  </GridContainer>
                </CardBody>
              </Card>
            </GridItem>
          )}
        </GridContainer>
        
      </div>
    );
  }
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(registerPageStyle)(RegisterPage);
