/* eslint-disable react/sort-comp */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// react plugin for creating charts
// react plugin for creating vector maps

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/icons

import DateRange from '@material-ui/icons/DateRange';
import CallMade from '@material-ui/icons/CallMade';
import CallReceived from '@material-ui/icons/CallReceived';
import Button from 'components/CustomButtons/Button.jsx';
import Dialog from '@material-ui/core/Dialog';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import Wizard from 'components/Wizard/Wizard.jsx';
import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import firebase from '../../config/config';
import DashboardTable from './Sections/Table';
import history from '../../history.js';

//  import { DialogContent } from "../../../node_modules/@material-ui/core";

import One from './OnboardingSlides/One';
import Two from './OnboardingSlides/Two';
import Three from './OnboardingSlides/Three';
import Four from './OnboardingSlides/Four';

const db = firebase.firestore();

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      transporters: [],
      companyId: '',
      uid: '',
      transportersData: [],
      AthData: [],
      truckersData: [],
      truckers: [],
      isLoading: false,
      athAmount: 0,
      bthAmount: 0,
      payableAmount: 0,
      paidAmount: 0,
      receivableAmount: 0,
      totalCommission: 0,
      totalBonus: 0,
      open: false,
    };
    this.getDashboardBalances = this.getDashboardBalances.bind(this);
    this.getTable = this.getTable.bind(this);
    this.goToAddTransporter = this.goToAddTransporter.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({ isLoading: true });

        // Start the tour!
        if (this.props.userDetails.isOnboarding) {
          this.setState({ open: true });
          //  window.hopscotch.startTour(tour);
        }

        this.getDashboardBalances(user);
      }
    });
  }

  endTour() {
    //  console.log("Tour ended")
    window.hopscotch.endTour([]);

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        db.collection('Users')
          .doc(user.uid)
          .update({ isOnboarding: false });
      }
    });
  }

  skipTours() {
    window.hopscotch.endTour([]);

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        db.collection('Users')
          .doc(user.uid)
          .update({
            isOnboarding: false,
            'onBoard.transporter': false,
            'onBoard.trucker': false,
            'onBoard.transactionCreate': false,
            'onBoard.transactionEdit': false,
            'onBoard.user': false,
          });
      }
    });
  }

  goToAddTransporter() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        db.collection('Users')
          .doc(user.uid)
          .update({ isOnboarding: false });
      }
    });

    //  window.hopscotch.endTour([])
    history.push('/agent/transporters/add');
    //  window.hopscotch.endTour([])
  }

  componentWillUnmount() {
    //  window.hopscotch.endTour([])
  }

  /**
   * Will Get ALl the Total Bth Balance For The User
   * @function
   */
  getDashboardBalances(user) {
    const mainRef = db.collection('Users').doc(user.uid);
    mainRef.get().then(userData => {
      if (userData.exists) {
        if (userData.data().companyId) {
          // const { companyId } = userData.data();
          // const checkCompanyDashboardValues = firebase
          //   .functions()
          //   .httpsCallable('updateCompanyDashboardValues');
          // const body = {
          //   companyId,
          // };
          // checkCompanyDashboardValues(body);
          db.collection('Companies')
            .doc(userData.data().companyId)
            .onSnapshot(companyData => {
              let { totalBonus } = companyData.data();
              if (totalBonus === undefined) {
                totalBonus = 0;
              }
              this.setState({
                payableAmount: companyData.data().totalPayablePendingAmount,
                receivableAmount: companyData.data()
                  .totalReceivablePendingAmount,
                totalCommission: companyData.data().totalCommission,
                totalBonus,
              });
            });
        }
      }
    });
  }

  goToPayablePending() {
    this.props.history.push('/agent/pending/payable');
  }

  goToReceivablePending() {
    this.props.history.push('/agent/pending/receivable');
  }

  // Will Return Table Component
  getTable() {
    return <DashboardTable />;
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose() {
    this.setState({ open: false });
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        db.collection('Users')
          .doc(user.uid)
          .update({
            isOnboarding: false,
            'onBoard.transporter': false,
            'onBoard.trucker': false,
            'onBoard.transactionCreate': false,
            'onBoard.transactionEdit': false,
            'onBoard.user': false,
          });
      }
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer id="main">
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <Card id="lol">
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <CallMade />
                </CardIcon>
                <p className={classes.cardCategory}>Payable Pending</p>
                <h3 className={classes.cardTitle}>
                  <i className="fa fa-inr" aria-hidden="true" />
                  {Intl.NumberFormat('en-IN').format(
                    this.state.payableAmount
                  )}{' '}
                  <small />
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <DateRange />
                  Pending Payable Amount
                </div>
                <Button
                  size="sm"
                  simple
                  color="info"
                  className={classes.actionButton}
                  onClick={() => this.goToPayablePending()}
                >
                  View
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <Card id="lol1">
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <CallReceived />
                </CardIcon>
                <p className={classes.cardCategory}>Receivable Pending</p>

                <h3 className={classes.cardTitle}>
                  <i className="fa fa-inr" aria-hidden="true" />
                  {Intl.NumberFormat('en-IN').format(
                    this.state.receivableAmount
                  )}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <DateRange />
                  Pending Receivable Amount
                </div>
                <Button
                  size="sm"
                  simple
                  color="info"
                  className={classes.actionButton}
                  onClick={() => this.goToReceivablePending()}
                >
                  View
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="warning">
                  <i className="fa fa-inr" aria-hidden="true" />
                </CardIcon>
                <p className={classes.cardCategory}>Commission</p>
                <h3 className={classes.cardTitle}>
                  <i className="fa fa-inr" aria-hidden="true" />
                  {Intl.NumberFormat('en-IN').format(
                    this.state.totalCommission
                  )}{' '}
                  <small />
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <DateRange />
                  Total Commission
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="warning">
                  <i className="fa fa-inr" aria-hidden="true" />
                </CardIcon>
                <p className={classes.cardCategory}>Bonus</p>
                <h3 className={classes.cardTitle}>
                  <i className="fa fa-inr" aria-hidden="true" />
                  {Intl.NumberFormat('en-IN').format(
                    this.state.totalBonus
                  )}{' '}
                  <small />
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <DateRange />
                  Total Bonus
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            {this.getTable()}
          </GridItem>
        </GridContainer>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <Wizard
            validate
            finishButtonClick={() => this.goToAddTransporter()}
            finishButtonText="Add a Transporter"
            color="primary"
            steps={[
              { stepName: '1', stepComponent: One, stepId: 'one' },
              { stepName: '2', stepComponent: Two, stepId: 'two' },
              { stepName: '3', stepComponent: Three, stepId: 'three' },
              {
                stepName: 'Start',
                stepComponent: Four,
                stepId: 'four',
                skipTour: this.handleClose,
              },
            ]}
            title="Welcome To Lobb Agent"
            subtitle="Here's what you can do"
          />
        </Dialog>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userDetails: state.main.userDetails,
});

export default connect(mapStateToProps)(withStyles(dashboardStyle)(Dashboard));
