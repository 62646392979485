/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-state */
import React from 'react';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

import customSelectStyle from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx';

const style = {
  infoText: {
    fontWeight: '300',
    margin: '10px 0 30px',
    textAlign: 'center',
  },
  ...customSelectStyle,
};

class Step3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      simpleSelect: '',
      desgin: false,
      code: false,
      develop: false,
    };
  }

  sendState() {
    return this.state;
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  isValidated() {
    return true;
  }

  render() {
    const { classes } = this.props;
    return (
      <GridContainer>
        <GridItem xs={12} sm={12}>
          <h3 className={classes.infoText}>Manage Your Transporters</h3>
        </GridItem>
        <GridItem xs={12} sm={12}>
          <span
            style={{ fontSize: '16px', marginBottom: '10px' }}
            className={classes.infoText}
          >
            &#8226; Add Transporters and Contact Person
          </span>
        </GridItem>
        <GridItem xs={12} sm={12}>
          <span
            style={{ fontSize: '16px', marginBottom: '10px' }}
            className={classes.infoText}
          >
            &#8226; Automatically alert transporter for outstanding payments
          </span>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(style)(Step3);
