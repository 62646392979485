import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";
import Settings from "@material-ui/icons/Settings";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";
import firebase from "../../config/config";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import purple from "@material-ui/core/colors/purple";
import SweetAlert from "react-bootstrap-sweetalert";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

import man from "assets/img/man.png";
import girl from "assets/img/girl.png";

const db = firebase.firestore();
var moment = require("moment");
class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [],
      name: "",
      nameState: "",
      email: "",
      emailState: "",
      companyName: "",
      phoneNumber: "",
      uid: "",
      isLoading: false,
      timeDiff: 0,
      alert: null,
      isCompanyAdmin: false,
      smsAlerts:true
    };
    this.checkAuth = this.checkAuth.bind(this);
    this.change = this.change.bind(this);
    this.getCompanyDetails = this.getCompanyDetails.bind(this);
    this.submitUser = this.submitUser.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    //this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    // Check weather the user is Authenticated
    this.checkAuth();
  }
  hideAlert() {
    this.setState({
      alert: null
    });
    
  }
  submitUser() {
    this.setState({
      isLoading: true
    });
    if (this.verifyLength(this.state.name, 2) && this.verifyEmail(this.state.email)) {
      const user = firebase.auth().currentUser;
      const transporterRef = db.collection(`Users`);
      transporterRef
        .doc(user.uid)
        .update({
          name: this.state.name,
          email: this.state.email,
          modifiedAt: new Date()
        })
        .then(userDoc => {
          //Updated Succeessfully
          this.setState({
            alert: (
              <SweetAlert
                success
                style={{ display: "block", marginTop: "-100px" }}
                title="Good job!"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                confirmBtnCssClass={
                  this.props.classes.button +
                  " " +
                  this.props.classes.success
                }
              >
                Profile updated successfully
              </SweetAlert>
            ),
            isLoading: false
          });
        });
    } else {
      if (!this.verifyLength(this.state.name, 2)) {
        this.setState({ nameState: "error", isLoading: false });
      }
      if (!this.verifyEmail(this.state.email)) {
        this.setState({ emailState: "error", isLoading: false });
      }
    }
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({
            [stateName + "State"]: "success",
            [stateName]: event.target.value
          });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            [stateName]: event.target.value
          });
        }
        break;

      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [stateName + "State"]: "success",
            [stateName]: event.target.value
          });
        } else {
          this.setState({
            [stateName + "State"]: "error",
            [stateName]: event.target.value
          });
        }
        break;
      default:
        break;
    }
  }

  /**
   * Check weather the user is Authenticated
   * @function
   */
  checkAuth() {
    let _me = this;
    this.setState({
      isLoading: true
    });
    
    const usersRef = db.collection("Users");
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // Check if user already exists
        this.setState({
          uid: user.uid
        });
        usersRef
          .doc(user.uid)
          .get()
          .then(
            userDoc => {
              if (userDoc.exists) {
                if (userDoc.data().isCompanyAdmin === true) {
                  let {
                    name,
                    companyName,
                    phoneNumber,
                    gender,
                    companyId,
                    email,
                    smsAlerts
                  } = userDoc.data();
                  _me.setState({
                    name,
                    companyName,
                    phoneNumber,
                    gender,
                    email,
                    smsAlerts,
                    isCompanyAdmin: true
                  });
                  this.getCompanyDetails(companyId);
                } else {
                  let {
                    name,
                    companyName,
                    phoneNumber,
                    gender,
                    email
                  } = userDoc.data();
                  _me.setState({
                    name,
                    companyName,
                    phoneNumber,
                    gender,
                    email,
                    isCompanyAdmin: false
                  });
                  //_me.props.history.push("/pages/initial-page");
                }
                _me.setState({ isLoading: false });
              } else {
                _me.props.history.push("/pages/register-page");
                _me.setState({ isLoading: false });
              }
            },
            err => {
              console.log("no users found error", err);
            }
          );
      } else {
        //if not logged go to login screen
        _me.setState({
          isLoading: false
        });
        _me.props.history.push("/pages/login-page");
      }
    });
  }
  getCompanyDetails(companyId) {
    const db = firebase.firestore();
    const usersRef = db.collection("Companies");
    usersRef
      .doc(companyId)
      .get()
      .then(userDoc => {
        if (userDoc.exists) {
          let today = moment(new Date()).format("DD-MM-YYYY");
          let freeTrialStartDate = moment(
            userDoc.data().freeTrialStartDate.toDate()
          ).format("DD-MM-YYYY");
          var a = moment(today, "DD-MM-YYYY");
          var b = moment(freeTrialStartDate, "DD-MM-YYYY");
          let timeDiff = Number(a.diff(b, "days"));
          this.setState({
            timeDiff
          });
        }
      });
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked },this.updateSettingsInDB);
  };

  showNotification(place, message) {
    this.setState({
      message
    });
    if (!this.state[place]) {
      var x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(
        function() {
          x[place] = false;
          this.setState(x);
        }.bind(this),
        2000
      );
    }
  }

  updateSettingsInDB(){
    const { smsAlerts } = this.state;
    db.collection('Users').doc(this.props.userDetails.uid).update({smsAlerts})
    .then(()=>{
      let NotificationMessage ;
      
      if(smsAlerts){
        NotificationMessage = 'SMS Alerts turned ON.';
      }
      else{
        NotificationMessage = 'SMS Alerts turned OFF.';
      }
      
      this.showNotification("tc", NotificationMessage);
    })
  }
  render() {
    const { classes } = this.props;
    return <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8}>
            {this.state.alert}
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <PermIdentity />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  <small>Profile Details</small>
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput labelText="Phone Number" id="postal-code" formControlProps={{ fullWidth: true }} inputProps={{ value: this.state.phoneNumber, disabled: true }} />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput labelText="Company" id="company-disabled" formControlProps={{ fullWidth: true}} inputProps={{ disabled: true, value: this.state.companyName }} />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput labelText="Full Name *" id="name" formControlProps={{ fullWidth: true }} success={this.state.nameState === "success"} error={this.state.nameState === "error"} inputProps={{ onChange: event => this.change(event, "name", "length", 1), type: "length", value: this.state.name }} />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput labelText="Email address" id="email-address" formControlProps={{ fullWidth: true }} inputProps={{ value: this.state.email, onChange: event => this.change(event, "email", "email"), type: "email" }} success={this.state.emailState === "success"} error={this.state.emailState === "error"} />
                  </GridItem>
                </GridContainer>
                <GridContainer />
                {this.state.isLoading ? <GridContainer justify="flex-end">
                    <GridItem>
                      <CircularProgress className={classes.progress} style={{ color: purple[500], textAlign: "right" }} thickness={7} />
                    </GridItem>
                  </GridContainer> : <Button color="rose" className={classes.updateProfileButton} onClick={this.submitUser}>
                    Update Profile
                  </Button>}
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
          {this.state.isCompanyAdmin && <GridItem xs={12} sm={12} md={4}>
              <Card profile>
                <CardAvatar profile>
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    <img src={this.state.gender === "female" ? girl : man} alt="..." />
                  </a>
                </CardAvatar>
                <CardBody profile>
                  <h6 className={classes.cardCategory}>Role : Admin</h6>
                  <h4 className={classes.cardTitle}>
                    {this.state.companyName}
                  </h4>
                  <p className={classes.description}>
                    License: 60 day free trial.
                    <br />
                    Your Free Trial Expires In {60 - Number(this.state.timeDiff)} days.
                  </p>
                </CardBody>
              </Card>
            </GridItem>}

            {this.state.isCompanyAdmin && 
            <GridItem xs={12} sm={12} md={8}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon color="rose">
                  <Settings />
                </CardIcon>
                <h4 className={classes.cardIconTitle}>
                  <small>Company Settings</small>
                </h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                  <FormControlLabel
            control={
              <Switch
                checked={this.state.smsAlerts}
                onChange={this.handleChange('smsAlerts')}
                value="smsAlerts"
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.switchChecked,
                  icon: classes.switchIcon,
                  iconChecked: classes.switchIconChecked,
                  bar: classes.switchBar
                }}
              />
            }
            classes={{
              label: classes.label
            }}
            label="SMS Alerts"
          />
                  </GridItem>
                  
                </GridContainer>
                <GridContainer />
                <Clearfix />
              </CardBody>
            </Card>
          </GridItem>
            }
        </GridContainer>
        <Snackbar
              place="tc"
              color={this.state.color}
              icon={AddAlert}
              message={this.state.message}
              open={this.state.tc}
              closeNotification={() => this.setState({ tc: false })}
              close
            />
      </div>;
  }
}



UserProfile.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  userDetails:state.main.userDetails
});

export default connect(mapStateToProps)(withStyles(userProfileStyles)(UserProfile));
