/* eslint-disable no-empty */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/order */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';

// @material-ui/Icons
import ArtTrack from '@material-ui/icons/ArtTrack';
import AccessTime from '@material-ui/icons/AccessTime';
import LocalShipping from '@material-ui/icons/LocalShipping';
import Table from 'components/Table/Table.jsx';
import Edit from '@material-ui/icons/Edit';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import AddAlert from '@material-ui/icons/AddAlert';
// Fireabse
// import Responsive from 'react-responsive';

import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import firebase from '../../../config/config';
import history from '../../../history';

import {
  switchActivePage,
  setCurrentTransaction,
  setTransactions,
} from '../../../reducers/transactions';

const db = firebase.firestore();

const moment = require('moment');

// const Desktop = props => <Responsive {...props} minWidth={701} />;
// const Mobile = props => <Responsive {...props} maxWidth={700} />;

// const isTabletOrMobile = useMediaQuery({ query: '(max-width: 700px)' });
class TransactionsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      value: 0,
      isDetailedForm: false,
      transactions: [],
      tc: false,
      message: '',
      width: window.innerWidth,
    };
    // this.showDetailedTransaction = this.showDetailedTransaction.bind(this);
    this.showEditedTransaction = this.showEditedTransaction.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  // Will Show DetailedTransaction Page
  showDetailedTransaction(transaction) {
    let pod = false;
    if (transaction.pod) {
      pod = true;
    } else {
      pod = false;
    }
    this.props.setCurrentTransaction(
      transaction,
      transaction.currentPayableAmount,
      transaction.currentReceivableAmount,
      pod
    );
    history.push(`/agent/transactions/view/${transaction.transactionId}`);
    this.showNotification = this.showNotification.bind(this);
  }

  showEditedTransaction(transaction) {
    // Set the CurrentTransaction in Redux Store
    let pod = false;
    if (transaction.pod) {
      pod = true;
    } else {
      pod = false;
    }
    this.props.setCurrentTransaction(
      transaction,
      transaction.currentPayableAmount,
      transaction.currentReceivableAmount,
      pod
    );
    this.props.switchActivePage({
      isListPage: false,
      isDetailedPage: false,
      isEditPage: true,
    });
    this.showNotification = this.showNotification.bind(this);
  }

  handleToggle(value, transactionId, companyId) {
    db.collection('Companies')
      .doc(companyId)
      .collection('Transactions')
      .doc(transactionId)
      .update({
        pod: value,
      })
      .then(() => {
        const NotificationMessage = 'POD Updated';
        this.showNotification('tc', NotificationMessage);
      });
  }

  /**
   * will return the notification snackbar
   * @param {string} place
   */
  showNotification(place, name) {
    this.setState({
      message: name,
    });
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 2000);
    }
  }

  /**
   * Will Return Array of Transaction Components
   * @function
   */
  showTransactions() {
    const { classes } = this.props;
    return (
      <GridContainer>
        {this.props.transactions.map((transaction, i) => {
          let checked = false;
          if (transaction.pod) {
            checked = transaction.pod;
          }
          return (
            <GridItem xs={12} sm={6} md={6} lg={4} key={i}>
              <Card chart className={classes.cardHover}>
                <CardHeader
                  color="lobbPrimary"
                  className={classes.cardHeaderHover}
                  style={{ height: '160px', padding: '5px' }}
                >
                  <h3 className={`${classes.cardTitle}`}>
                    <span className={classes.rajdhaniFont}>Freight :</span>{' '}
                    <i className="fa fa-inr" aria-hidden="true" />
                    <span
                      className={classes.rajdhaniFont}
                      style={{
                        fontWeight: 'bold',
                        color: '#163BAC',
                        marginLeft: '2px',
                      }}
                    >
                      {Intl.NumberFormat('en-IN').format(transaction.amount)}
                    </span>
                  </h3>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <h4 className={classes.cardTitle}>
                        <span className={classes.rajdhaniFont}>Payable :</span>
                      </h4>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <h4 className={classes.cardTitle}>
                        <i className="fa fa-inr" aria-hidden="true" />
                        <span
                          style={{
                            fontWeight: 'bold',
                            color: '#163BAC',
                            fontFamily: 'Rajdhani',
                          }}
                        >
                          {' '}
                          {Intl.NumberFormat('en-IN').format(
                            transaction.currentPayableAmount
                          )}
                        </span>
                      </h4>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <h4 className={classes.cardTitle}>
                        <span className={classes.rajdhaniFont}>
                          Receivable :
                        </span>
                      </h4>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <h4 className={classes.cardTitle}>
                        <i className="fa fa-inr" aria-hidden="true" />
                        <span
                          style={{
                            fontWeight: 'bold',
                            color: '#163BAC',
                            fontFamily: 'Rajdhani',
                          }}
                        >
                          {' '}
                          {Intl.NumberFormat('en-IN').format(
                            transaction.currentReceivableAmount
                          )}
                        </span>
                      </h4>
                    </GridItem>
                  </GridContainer>
                </CardHeader>
                <CardBody>
                  <div className={classes.cardHoverUnder}>
                    <Tooltip
                      id="tooltip-top"
                      title="View"
                      placement="bottom"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        simple
                        color="info"
                        onClick={() =>
                          this.showDetailedTransaction(transaction)
                        }
                      >
                        <ArtTrack className={classes.underChartIcons} />
                        View
                      </Button>
                    </Tooltip>
                    <Tooltip
                      id="tooltip-top"
                      title="View"
                      placement="bottom"
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <Button
                        simple
                        color="info"
                        onClick={() => this.showEditedTransaction(transaction)}
                      >
                        <ArtTrack className={classes.underChartIcons} />
                        Edit
                      </Button>
                    </Tooltip>
                  </div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <p
                        className={`${classes.cardCategory} ${classes.rajdhaniFont}`}
                      >
                        Transporter
                      </p>
                      <h4 className={classes.cardTitle}>
                        <span className={classes.rajdhaniFont}>
                          {transaction.transporterName}
                        </span>
                      </h4>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <p
                        className={`${classes.cardCategory} ${classes.rajdhaniFont}`}
                      >
                        Trucker
                      </p>
                      <h4 className={classes.cardTitle}>
                        <span className={classes.rajdhaniFont}>
                          {transaction.truckerName}
                        </span>
                      </h4>
                    </GridItem>
                    <GridContainer style={{ marginTop: '20px' }}>
                      <GridItem xs={12} sm={12} md={4}>
                        <h5
                          className={`${classes.cardTitle} ${classes.rajdhaniFont}`}
                        >
                          {transaction.fromCity}
                        </h5>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <LocalShipping style={{ color: '#FCD21F' }} />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        <h5
                          className={`${classes.cardTitle} ${classes.rajdhaniFont}`}
                        >
                          {transaction.toCity}
                        </h5>
                      </GridItem>
                    </GridContainer>
                  </GridContainer>
                </CardBody>
                <CardFooter chart>
                  <div className={classes.stats}>
                    <AccessTime />{' '}
                    {moment(transaction.createdAt.toDate()).format('MMM Do YY')}
                  </div>
                  <FormControlLabel
                    classes={{
                      root: classes.checkboxLabelControl,
                      label: classes.checkboxLabel,
                    }}
                    control={
                      <Checkbox
                        onChange={() =>
                          this.handleToggle(
                            !checked,
                            transaction.transactionId,
                            transaction.companyId
                          )
                        }
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        checked={checked}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{ checked: classes.checked }}
                      />
                    }
                    label={<span>POD</span>}
                  />
                </CardFooter>
              </Card>
            </GridItem>
          );
        })}
      </GridContainer>
    );
  }

  render() {
    const { classes } = this.props;
    const { width } = this.state;
    const isMobile = width <= 800;
    const { transactions } = this.props;
    const transactionData = [];
    transactions.forEach(resultData => {
      let checked = false;
      if (resultData.pod) {
        checked = resultData.pod;
      }
      const checkBox = (
        <FormControlLabel
          classes={{
            root: classes.checkboxLabelControl,
            label: classes.checkboxLabel,
          }}
          control={
            <Checkbox
              onChange={() =>
                this.handleToggle(
                  !checked,
                  resultData.transactionId,
                  resultData.companyId
                )
              }
              checkedIcon={<Check className={classes.checkedIcon} />}
              checked={checked}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{ checked: classes.checked }}
            />
          }
        />
      );
      const fillButtons = [{ color: 'info', icon: Edit }].map((prop, key) => (
        <Button
          color={prop.color}
          className={classes.actionButton}
          key={key}
          onClick={() => this.showDetailedTransaction(resultData)}
        >
          View
        </Button>
      ));
      const editButtons = [{ color: 'info', icon: Edit }].map((prop, key) => (
        <Button
          color={prop.color}
          className={classes.actionButton}
          key={key}
          onClick={() => this.showEditedTransaction(resultData)}
        >
          Edit
        </Button>
      ));
      const date = resultData.createdAt.toDate();

      transactionData.push({
        color: 'success',
        data: [
          resultData.transactionSerial,
          resultData.transporterName,
          resultData.truckerName,
          resultData.vehicleNumber,
          resultData.fromCity,
          resultData.toCity,
          Intl.NumberFormat('en-IN').format(resultData.amount),
          Intl.NumberFormat('en-IN').format(resultData.currentPayableAmount),
          Intl.NumberFormat('en-IN').format(resultData.currentReceivableAmount),
          Intl.NumberFormat('en-IN').format(resultData.commission),
          moment(date).format('MMM Do YY'),
          checkBox,
          fillButtons,
          editButtons,
        ],
      });
    });
    return (
      <GridContainer justify="center">
        {isMobile ? (
          this.showTransactions()
        ) : (
          <>
            {transactionData.length > 0 ? (
              <CardBody>
                <Table
                  tableHead={[
                    '#',
                    'Transporter',
                    'Trucker',
                    'Truck Number',
                    'From',
                    'To',
                    'Freight',
                    'Payable',
                    'Receivable',
                    'Commission',
                    'Date',
                    'POD',
                  ]}
                  tableData={transactionData}
                  customCellClasses={[classes.center]}
                  customClassesForCells={[6, 5, 5]}
                  customHeadCellClasses={[classes.center]}
                  customHeadClassesForCells={[6, 5, 5]}
                />
              </CardBody>
            ) : (
              <div className={classes.center}>
                <p className={`${classes.rajdhaniFont}`}>
                  No Transactions Available
                </p>
                <Button round color="info" onClick={this.addTransactions}>
                  Add Transactions
                </Button>
              </div>
            )}
          </>
        )}

        <Snackbar
          place="tc"
          color="info"
          icon={AddAlert}
          message={this.state.message}
          open={this.state.tc}
          closeNotification={() => this.setState({ tc: false })}
          close
        />
      </GridContainer>
    );
  }
}

TransactionsCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isDetailedPage: state.transactions.isDetailedPage,
  currerntTransactionData: state.transactions.currerntTransactionData,
  // transactions: state.transactions.transactions,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchActivePage,
      setCurrentTransaction,
      setTransactions,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(TransactionStyle)(TransactionsCard));
