/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import CardIcon from 'components/Card/CardIcon.jsx';
import Assignment from '@material-ui/icons/Assignment';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Table from 'components/Table/Table.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import firebase from '../../config/config';

const db = firebase.firestore();

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usersData: [],
      isLoading: false,
    };
    this.getTable = this.getTable.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.AddUser = this.AddUser.bind(this);
  }

  componentDidMount() {
    const userRef = db.collection('Users');
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.setState({ isLoading: true });
        userRef
          .doc(`${user.uid}`)
          .get()
          .then(resultData => {
            if (resultData.exists) {
              this.setState({
                companyId: resultData.data().companyId,
              });
              this.getUsers(user, resultData.data().companyId);
            }
          });
      } else {
        this.setState({
          isLoading: false,
        });
      }
    });
  }

  /**
   * Will Get ALl the Truckers For The User
   * @function
   */
  getUsers(user, companyId) {
    const colors = ['info', 'success'];
    const userRef = db
      .collection('Users')
      .where('companyId', '==', companyId)
      .orderBy('name', 'asc');
    const users = this.state.usersData;
    userRef.get().then(usersData => {
      this.setState({ isLoading: false });
      let flag = 0;
      usersData.forEach(userData => {
        const userResult = userData.data();
        users.push({
          color: colors[flag],
          data: [userResult.name, userResult.phoneNumber, userResult.email],
        });
        if (flag === 0) {
          flag = 1;
        } else {
          flag = 0;
        }
      });
      this.setState({
        usersData: users,
      });
    });
  }

  /**
   * Will return the Table Component
   * @function
   */
  getTable() {
    const { classes } = this.props;
    return (
      <Card>
        <CardHeader color="warning" icon>
          <CardIcon color="warning">
            <Assignment />
          </CardIcon>
        </CardHeader>
        <CardBody className={classes.customCardContentClass}>
          <Table
            hover
            tableHead={['Name', 'Phone', 'Email']}
            tableData={this.state.usersData}
          />
        </CardBody>
      </Card>
    );
  }

  /**
   * will redirect to Add Truckers Page
   * @function
   */
  AddUser() {
    this.props.history.push('/agent/users/add');
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader className={`${classes.cardHeader}`} color="warning">
                <p className={`${classes.cardHeader}`}>Users</p>
                <h3 className={classes.cardTitle}>
                  {this.state.usersData.length} <small />
                </h3>
              </CardHeader>
              <CardBody
                className={`${classes.cardHeader} ${classes.textCenter}`}
              >
                {this.state.isLoading ? (
                  <CircularProgress
                    className={classes.progress}
                    style={{ color: purple[500] }}
                    thickness={7}
                  />
                ) : (
                  <React.Fragment>
                    {this.state.usersData.length <= 0 ? (
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        <p className={classes.textCenter}>No Users Available</p>
                        <div className={classes.textCenter}>
                          <Button round color="warning" onClick={this.AddUser}>
                            Add User
                          </Button>
                        </div>
                      </GridItem>
                    ) : (
                      <GridItem xs={12} sm={12} md={12} lg={12}>
                        {this.getTable()}
                      </GridItem>
                    )}
                  </React.Fragment>
                )}
              </CardBody>
              <CardFooter stats>
                <div className={classes.stats} />
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Users.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(TransactionStyle)(Users);
