/* eslint-disable no-undef */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/sort-comp */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/Icons

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import Table from 'components/Table/Table.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import AddAlert from '@material-ui/icons/AddAlert';
import Assignment from '@material-ui/icons/Assignment';
import Edit from '@material-ui/icons/Edit';

import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import {
  switchActivePage,
  setTransactions,
  setCurrentTransaction,
  setPayablePendingTransactions,
  setReceivablePendingTransactions,
} from '../../reducers/transactions';
import firebase from '../../config/config';

const db = firebase.firestore();
const moment = require('moment');

class PayablePending extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      isDetailedForm: false,
      tc: false,
      message: '',
      pendingTransactions: {},
    };
    this.handleToggle = this.handleToggle.bind(this);
  }

  componentDidMount() {
    // let _me = this;
    // Get All Transactions for current User
    firebase.auth().onAuthStateChanged(user => {
      const mainRef = db.collection('Users').doc(user.uid);
      mainRef.get().then(userData => {
        if (userData.exists) {
          if (userData.data().companyId) {
            const transactionsRef = db
              .collection(`Companies`)
              .doc(userData.data().companyId)
              .collection(`Transactions`)
              .where('companyId', '==', userData.data().companyId)
              .where('currentPayableAmount', '>', 0);

            transactionsRef.onSnapshot(resultData => {
              const transactions = [];
              resultData.forEach(transaction => {
                transaction.data().createdAt = moment().format(
                  'MMM Do YY',
                  transaction.data().createdAt
                );
                transactions.push(transaction.data());
              });
              const pen = {};
              transactions.forEach(transaction => {
                if (!pen[transaction.truckerName]) {
                  pen[transaction.truckerName] = [];
                }
                pen[transaction.truckerName].push(transaction);
              });
              this.props.setPayablePendingTransactions(pen);
              this.setState({
                transactions,
                pendingTransactions: pen,
              });
            });
          }
        }
      });
    });
  }

  /**
   * will return the notification snackbar
   * @param {string} place
   */
  showNotification(place, name) {
    this.setState({
      message: name,
    });
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 2000);
    }
  }

  handleToggle(value, transaction, pod) {
    const currentTransaction = transaction;
    if (currentTransaction.pod) {
      currentTransaction.pod = false;
    } else {
      currentTransaction.pod = true;
    }
    db.collection('Companies')
      .doc(transaction.companyId)
      .collection('Transactions')
      .doc(transaction.transactionId)
      .update({
        pod: value,
      })
      .then(() => {
        const NotificationMessage = 'POD Updated';
        this.showNotification('tc', NotificationMessage);
      });
  }

  goToViewTransactions(transaction) {
    let pod = false;
    if (transaction.pod) {
      pod = true;
    } else {
      pod = false;
    }
    this.props.setCurrentTransaction(
      transaction,
      transaction.currentPayableAmount,
      transaction.currentReceivableAmount,
      pod
    );
    this.props.switchActivePage({
      isListPage: true,
      isDetailedPage: false,
      isEditPage: false,
    });
    this.props.history.push(
      `/agent/transactions/view/${transaction.transactionId}`
    );
  }

  getTable(payableTransactions) {
    const { classes } = this.props;
    const transactions = [];
    payableTransactions.forEach(resultData => {
      let checked = false;
      if (resultData.pod) {
        checked = resultData.pod;
      }
      const checkBox = (
        <FormControlLabel
          classes={{
            root: classes.checkboxLabelControl,
            label: classes.checkboxLabel,
          }}
          control={
            <Checkbox
              onChange={() => this.handleToggle(!checked, resultData, checked)}
              checkedIcon={<Check className={classes.checkedIcon} />}
              checked={checked}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{ checked: classes.checked }}
            />
          }
        />
      );
      const fillButtons = [{ color: 'info', icon: Edit }].map((prop, key) => (
        <Button
          color={prop.color}
          className={classes.actionButton}
          key={key}
          onClick={() => this.goToViewTransactions(resultData)}
        >
          View
        </Button>
      ));
      const date = resultData.createdAt.toDate();
      transactions.push({
        color: 'success',
        data: [
          resultData.transactionSerial,
          resultData.transporterName,
          resultData.truckerName,
          resultData.vehicleNumber,
          Intl.NumberFormat('en-IN').format(resultData.amount),
          Intl.NumberFormat('en-IN').format(resultData.currentPayableAmount),
          Intl.NumberFormat('en-IN').format(resultData.currentReceivableAmount),
          Intl.NumberFormat('en-IN').format(resultData.commission),
          moment(date).format('MMM Do YY'),
          checkBox,
          fillButtons,
        ],
      });
    });
    return (
      <Card>
        <CardHeader color="rose" icon>
          <CardIcon color="warning">
            <Assignment />
          </CardIcon>
          <h4 className={classes.cardIconTitle}>Transactions</h4>
        </CardHeader>
        {transactions.length > 0 ? (
          <CardBody>
            <Table
              tableHead={[
                '#',
                'Transporter',
                'Trucker',
                'Truck No',
                'Freight',
                'Payable Pending',
                'Receivable Pending',
                'Commission',
                'Date',
                'POD',
              ]}
              tableData={transactions}
              customCellClasses={[classes.center]}
              customClassesForCells={[6, 5, 5]}
              customHeadCellClasses={[classes.center]}
              customHeadClassesForCells={[6, 5, 5]}
            />
          </CardBody>
        ) : (
          <div className={classes.center}>
            <p className={`${classes.rajdhaniFont}`}>
              No Transactions Available
            </p>
            <Button round color="info" onClick={this.addTransactions}>
              Add Transactions
            </Button>
          </div>
        )}
        <Snackbar
          place="tc"
          color="info"
          icon={AddAlert}
          message={this.state.message}
          open={this.state.tc}
          closeNotification={() => this.setState({ tc: false })}
          close
        />
      </Card>
    );
  }

  getCards() {
    const { classes } = this.props;
    return (
      <div>
        {Object.keys(this.props.payablePendingTransactions)
          .sort()
          .map((transaction, i) => {
            let totalPendingAmount = 0;
            const transactions = this.props.payablePendingTransactions[
              transaction
            ];
            transactions.forEach(transaction => {
              totalPendingAmount =
                Number(totalPendingAmount) +
                Number(transaction.currentPayableAmount);
            });
            return (
              <GridContainer justify="center" key={i}>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <Card>
                    <CardHeader
                      className={`${classes.cardHeader}`}
                      color="warning"
                      style={{ padding: '0px', marginTop: '0px' }}
                    >
                      <h3
                        className={`${classes.cardHeader} ${classes.rajdhaniFont}`}
                      >
                        {transaction} :{' '}
                        <span
                          style={{
                            fontWeight: 'bold',
                            color: '#163BAC',
                            marginLeft: '2px',
                          }}
                        >
                          {' '}
                          <i className="fa fa-inr" aria-hidden="true" />{' '}
                          {Intl.NumberFormat('en-IN').format(
                            totalPendingAmount
                          )}{' '}
                        </span>
                      </h3>
                    </CardHeader>
                    <CardBody
                      className={`${classes.cardHeader} ${classes.textCenter}`}
                      style={{ marginBottom: '0px' }}
                    >
                      {this.getTable(
                        this.props.payablePendingTransactions[transaction]
                      )}
                    </CardBody>
                  </Card>
                </GridItem>
                <Snackbar
                  place="tc"
                  color="info"
                  icon={AddAlert}
                  message={this.state.message}
                  open={this.state.tc}
                  closeNotification={() => this.setState({ tc: false })}
                  close
                />
              </GridContainer>
            );
          })}
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={12} lg={12}>
            <Card>
              <CardHeader
                className={`${classes.cardHeader}`}
                color="#fff"
                style={{
                  paddingBottom: '0px',
                  marginTop: '0px',
                  marginBottom: '0px',
                }}
              >
                <h3 className={`${classes.cardHeader} ${classes.rajdhaniFont}`}>
                  Payable Pending Transactions:{' '}
                  {Object.keys(this.props.payablePendingTransactions).length}{' '}
                  <small />
                </h3>
              </CardHeader>
              <CardBody
                className={`${classes.cardHeader} ${classes.textCenter}`}
              >
                {Object.keys(this.props.payablePendingTransactions).length <=
                0 ? (
                  <div className={classes.textCenter}>
                    <p className={`${classes.rajdhaniFont}`}>
                      No Transactions Available
                    </p>
                  </div>
                ) : (
                  this.getCards()
                )}
              </CardBody>
              <CardFooter stats>
                <div className={classes.stats} />
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

PayablePending.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isDetailedPage: state.transactions.isDetailedPage,
  transactions: state.transactions.transactions,
  receivablePendingTransactions:
    state.transactions.receivablePendingTransactions,
  payablePendingTransactions: state.transactions.payablePendingTransactions,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchActivePage,
      setTransactions,
      setPayablePendingTransactions,
      setCurrentTransaction,
      setReceivablePendingTransactions,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(TransactionStyle)(PayablePending));
