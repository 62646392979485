/* eslint-disable react/sort-comp */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
/* eslint-disable react/no-unused-state */
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
// creates a beautiful scrollbar
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import Header from 'components/Header/Header.jsx';
import Footer from 'components/Footer/Footer.jsx';
import Sidebar from 'components/Sidebar/Sidebar.jsx';
import UserProfile from 'views/Pages/UserProfile.jsx';
import DetailedTransaction from 'views/Transactions/Sections/DetailedTransactions.jsx';
import EditTransaction from 'views/Transactions/Sections/EditTransaction.jsx';

import dashRoutes from 'routes/dashboard.jsx';
// import RequireAuth from "../../app/auth";

import boy from 'assets/img/man.png';
import girl from 'assets/img/girl.png';

import appStyle from 'assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx';

import logo from 'assets/img/Lobb-Logo.png';
import firebase from '../config/config';

const db = firebase.firestore();
let ps;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.switchRoutes = this.switchRoutes.bind(this);
  }

  state = {
    mobileOpen: false,
    miniActive: false,
    name: '',
    isAgent: false,
    isMale: true,
    isFemale: false,
    isAdmin: false,
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  getRoute() {
    return this.props.location.pathname !== '/maps/full-screen-maps';
  }

  switchRoutes() {
    return (
      <React.Fragment>
        <Switch>
          <Route path="/agent/settings" component={UserProfile} />
          <Route
            path="/agent/transactions/view/:txnId"
            component={DetailedTransaction}
          />
          <Route
            path="/agent/transactions/edit/:txnId"
            component={EditTransaction}
          />
          {dashRoutes.adminRoutes.map((prop, key) => {
            if (prop.redirect)
              return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
            if (prop.collapse)
              return prop.views.map((prop, key) => (
                <Route path={prop.path} component={prop.component} key={key} />
              ));
            return (
              <Route path={prop.path} component={prop.component} key={key} />
            );
          })}
        </Switch>
      </React.Fragment>
    );
  }

  componentDidMount() {
    const db = firebase.firestore();
    const usersRef = db.collection('Users');
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
      document.body.style.overflow = 'hidden';
    }
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // Check if user already exists
        usersRef
          .doc(user.uid)
          .get()
          .then(userDoc => {
            if (userDoc.exists) {
              // Succeess
              if (userDoc.data().isCompanyAdmin) {
                this.setState({
                  isAdmin: true,
                });
              } else {
                this.setState({
                  isAdmin: false,
                });
              }
              if (userDoc.data().gender === 'female') {
                this.setState({
                  isFemale: true,
                  isMale: false,
                });
              } else {
                this.setState({
                  isMale: true,
                  isFemale: false,
                });
              }
              if (userDoc.data().isAgent === true) {
                this.setState({ isAgent: true });
              }
              if (userDoc.data().isAgent !== true) {
                this.props.history.push('/pages/initial-page');
              }
              this.setState({
                name: userDoc.data().name,
              });
            } else {
              this.props.history.push('/pages/initial-page');
            }
          });
      } else {
        // if not logged go to login screen
        this.props.history.push('/pages/login-page');
      }
    });
  }

  /**
   * Will return a promise weather the user is Admin or not
   * @function
   */
  checkIsAdmin(user, companyId) {
    return new Promise((resolve, reject) => {
      const companyRef = db.collection('Companies').doc(companyId);
      companyRef
        .get()
        .then(companyResult => {
          if (companyResult.exists) {
            if (companyResult.data().admin === user.uid) {
              resolve(true);
            } else {
              reject(false);
            }
          } else {
            reject(false);
          }
        })
        .catch(err => {
          reject(false);
        });
    });
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }

  render() {
    let routes = '';
    if (this.state.isAdmin) {
      routes = dashRoutes.adminRoutes;
    } else {
      routes = dashRoutes.agentRoutes;
    }
    let image = '';
    if (this.state.isMale) {
      image = boy;
    } else {
      image = girl;
    }

    const { classes, ...rest } = this.props;
    const mainPanel = `${classes.mainPanel} ${cx({
      [classes.mainPanelSidebarMini]: this.state.miniActive,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf('Win') > -1,
    })}`;
    return (
      <div className={classes.wrapper}>
        {this.state.isAgent ? (
          <React.Fragment>
            <Sidebar
              routes={routes}
              logoText="Lobb Agent"
              logo={logo}
              image={image}
              name={this.state.name}
              handleDrawerToggle={this.handleDrawerToggle}
              open={this.state.mobileOpen}
              color="blue"
              bgColor="black"
              miniActive={this.state.miniActive}
              {...rest}
            />
            <div className={mainPanel} ref="mainPanel">
              <Header
                sidebarMinimize={this.sidebarMinimize.bind(this)}
                miniActive={this.state.miniActive}
                routes={routes}
                handleDrawerToggle={this.handleDrawerToggle}
                {...rest}
              />
              {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
              {this.getRoute() ? (
                <div className={classes.content}>
                  <div>{this.switchRoutes()}</div>
                </div>
              ) : (
                <div className={classes.map}>{this.switchRoutes()}</div>
              )}
              {this.getRoute() ? <Footer fluid /> : null}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Sidebar
              routes={[]}
              logoText="Lobb Agent"
              logo={logo}
              image={image}
              name={this.state.name}
              handleDrawerToggle={this.handleDrawerToggle}
              open={this.state.mobileOpen}
              color="blue"
              bgColor="black"
              miniActive={this.state.miniActive}
              {...rest}
            />
            <div className={mainPanel} ref="mainPanel" />
          </React.Fragment>
        )}
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(appStyle)(Dashboard);
